export const trimObjectValues = (obj: any) => {
  const trimmedObject: any = {};

  for (const [key, value] of Object.entries(obj)) {
    if (typeof value === 'string') {
      trimmedObject[key] = value.trim();
    } else {
      trimmedObject[key] = value;
    }
  }

  return trimmedObject;
};
