import React from "react";
import DecisionIntegerField from "./fields/decison-integer-field";
import DecisionCheckField from "./fields/decision-check-field";
import DecisionSelectField from "./fields/decison-select-field";

type Props = {
  fieldKey: string;
  value: any;
  data_type: string;
  description: string;
  options?: string[];
};

export function RenderModuleDecsionSettingField(props: Props) {
  const { data_type, value, fieldKey, options } = props;
  switch (data_type) {
    case "number": {
      return (
        <DecisionIntegerField id={fieldKey} name={fieldKey} value={value} />
      );
    }
    case "string|rule": {
      return (
        <DecisionSelectField
          id={fieldKey}
          name={fieldKey}
          value={value}
          options={options}
        />
      );
    }
    case "boolean": {
      return <DecisionCheckField id={fieldKey} check={value} />;
    }

    default:
      return <></>;
  }
}
