import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './_decision-model-manage.scss';

//components
import ModulesComp from './module/modules';
import WorkspacesComp from './workspace/workspace';
import ConfigurationComp from './configuration/configuration';
import { useAppDispatch, useAppSelector } from '../../../store/customHooks';
import { IDecisionModels } from '../../../store/decision-model/decision-model.interface';
import {
  getConfigModelAction,
  getSingleModelAction,
} from '../../../store/decision-model/decision-model.actions';

import FillButton, {
  ButtonColor,
} from '../../../components/common/buttons/button';
import PageLoading from '../../../components/page-loading/pageLoading';
import ToggleModelStatusModal from '../../../components/decision-model/change-model-status/toggle-model-status';
import PageTitle from '../../../components/common/page-title';

function DecisionModelManagePage() {
  const dispatch = useAppDispatch();
  const params = useParams();

  const decisionModel: IDecisionModels = useAppSelector(
    (state) => state.decisionModel
  );

  useEffect(() => {
    dispatch(getSingleModelAction(`${params.id}`));
    if (!decisionModel.configModel) {
      dispatch(getConfigModelAction());
    }
  }, []);

  const [showChangeModelStatus, setShowChangeModelStatus] =
    useState<boolean>(false);

  let modelStatus = decisionModel?.focusModel?.status;

  return (
    <>
      {decisionModel.focusModelStatus === 'loading' ? (
        <PageLoading loadingMessage="Getting Model" />
      ) : (
        <div className="decision-model-page">
          <PageTitle
            title="Decision Models"
            description={decisionModel?.focusModel?.name}
            rightElement={
              <div className="button-row">
                <FillButton
                  colorVariant={
                    modelStatus === 'active'
                      ? ButtonColor.danger
                      : ButtonColor.primary
                  }
                  onClick={() => {
                    setShowChangeModelStatus(true);
                  }}
                >
                  {modelStatus === 'active'
                    ? 'Deactivate Model'
                    : 'Activate Model'}
                </FillButton>
              </div>
            }
          />

          <section className="decision-model-body">
            <section className="module-section">
              <ModulesComp />
            </section>

            <div className="decision-model-body-grid">
              <section>
                <WorkspacesComp />
              </section>

              <section>
                <ConfigurationComp />
              </section>
            </div>
          </section>

          <ToggleModelStatusModal
            showModal={showChangeModelStatus}
            setShowModal={setShowChangeModelStatus}
            modelId={Number(params.id)}
            modelStatus={modelStatus}
          />
        </div>
      )}
    </>
  );
}

export default DecisionModelManagePage;
