import React from 'react';
import './_button.scss';
import { ButtonProps } from './button.interface';
import Spinner from '../spinner/spinner';

export enum ButtonColor {
  primary = 'primary-btn-color',
  secondary = 'secondary-btn-color',
  danger = 'danger-btn-color',
  purple = 'purple-btn-color',
  neutral = 'neutral-btn-color',
}

const FillButton: React.FC<ButtonProps> = (props) => {
  const {
    className,
    onClick,
    children,
    isLoading,
    colorVariant = ButtonColor.primary,
    variant,
    disabled,
    label,
    icon,
    iconPosition = 'left',
    iconClassName,
    loadingText,
    ...rest
  } = props;

  return (
    <button
      className={`${className ?? ''} ${
        colorVariant ?? 'primary-btn-color'
      } filled-btn`}
      data-variant={variant}
      onClick={() => {
        onClick?.();
      }}
      disabled={disabled || isLoading}
      {...rest}
    >
      {label && (
        <React.Fragment>
          {isLoading && <Spinner className="button-spinner" />}

          {icon && iconPosition === 'left' && (
            <div className={`button-icon ${iconClassName ?? ''}`}>{icon}</div>
          )}

          <div>{isLoading && loadingText ? loadingText : label}</div>

          {icon && iconPosition === 'right' && (
            <div className={`button-icon ${iconClassName ?? ''}`}>{icon}</div>
          )}
        </React.Fragment>
      )}

      {/* DEPRECIATE ONCE NEW VERSION IS USED */}

      {!label && isLoading ? <Spinner /> : null}
      {!label && !isLoading ? children : null}
    </button>
  );
};

export default FillButton;
