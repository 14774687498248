import React, { useEffect, useState } from 'react';
import MfaInput from '../mfa-input/mfa-input';
import Modal, { ModalPosition } from '../common/modal/modal';
import FillButton, { ButtonColor } from '../common/buttons/button';
import ModalFooter from '../common/modal/modal-footer';
import { useAppDispatch, useAppSelector } from '../../store/customHooks';
import Input from '../input-fields/input/input';
import NoticeModal from './notice';
import { deleteOrgAction } from '../../store/account/account.actions';
import SuccessModal from './success-modal';
import { logoutAction } from '../../store/auth/auth.actions';

type Props = {
  showDeleteOrgModal: boolean;
  toggleShowDeleteOrgModal: () => void;
  proceedTo2fa: () => void;
};

function ToggleDeleteOrgModal({
  proceedTo2fa,
  toggleShowDeleteOrgModal,
  showDeleteOrgModal,
}: Props) {
  const dispatch = useAppDispatch();
  const [deleting, setDeleting] = useState(false);
  const [MFAValue, setMFAValue] = useState('');
  const [MFAError, setMFAError] = useState(false);
  const [orgNameValue, setOrgNameValue] = useState('');
  const { is_twofa_enabled } = useAppSelector((state) => state.auth);
  const { current_organization_profile } = useAppSelector(
    (state) => state.account
  );
  const [activeScreen, setActiveScreen] = useState<
    'warning' | 'code' | 'require-2fa' | 'success' | ''
  >('');

  const handleClose = () => {
    setActiveScreen('');
    toggleShowDeleteOrgModal();
  };

  useEffect(() => {
    if (showDeleteOrgModal) {
      setActiveScreen('warning');
    }
  }, [showDeleteOrgModal]);

  return (
    <>
      <Modal
        isOpen={activeScreen === 'warning'}
        title={`Delete ${current_organization_profile.name}`}
        onClose={() => {
          handleClose();
        }}
        modalPosition={ModalPosition.center}
        animated={false}
        modalFooter={
          <ModalFooter>
            <FillButton
              onClick={() => {
                handleClose();
              }}
              colorVariant={ButtonColor.neutral}
              variant="outlined"
              className="close-button"
              label="Cancel"
            />

            <FillButton
              onClick={() => {
                is_twofa_enabled
                  ? setActiveScreen('code')
                  : setActiveScreen('require-2fa');
              }}
              colorVariant={ButtonColor.danger}
              label="Yes, delete"
              disabled={
                orgNameValue.toLowerCase() !==
                current_organization_profile.name.toLowerCase()
              }
            />
          </ModalFooter>
        }
      >
        <div>
          <p>
            You are about to delete your organization{' '}
            <b>{current_organization_profile.name}</b> with email{' '}
            <b>{current_organization_profile.primary_email}</b>. This process is
            not reversible and all your data would be deleted immediate. Are you
            sure you want to do this?
            <br />
            <br />
            To do this, please type your organization name in the box below.
          </p>
          <br />

          <Input
            label="Organisation name"
            value={orgNameValue}
            onChange={(e: any) => {
              setOrgNameValue(e.target.value);
            }}
          />
        </div>
      </Modal>

      <NoticeModal
        title="Two-Factor-Authentication"
        message={`To proceed with account deletion, you need to enable Two-Factor-Authentication?`}
        showNoticeModal={activeScreen === 'require-2fa'}
        setShowNoticeModal={() => {
          handleClose();
        }}
        actionButtons={
          <ModalFooter>
            <FillButton
              onClick={() => {
                handleClose();
              }}
              colorVariant={ButtonColor.neutral}
              className="close-button"
              variant="outlined"
              label="Cancel"
            />

            <FillButton
              onClick={() => {
                handleClose();
                proceedTo2fa();
              }}
              colorVariant={ButtonColor.primary}
              label={`Yes, Activate
            `}
            />
          </ModalFooter>
        }
      />

      <MfaInput
        showMFA={activeScreen === 'code'}
        description="Enter the OTP sent to your email or the token from your authenticator app to validate this action"
        canUseRecovery={false}
        closeMFA={() => {
          if (deleting) return;
          handleClose();
        }}
        value={MFAValue}
        isLoading={deleting}
        onChange={(value) => {
          setMFAValue(value.toString());
        }}
        error={MFAError}
        onComplete={(value) => {
          if (!current_organization_profile.id) return;
          setDeleting(true);
          setMFAError(false)
          dispatch(
            deleteOrgAction({
              org_id: current_organization_profile.id,
              token: `${value}`,
            })
          )
            .unwrap()
            .then(() => {
              setTimeout(() => logoutAction(), 5000);
              setActiveScreen('success');
            })
            .catch(()=>{
              setMFAError(true)
            })
            .finally(() => {
              setMFAValue('');
              setDeleting(false);
            });
        }}
      />

      <SuccessModal
        isOpen={activeScreen === 'success'}
        onClose={logoutAction}
        message={`Organization deleted successfully`}
        description={`${current_organization_profile.name} has been successfully deleted`}
        title={`Delete ${current_organization_profile.name}`}
        continueAction={logoutAction}
        continueBtnLabel='Log out'
      />
    </>
  );
}

export default ToggleDeleteOrgModal;
