import React from "react";
import "./_tooltip.scss";
import { ReactComponent as TooltipIcon } from "../../../assets/icons/exclamation-with-border.svg";

export interface TooltipProps {
  content: string;
  children?: string | React.ReactNode; //this is the parent Item
  className?: string;
  position?: 'top' | 'bottom'
}

export default function Tooltip({
  className,
  children = <TooltipIcon />,
  content,
  position = 'top'
}: TooltipProps) {
  return (
    <div className={`tooltip ${className ?? ""}`}>
      <div className="tooltip-icon">{children}</div>
      <div className={`tooltip-content tooltip-content-${position}`}>{content}</div>
    </div>
  );
}
