import React from 'react';
import AnayticsCard from '../components/analytics-card/analytics-card';
import ApiCallsChart from '../pages/dashboard/api-calls-chart';
import { formatNumber } from '../utils/formatter';
import { IAuditsLogType } from '../store/audits/audits.interface';
import Styles from './_dashboard-analytics.module.scss';

type Props = {
  allApiCalls: IAuditsLogType[];
  totalCall: number;
  totalError: number;
  totalAveResTime: number;
  averageLatency: string;
  dataFilter: {
    label: string;
    value: number;
  };
  className?: string;
};

function DashboardAnalytics({
  dataFilter,
  totalAveResTime,
  totalError,
  totalCall,
  averageLatency,
  allApiCalls,
  className = '',
}: Props) {
  return (
    <div className={`${Styles.analytics_grid} ${className}`}>
      <div className={Styles.analytics_grid}>
        <AnayticsCard
          title={'Total API Calls'}
          className="dashboard_analytics_card"
          footer="request(s)"
          value={totalCall}
          isEmpty={totalCall < 1}
          activeFilter={dataFilter.label}
        />

        <AnayticsCard
          title="Avg. Latency"
          className="dashboard_analytics_card"
          activeFilter={dataFilter.label}
          value={
            <>
              {averageLatency} <span>ms</span>
            </>
          }
          isEmpty={true}
        />

        <AnayticsCard
          title="Errors"
          className="dashboard_analytics_card"
          activeFilter={dataFilter.label}
          footer="error(s)"
          value={totalError}
          isEmpty={totalError < 1}
        />

        <AnayticsCard
          title={`Avg. Resp. Time`}
          className="dashboard_analytics_card"
          activeFilter={dataFilter.label}
          value={
            <>
              {formatNumber(totalAveResTime)} <span>ms</span>
            </>
          }
          isEmpty={totalAveResTime < 1}
        />
      </div>

      <ApiCallsChart data={allApiCalls ?? []} activeFilter={dataFilter.label} />
    </div>
  );
}

export default DashboardAnalytics;
