import React from 'react';
import Style from './_custom-toast.module.scss';
import { Toast, toast } from 'react-hot-toast';
import CloseButton from '../buttons/close-button';
import { ReactComponent as InfoIcon } from '../../../assets/icons/info.svg';
import { ReactComponent as SuccessIcon } from '../../../assets/icons/check-with-border.svg';

interface ToastProps extends Omit<Toast, 'message'> {
  message: string;
}

function CustomToast(props: Readonly<{ toast: ToastProps }>) {
  return (
    <div>
      <div
        className={`${Style.custom_toast_container} ${
          props?.toast.type === 'blank' ? Style.custom_toast_info : ''
        }
        ${props?.toast.type === 'success' ? Style.custom_toast_success : ''}
        ${props?.toast.type === 'error' ? Style.custom_toast_error : ''}
        `}
      >
        {props.toast.type === 'blank' && <InfoIcon />}
        {props.toast.type === 'success' && (
          <div style={{ width: '1.5rem', height: '1.5rem' }}>
            <SuccessIcon />
          </div>
        )}
        <p>{props?.toast?.message}</p>{' '}
        <CloseButton
          style={{ color: 'inherit' }}
          onClick={() => {
            toast.dismiss(props.toast.id);
          }}
        />
      </div>
    </div>
  );
}

export default CustomToast;
