
export function numberToString(value: number) {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const currencyFormat = (
  amount = 0,
  currency?: string,
  type?: string
) => {
  if (type === 'table') {
    return new Intl.NumberFormat(navigator.language, {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
      .format(amount)
      .replace(/\s+/, '');
  }

  return new Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency: currency ?? 'NGN',
  }).format(amount);
};

export function formatMoney(value: number) {
  let exp,
    suffixes = ['K', 'M', 'B', 'T', 'P', 'E'];
  if (Number.isNaN(value)) {
    return null;
  }

  if (value < 1000000) {
    return numberToString(value);
  }
  exp = Math.floor(Math.log(value) / Math.log(1000));
  return (value / Math.pow(1000, exp)).toFixed(2) + suffixes[exp - 1];
}

export const fullDateFormat = (date: string) =>
  new Date(date).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h12',
  });

export const fullDateTimeFormat = (date: Date) =>
  new Date(date).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hourCycle: 'h23',
  });

export const CalenderDateFormat = (date: string) =>
  new Date(date).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });

export const inputFieldDate = (date: string) => {
  return new Date(date).toISOString()?.split('T')[0];
};

export const removeSpecialCharacters = (e: any) => {
  // replacing no's other than 0-9
  e.target.value = e.target.value.replace(/[^0-9 ]/g, '');
  // to replace special characters
  e.target.value = e.target.value.replace(/[&/\\#, +()$~%.'":*?<>{}]/g, '');
};

export const checkLength = (e: any) => {
  const KEYS = {
    leftKey: 37,
    rightKey: 39,
    backspace: 8,
    deleteKey: 46,
    digitZero: 48,
    digitNine: 57,
  };
  if (
    e.keyCode === KEYS.backspace ||
    e.keyCode === KEYS.deleteKey ||
    e.keyCode === KEYS.rightKey ||
    e.keyCode === KEYS.leftKey
  ) {
    return true;
  }
  if (
    e.keyCode < KEYS.digitZero ||
    e.keyCode > KEYS.digitNine ||
    e.target.value.length === e.target.maxLength ||
    e.shiftKey
  ) {
    e.stopPropagation();
    e.preventDefault();
    return false;
  }
  return true;
};

export const truncateString = (str: string, maxLenght: number) => {
  if (str.length > maxLenght) {
    return str.slice(0, maxLenght) + '...';
  } else {
    return str;
  }
};

export const formatNumber = (value: number) => {
  return new Intl.NumberFormat(navigator.language, {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
    .format(value)
    .replace(/\s+/, '');
};
