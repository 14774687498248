import React, { useState } from 'react';
import './_profile-management.scss';

import Card from '../../components/common/cards/cards';

//icons
import FillButton, {
  ButtonColor,
} from '../../components/common/buttons/button';

//icon
import { useAppDispatch, useAppSelector } from '../../store/customHooks';
import EditCompanyPane from './edit-profile-details-pane';
import { updateUserProfileImgAction } from '../../store/account/account.actions';
import { IAccountState } from '../../store/account/account.interface';
import PageLoading from '../../components/page-loading/pageLoading';
import ChangePasswordPane from './change-profile-password-pane';
import CardBody from '../../components/common/cards/card-body';
import CardHeader from '../../components/common/cards/card-header';
import ToggleTwo2FAModal from '../../components/notice-modal/toggle-2fa';
import UploadImageModal from '../../components/upload-image';
import ToggleDeleteOrgModal from '../../components/notice-modal/delete-organization';
import Divider from '../../components/common/divider/divider';
import PermissionsGate from '../../utils/hooks/permission-gate';
import { ADJUTOR_SCOPES } from '../../utils/hooks/permission-maps';

function ProfileManagement() {
  const [openEditPane, setOpenEditPane] = useState(false);
  const [showTwoFAModal, setShowTwoFAModal] = useState(false);
  const [ShowPasswordChangePane, setShowPasswordChangePane] = useState(false);
  const [showDeleteOrgModal, setShowDeleteOrgModal] = useState(false);
  const dispatch = useAppDispatch();
  const profile: IAccountState = useAppSelector((state) => state.account);
  const { is_twofa_enabled } = useAppSelector((state) => state.auth);

  const toggleShowTwoFaModal = () => {
    setShowTwoFAModal(!showTwoFAModal);
  };

  const toggleShowDeleteOrgModal = () => {
    setShowDeleteOrgModal(!showDeleteOrgModal);
  };

  return (
    <div className="profile-management">
      {profile.isLoading ? (
        <PageLoading loadingMessage="Getting Your Profile" />
      ) : (
        <>
          <Card className="change-logo-card">
            <CardBody>
              <UploadImageModal
                value={profile?.image_url ?? ''}
                title="Profile Picture"
                isLoading={
                  profile.isUpdatingAccount === 'loading.profile-image'
                }
                modal_description="This is the image displayed as your profile picture. You can upload an image of your choice from your device."
                uploadAction={(image, onSuccess) => {
                  dispatch(
                    updateUserProfileImgAction({
                      full_name: profile.full_name,
                      phone_number: profile.phone_number,
                      raw_profile_image: image,
                    })
                  )
                    .unwrap()
                    .then(() => {
                      onSuccess();
                    });
                }}
                resetAction={(onSuccess) => {
                  dispatch(
                    updateUserProfileImgAction({
                      full_name: profile.full_name,
                      phone_number: profile.phone_number,
                    })
                  )
                    .unwrap()
                    .then(() => {
                      onSuccess();
                    });
                }}
              />
            </CardBody>
          </Card>

          <Card
            cardHeading={
              <CardHeader
                title="Profile Details"
                additiionalButton={
                  <FillButton
                    onClick={() => {
                      setOpenEditPane(true);
                    }}
                    label="Edit"
                    variant="ghost"
                  />
                }
                collapsible
              />
            }
            className="profile-management-card"
          >
            <CardBody>
              <div className="detail-row">
                <div className="detail-field">
                  <p className="field-title">Account Name</p>
                  <p>{profile.full_name || '-'}</p>
                </div>

                <div className="detail-field">
                  <p className="field-title">Phone Number</p>
                  <p>{profile.phone_number || '-'}</p>
                </div>

                <div className="detail-field">
                  <p className="field-title">Email Address</p>
                  <p>{profile.email || '-'}</p>
                </div>

                <div className="detail-field">
                  <p className="field-title">Current Organization</p>
                  <p>{profile.current_organization_profile.name || '-'}</p>
                </div>
              </div>
              <EditCompanyPane
                isOpen={openEditPane}
                onClose={() => {
                  setOpenEditPane(!openEditPane);
                }}
              />
              <ChangePasswordPane
                isOpen={ShowPasswordChangePane}
                onClose={() => {
                  setShowPasswordChangePane(!ShowPasswordChangePane);
                }}
              />
            </CardBody>
          </Card>

          <Card className="profile-password-2fa-container">
            <CardBody>
              <CardHeader
                title="Password"
                titleClassName="sm"
                description="Change your password"
                additiionalButton={
                  <FillButton
                    onClick={() => {
                      setShowPasswordChangePane(!ShowPasswordChangePane);
                    }}
                    label="Change Password"
                  />
                }
              />
            </CardBody>

            <Divider />

            <CardBody>
              <CardHeader
                title="Two-Factor Authentication"
                titleClassName="sm"
                description="Add an extra layer of protection when you login with 2FA"
                additiionalButton={
                  <FillButton
                    colorVariant={
                      is_twofa_enabled
                        ? ButtonColor.danger
                        : ButtonColor.primary
                    }
                    label={is_twofa_enabled ? 'Deactivate 2FA' : 'Setup 2FA'}
                    onClick={toggleShowTwoFaModal}
                  />
                }
              />
            </CardBody>
            <PermissionsGate scopes={[ADJUTOR_SCOPES.canDeleteOrganization]}>
              <>
                <Divider />

                <CardBody>
                  <CardHeader
                    title="Delete Organization"
                    titleClassName="sm"
                    description="Delete your organisation and all associated data"
                    additiionalButton={
                      <FillButton
                        colorVariant={ButtonColor.danger}
                        label={'Delete my organisation'}
                        onClick={toggleShowDeleteOrgModal}
                      />
                    }
                  />
                </CardBody>
              </>
            </PermissionsGate>
          </Card>

          {showTwoFAModal && (
            <ToggleTwo2FAModal
              showTwoFaModal={showTwoFAModal}
              toggleShowTwoFaModal={toggleShowTwoFaModal}
            />
          )}

          {showDeleteOrgModal && (
            <ToggleDeleteOrgModal
              showDeleteOrgModal={showDeleteOrgModal}
              toggleShowDeleteOrgModal={toggleShowDeleteOrgModal}
              proceedTo2fa={toggleShowTwoFaModal}
            />
          )}
        </>
      )}
    </div>
  );
}

export default ProfileManagement;
