import React, { useEffect } from 'react';
import Select from '../../../../components/input-fields/Select/select';
import Input from '../../../../components/input-fields/input/input';
import { getTeamMembersAction } from '../../../../store/settings/settings.actions';
import { useAppDispatch, useAppSelector } from '../../../../store/customHooks';
import { GetEventLogActionProps } from '../../../../store/audits/types';

interface FilterModalProps {
  filter: Record<string, any>;
  setFilter: React.Dispatch<React.SetStateAction<GetEventLogActionProps>>;
  initialFilter: {
    page: number;
    limit: number;
  };
  handleFilter: (e: any) => void;
}

const EventFilterModal = ({ filter, handleFilter }: FilterModalProps) => {
  const dispatch = useAppDispatch();
  const { allTeamMembers } = useAppSelector((state) => state.settings);

  useEffect(() => {
    if (allTeamMembers.length < 1) {
      dispatch(getTeamMembersAction());
    }
  }, []);

  const membersOptions = allTeamMembers.map((member) => {
    return { label: member.full_name, value: member.email };
  });

  const findMember = (email: string) => {
    const member = allTeamMembers.find((member) => {
      return member?.email?.toLowerCase() === email?.toLowerCase();
    });
    if (!member) {
      return {};
    }
    return { label: member?.full_name, value: member?.email };
  };

  return (
    <>
      <Select
        name="user"
        label="Admin"
        onChange={(val) => {
          handleFilter({ name: 'user', value: val.value });
        }}
        selectOptions={membersOptions}
        value={findMember(filter?.user)}
        selectPlaceholder={filter?.user}
        isSearchable={true}
      />

      <Input
        name="date"
        type="date"
        label="Date"
        value={filter.date ?? ''}
        max={new Date().toISOString().split('T')[0]}
        onChange={handleFilter}
      />
    </>
  );
};

export default EventFilterModal;
