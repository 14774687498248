import { createSlice } from '@reduxjs/toolkit';

type Props = {
  isSidebarOpen: boolean;
  breadcrumb: {
    title: string;
    path: string;
  }[];
};

const initialState = {
  isSidebarOpen: false,
  breadcrumb: [],
} as Props;

const LayoutSlice = createSlice({
  name: 'LayoutState',
  initialState,

  reducers: {
    OpenSideBar: (state) => {
      state.isSidebarOpen = true;
    },
    CloseSideBar: (state) => {
      state.isSidebarOpen = false;
    },
    updateBreadcrumb: (
      state,
      {
        payload,
      }: {
        payload: {
          title: string;
          path: string;
        }[];
      }
    ) => {
      state.breadcrumb = payload;
    },
  },

  extraReducers: () => {},
});

export const { OpenSideBar, CloseSideBar, updateBreadcrumb } = LayoutSlice.actions;

export default LayoutSlice.reducer;
