import React from "react";
import Card from "../../../../components/common/cards/cards";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import urls from "../../../../routes/all-routes";

const ModuleCard = (props: any) => {
  const { module, required } = props;

  const params = useParams();

  const navigate = useNavigate();

  const setFocusModule = (id: string) => {
    let decisionModelUrl = urls.business.decision_model.for(`${params?.id}`);
    let navigateUrl = `${decisionModelUrl}?module-id=${id}`;
    navigate(navigateUrl);
  };

  const [searchParams] = useSearchParams();

  return (
    <Card
      className={`module-card ${
        searchParams?.get("module-id") === module?.name && "active"
      }`}
      onClick={() => {
        setFocusModule(module?.name);
      }}
    >
      <p className="card-title">{module?.name?.replace(/_/g, ' ')}</p>

      <div className="boolean-value">
        Required:{" "}
        <span data-boolean-value={required}>
          {required ? "True" : "False"}
        </span>
      </div>
    </Card>
  );
};

export default ModuleCard;
