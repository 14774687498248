import React, { useState, useCallback } from 'react';
import './_events.scss';

import Table from '../../../../components/table/table';
import TableRow from '../../../../components/table/tablebody';
import MobileTable from '../../../../components/table/mobile-table/mobile-table';
import MobileTableRow from '../../../../components/table/mobile-table/mobile-tablebody';
import DropdownMenu from '../../../../components/common/dropdown/dropdown-menu';
import DropdownOption from '../../../../components/common/dropdown/dropdown-option';

//icons
import { ReactComponent as MoreIcon } from '../../../../assets/icons/more.svg';
import { useAppDispatch, useAppSelector } from '../../../../store/customHooks';
import { getEventLogAction } from '../../../../store/audits/audits.actions';
import { fullDateFormat } from '../../../../utils/formatter';
import Pagination from '../../../../components/common/pagination/Pagination';
import { useNavigate } from 'react-router-dom';
import urls from '../../../../routes/all-routes';
import EmptyTable from '../../../../components/empty-table/EmptyTable';
import CardFooter from '../../../../components/common/cards/card-footer';
import Card from '../../../../components/common/cards/cards';
import useScreenSizeHook from '../../../../hooks/useScreenSizeHook';
import useFilterHook from '../../../../hooks/useFilterHook';
import EventFilterModal from './event-filter';

function Events() {
  const navigate = useNavigate();
  const { isDesktop } = useScreenSizeHook();

  const dispatch = useAppDispatch();
  const auditState = useAppSelector((state) => state.audit);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const initialFilter = {
    page: currentPage,
    limit: perPage,
  };

  const { filter, handleFilter, setFilter, resetFilter } = useFilterHook({
    initialFilter,
  });

  const fetchEvents = useCallback(() => {
    dispatch(
      getEventLogAction({
        ...filter,
        page: currentPage,
        limit: perPage,
      })
    );
  }, [perPage, currentPage]);

  const loadPage = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  const viewDetails = (id: number) => {
    navigate(urls.business.audits_trails.events.for(`${id}`));
  };

  return (
    <div className="events-table">
      <Card>
        {isDesktop ? (
          <Table
            title="Activity Logs"
            tableHeader={['Description', 'Event Type', 'User Name', 'Date']}
            more={true}
            isLoading={
              auditState.eventsState === 'loading' &&
              auditState.eventsLog.length === 0
            }
            isEmpty={auditState.eventsLog.length === 0}
            emptyStateComponent={
              <EmptyTable
                message="No Event yet"
                additional_message="Your events log will appear here"
              />
            }
            filter={
              <EventFilterModal
                filter={filter}
                setFilter={setFilter}
                initialFilter={initialFilter}
                handleFilter={handleFilter}
              />
            }
            handleFilterReset={() => {
              setFilter(initialFilter);
              resetFilter();
              dispatch(getEventLogAction(initialFilter));
            }}
            handleFilterSubmit={() => {
              dispatch(getEventLogAction(filter));
            }}
          >
            {auditState.eventsLog.map((action) => {
              return (
                <TableRow
                  key={action.id}
                  more={true}
                  moreOption={
                    <DropdownMenu
                      renderButton={<MoreIcon />}
                      className="action-dropdown"
                      id={`dropdown-menu-${action?.id}`}
                    >
                      <DropdownOption
                        onClick={() => {
                          viewDetails(action.id);
                        }}
                      >
                        View Details
                      </DropdownOption>
                    </DropdownMenu>
                  }
                  onRowClickAction={() => {
                    viewDetails(action.id);
                  }}
                >
                  <td className="event-field">{action?.description || '-'} </td>
                  <td className="event-field">{action?.action || '-'} </td>

                  <td className="who-field">
                    {action?.admin?.full_name || '-'}
                  </td>

                  <td className="when-field">
                    {fullDateFormat(action.user_view || action?.created_on)}
                  </td>
                </TableRow>
              );
            })}
          </Table>
        ) : (
          <MobileTable
            isLoading={
              auditState.eventsState === 'loading' &&
              auditState.eventsLog.length === 0
            }
            title="Activity Logs"
            isEmpty={auditState.eventsLog.length === 0}
            emptyStateComponent={
              <EmptyTable
                message="No Event yet"
                additional_message="Your events log will appear here"
              />
            }
            filter={
              <EventFilterModal
                filter={filter}
                setFilter={setFilter}
                initialFilter={initialFilter}
                handleFilter={handleFilter}
              />
            }
            handleFilterReset={() => {
              setFilter(initialFilter);
              resetFilter();
              dispatch(getEventLogAction(initialFilter));
            }}
            handleFilterSubmit={() => {
              dispatch(getEventLogAction(filter));
            }}
          >
            {auditState.eventsLog.map((action) => {
              return (
                <MobileTableRow
                  key={action.id}
                  moreOption={
                    <DropdownMenu
                      renderButton={<MoreIcon />}
                      className="action-dropdown"
                      id={`dropdown-menu-${action?.id}`}
                    >
                      <DropdownOption>View Details</DropdownOption>
                    </DropdownMenu>
                  }
                  onRowClickAction={() => {
                    viewDetails(action.id);
                  }}
                >
                  <div className="field-container event-field">
                    <span className="field-title">Description: </span>
                    {action?.description}
                  </div>

                  <div className="field-container event-field">
                    <span className="field-title">Event Type: </span>
                    {action?.action}
                  </div>

                  <div className="field-container who-field">
                    <span className="field-title">User Name: </span>
                    {action?.admin?.full_name || '-'}
                  </div>

                  <div className="field-container when-field">
                    <span className="field-title">Date: </span>
                    {fullDateFormat(action.user_view || action?.created_on)}
                  </div>
                </MobileTableRow>
              );
            })}
          </MobileTable>
        )}

        <CardFooter>
          {auditState.eventsLog && (
            <Pagination
              total={auditState.totalEventsSize}
              perPage={perPage}
              setPerPage={setPerPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              loadPage={loadPage}
              perPageTitle="Events"
              updateDataCallback={fetchEvents}
            />
          )}
        </CardFooter>
      </Card>
    </div>
  );
}

export default Events;
