import React, { useState } from "react";
import "./_configuration-field.scss";
import Input from "../../../../input-fields/input/input";
import { FormikProps, useFormikContext } from "formik";
import { useSearchParams } from "react-router-dom";

function DecisionIntegerField(props: any) {
  const { setFieldValue }: FormikProps<any> = useFormikContext();

  const [searchParams] = useSearchParams();

  const [data, setData] = useState(props.value);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setData(Number(value));

    setFieldValue(
      `decision_setting.${searchParams.get("module-id")}.${props.id}`,
      Number(value)
    );
  };

  return (
    <div className="decision-setting-field">
      <div className="decision-setting-title">{props.id?.replace(/_/g, ' ')}</div>
      <div className="decision-setting-input">
        <Input
          name={props.id}
          placeholder="00"
          type="number"
          label=""
          defaultValue={props.value}
          value={data}
          onChange={handleChange}
        />
      </div>
    </div>
  );
}

export default DecisionIntegerField;
