import React, { useEffect } from 'react';
import './_api-pricing.scss';

import Table from '../../../components/table/table';
import TableRow from '../../../components/table/tablebody';

//icons
import MobileTable from '../../../components/table/mobile-table/mobile-table';
import MobileTableRow from '../../../components/table/mobile-table/mobile-tablebody';
import { currencyFormat } from '../../../utils/formatter';
import { useAppDispatch, useAppSelector } from '../../../store/customHooks';
import { getAllApiPricingAction } from '../../../store/settings/settings.actions';
import {
  IAPIPricingType,
  ISettingsState,
} from '../../../store/settings/settings.interface';
import Chip from '../../../components/common/chip/chip';
import PageLoading from '../../../components/page-loading/pageLoading';
import EmptyTable from '../../../components/empty-table/EmptyTable';
import useScreenSizeHook from '../../../hooks/useScreenSizeHook';

function ApiPricing() {
  const { isDesktop } = useScreenSizeHook();

  const dispatch = useAppDispatch();
  const settingState: ISettingsState = useAppSelector(
    (state) => state.settings
  );

  useEffect(() => {
    dispatch(getAllApiPricingAction());
  }, []);

  return (
    <div className="api-pricing-tab">
      {settingState.isApiPlansLoading &&
      settingState.allApiPlans?.length === 0 ? (
        <PageLoading loadingMessage="Getting Your API Plans" />
      ) : isDesktop ? (
        <Table
          title="API Pricing"
          tableHeader={['Name', 'Entity', 'Type', 'Fee Type', 'Amount']}
          more={true}
          className="endpoint-pricing-table"
          isEmpty={settingState.allApiPlans.length === 0}
          emptyStateComponent={<EmptyTable message="No API Prices available" />}
        >
          {settingState.allApiPlans.map((service: IAPIPricingType) => {
            return (
              <TableRow key={service.id}>
                <td className="endpoint-field">{service?.name}</td>

                <td>{service?.entityId}</td>
                <td>
                  <Chip
                    indicatior={false}
                    data-service={service?.serviceChargeType.toLowerCase()}
                    label={service?.serviceChargeType}
                  />
                </td>
                <td>{service?.chargeType}</td>

                <td className="price-field">
                  {currencyFormat(
                    Number(service?.baseCharge),
                    service?.currencyCode
                  )}
                  {service?.percentageCharge &&
                    Number(service.maximumCharge) > 0 &&
                    ` + ${service?.percentageCharge}% max of ${currencyFormat(
                      Number(service?.maximumCharge),
                      service?.currencyCode
                    )}`}
                </td>
              </TableRow>
            );
          })}
        </Table>
      ) : (
        <MobileTable
          title="API Pricing"
          className="endpoint-pricing-table"
          isEmpty={settingState.allApiPlans.length === 0}
          emptyStateComponent={<EmptyTable message="No API Prices available" />}
        >
          {settingState.allApiPlans.map((service: IAPIPricingType) => {
            return (
              <MobileTableRow key={service.id}>
                <div className="field-container endpoint-field">
                  <span className="field-title">Name: </span>
                  {service?.name}
                </div>

                <div className="field-container">
                  <span className="field-title">Entity: </span>
                  {service?.entityId}
                </div>

                <div className="field-container">
                  <span className="field-title">Type: </span>
                  <Chip
                    indicatior={false}
                    data-service={service?.serviceChargeType.toLowerCase()}
                    label={service?.serviceChargeType}
                  />
                </div>

                <div className="field-container">
                  <span className="field-title">Fee Type: </span>
                  {service?.chargeType}
                </div>

                <div className="field-container price-field">
                  <span className="field-title">Price: </span>
                  {currencyFormat(
                    Number(service?.baseCharge),
                    service?.currencyCode
                  )}
                  {service?.percentageCharge &&
                    Number(service.maximumCharge) > 0 &&
                    ` + ${service?.percentageCharge}% max of ${currencyFormat(
                      Number(service?.maximumCharge),
                      service?.currencyCode
                    )}`}
                </div>
              </MobileTableRow>
            );
          })}
        </MobileTable>
      )}
    </div>
  );
}

export default ApiPricing;
