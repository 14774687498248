import React, { ReactElement } from 'react';
import Style from './_page-heading.module.scss';

type Props = {
  title: string;
  description?: string | ReactElement;
  rightElement?: ReactElement;
};

function PageTitle({ title, description, rightElement }: Readonly<Props>) {
  return (
    <div className={Style.page_heading_container}>
      <div className={Style.page_heading}>
        <h6>{title}</h6>
        {description && <p className="sm">{description}</p>}
      </div>

      <div className={Style.page_heading_rightElement}>{rightElement}</div>
    </div>
  );
}

export default PageTitle;
