export const ADJUTOR_SCOPES = {
  canCreateApps: "can-create-apps",
  canEditApps: "can-edit-apps",
  canDeleteApps: "can-delete-apps",
  canViewApps: "can-view-apps",
  canViewEvent: "can-view-event",
  canViewAudit: "can-view-audit",
  canEditAccountInfo: "can-edit-account-info",
  canViewPermissions: "can-view-permissions",
  canViewPricing: "can-view-pricing",
  canResetAppToken: "can-reset-app-token",
  canDeleteOrganization: "can-delete-organization",
};

export const ADJUTOR_GROUPS = {
  viewer: "VIEWER",
  admin: "ADMIN",
  super_admin: "SUPER-ADMIN",
};

export const ADJUTOR_ROLES: {
  name: string;
  id: number;
  description: string;
  permissions: string[];
}[] = [
  {
    name: "TEAM",
    id: 3,
    description: "Team members of the organization",
    permissions: [
      ADJUTOR_SCOPES.canViewApps,
      ADJUTOR_SCOPES.canViewAudit,
      ADJUTOR_SCOPES.canViewEvent,
      ADJUTOR_SCOPES.canViewPermissions,
    ],
  },
  {
    name: "ADMIN",
    id: 2,
    description: "Has access and permissions to a degree of administrative tasks",
    permissions: [
      ADJUTOR_SCOPES.canViewApps,
      ADJUTOR_SCOPES.canEditApps,
      ADJUTOR_SCOPES.canViewAudit,
      ADJUTOR_SCOPES.canViewEvent,
      ADJUTOR_SCOPES.canViewPermissions,
      ADJUTOR_SCOPES.canViewPricing,
    ],
  },
  {
    name: "SUPER-ADMIN",
    id: 1,
    description: "Can access all",

    permissions: [
      ADJUTOR_SCOPES.canViewApps,
      ADJUTOR_SCOPES.canEditApps,
      ADJUTOR_SCOPES.canViewApps,
      ADJUTOR_SCOPES.canCreateApps,
      ADJUTOR_SCOPES.canDeleteApps,
      ADJUTOR_SCOPES.canViewAudit,
      ADJUTOR_SCOPES.canViewEvent,
      ADJUTOR_SCOPES.canViewPermissions,
      ADJUTOR_SCOPES.canViewPricing,
      ADJUTOR_SCOPES.canEditAccountInfo,
      ADJUTOR_SCOPES.canResetAppToken,
      ADJUTOR_SCOPES.canDeleteOrganization,
    ],
  },
];
