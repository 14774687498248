import React, { useEffect } from 'react';
import './styles/index.scss';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { protectedRoutes, unProtectedRoutes } from './routes/routes';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store/store';
import InAppLayout from './layout/inAppLayout/inAppLayout';
import { ADJUTOR_ROLES } from './utils/hooks/permission-maps';
import { hasPermission } from './utils/hooks/permission-gate';
import { toast, useToasterStore } from 'react-hot-toast';

function App() {
  const { permissions }: any = ADJUTOR_ROLES.find((role) => {
    return role.id === 2;
  });

  const { toasts } = useToasterStore();

  const TOAST_LIMIT = 1;

  useEffect(() => {
    toasts
      .filter((t) => t.visible) // Only consider visible toasts
      .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit?
      .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) for no exit animation
  }, [toasts]);

  return (
    <div className="App">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor as any}>
          <React.Fragment>
            <BrowserRouter>
              <Routes>
                {protectedRoutes.map((route) => {
                  const { path, element, scopes, breadcrumb } = route;

                  if (route.scopes?.length) {
                    const permissionGranted = hasPermission({
                      permissions,
                      scopes,
                    });
                    if (!permissionGranted) return <></>;

                    return (
                      <Route
                        key={path}
                        path={path}
                        element={
                          <InAppLayout breadcrumb={breadcrumb ?? []}>
                            {element}
                          </InAppLayout>
                        }
                      />
                    );
                  } else if (!route.scopes) {
                    return (
                      <Route
                        key={path}
                        path={path}
                        element={
                          <InAppLayout breadcrumb={breadcrumb ?? []}>
                            {element}
                          </InAppLayout>
                        }
                      />
                    );
                  }
                })}

                {unProtectedRoutes.map((route) => {
                  const { path, element } = route;
                  return <Route key={path} path={path} element={element} />;
                })}
              </Routes>
            </BrowserRouter>
          </React.Fragment>
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
