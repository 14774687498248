import React, { useEffect, useState } from 'react';
import { FormikProps, useFormikContext } from 'formik';
import { RenderModelConfigOptions } from '../../../../components/decision-model/configuration/render-model-config-options';
import { objectToArray } from '../../../../utils/helper';
import JsonEditior from '../../../../components/json-editor';

interface Props {
  setting: any;
  activeModule: string;
}

function MainConfiguration(props: Props) {
  const { setValues }: FormikProps<any> = useFormikContext();

  const { setting, activeModule } = props;

  const [availableField, setAvailableField] = useState('');

  const availableSetting = objectToArray(setting);

  useEffect(() => {
    setAvailableField(setting);
  }, [setting]);

  const handleChange = (value: any) => {
    setValues(value);
  };

  return (
    <div className="main-configuration">
      <div className="configuration-body">
        {activeModule?.toLowerCase() !== 'scoring' ? (
          availableSetting.map((setting) => {
            return (
              <RenderModelConfigOptions
                key={`${setting.key}`}
                name={setting.key}
                field={setting.arr}
              />
            );

            // SCORING UI IS STILL NOT SCALABLE
            // <RenderScoringConfigOption
            //   key={`${setting.key}`}
            //   name={setting.key}
            //   field={setting.arr}
            // />
          })
        ) : (
          <JsonEditior value={availableField} onChange={handleChange} />
        )}
      </div>
    </div>
  );
}

export default MainConfiguration;
