import React from "react";
import Style from "./_spinner.module.scss";

export interface ISpinner {
  className?: string;
}

export default function Spinner(props: ISpinner) {
  return (
    <div
      className={`${Style.spinner_container} ${
        props.className ?? ''
      }`}
    >
      <svg className={Style.spinner} viewBox="0 0 50 50">
        <circle
          className={Style.path}
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth="5"
        ></circle>
      </svg>
    </div>
  );
}
