import React, { useEffect } from 'react';
import OtpInput, { OTPInputProps } from 'react-otp-input';
import './_pin.scss';

export interface PinType extends Omit<OTPInputProps, 'renderInput'> {
  onChange: (value: string | number, index?: number) => void;
  onComplete: (value: string | number, index?: number) => void;
  length: number;
  value: string;
  error?: boolean;
  errorMessage?: string;
}

function PinField({
  onComplete,
  onChange,
  value = '',
  length = 6,
  error = false,
  errorMessage = '',
  ...rest
}: Readonly<PinType>) {
  useEffect(() => {
    if (value.length === length) {
      onComplete(value);
    }
  }, [value]);

  const handleChange = (value: string) => {
    onChange(value);
  };

  return (
    <div className="pin-field-container">
      <OtpInput
        {...rest}
        value={value}
        numInputs={length}
        onChange={handleChange}
        renderSeparator={(index) =>
          index === 2 && (
            <span className="pin-input-separator">
              <svg
                width="16"
                height="2"
                viewBox="0 0 16 2"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="0.221924"
                  y1="1"
                  x2="15.7783"
                  y2="1"
                  stroke="#44546F"
                  strokeWidth="2"
                />
              </svg>
            </span>
          )
        }
        renderInput={(props) => (
          <div className={`pincode-input-text ${error ? 'input-error' : ''}`}>
            <input {...props} style={{ width: '100%', height: '100%' }} />
          </div>
        )}
        containerStyle="pincode-input-container"
        shouldAutoFocus={true}
      />

      {errorMessage && error && <p className="error-message">{errorMessage}</p>}
    </div>
  );
}

export default PinField;
