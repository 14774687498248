import { createSlice } from '@reduxjs/toolkit';
import { getAuditsActionAggregate } from './audits.actions';
import { IAuditsLogAggregate } from './audits.interface';

const initialState = {
  auditsLogAggregate: [],
  // auditAggregateState: 'idle',
  // error: '',
} as IAuditsLogAggregate;

const AuditsLogsAggregateSlice = createSlice({
  name: 'AuditsActionAggregate',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    //Audits Logs
    builder.addCase(
      getAuditsActionAggregate.fulfilled,
      (state: IAuditsLogAggregate, action: any) => {
        state.auditsLogAggregate = action.payload.calls;
      }
    );
  },
});

export default AuditsLogsAggregateSlice.reducer;
