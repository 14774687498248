import { useEffect, useState } from 'react';

function useScreenSizeHook() {
  const [isDesktop, setDesktop] = useState(window.innerWidth >= 1024);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 900);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, [isDesktop]);

  return { isDesktop };
}

export default useScreenSizeHook;
