import React, { useState } from 'react';
import FillButton, { ButtonColor } from '../common/buttons/button';
import './_table.scss';

//icon
import { ReactComponent as FilterIcon } from '../../assets/icons/filter.svg';
import { TableProps } from './table.interface';
import Spinner from '../common/spinner/spinner';
import Card from '../common/cards/cards';
import SlidingPane from '../slidingpane/slidingpane';
import ModalFooter from '../common/modal/modal-footer';
import CardBody from '../common/cards/card-body';

export default function Table(props: TableProps) {
  const [openFilter, setOpenFilter] = useState(false);
  const [filtered, setFiltered] = useState(false);

  const handleToggleFilter = () => {
    setOpenFilter(!openFilter);
  };

  return (
    <Card
      className={`table-container ${props.className ? props.className : ''}`}
    >
      <div className="table-heading">
        {props.title && (
          <span className="title text-text-primary">{props.title}</span>
        )}

        {props.filter && (!props.isEmpty || filtered) && (
          <FillButton
            variant="outlined"
            label="Filter"
            className="filter-btn"
            onClick={handleToggleFilter}
            icon={<FilterIcon />}
            colorVariant={ButtonColor.neutral}
          />
        )}

        {props.tableHeaderContent && !props.isEmpty && (
          <div className="table-header-additional-content">
            {props.tableHeaderContent}
          </div>
        )}
      </div>

      <div className={`table-wrapper`}>
        {(!props.isEmpty || props.isLoading) && (
          <table
            data-testid="table"
            className={`table ${props.tableClass}`}
            data-rowicon={!!props.rowIcon}
          >
            <thead>
              <tr className={props.tableHeaderClass}>
                {props.rowIcon && <th></th>}

                {props.tableHeader.map((header: string, index: number) => {
                  return <th key={index}>{header}</th>;
                })}

                {props.more && <th></th>}
              </tr>
            </thead>

            <tbody data-loading={props.isLoading}>
              {props.isLoading ? (
                <div className="table-spinner">
                  <Spinner />
                </div>
              ) : (
                props.children
              )}
            </tbody>
          </table>
        )}

        {!props.isLoading && props.isEmpty && (
          <CardBody className="table-empty-state-conatiner">
            {props.emptyStateComponent}
          </CardBody>
        )}
      </div>

      <SlidingPane
        isOpen={openFilter}
        onClose={handleToggleFilter}
        title="Filter"
        footer={
          <ModalFooter>
            <FillButton
              variant="outlined"
              onClick={() => {
                handleToggleFilter();
                props.handleFilterReset?.();
                setFiltered(false);
              }}
              className="close-button"
              colorVariant={ButtonColor.neutral}
              label="Reset"
            />

            <FillButton
              type="submit"
              onClick={() => {
                props.handleFilterSubmit?.();
                handleToggleFilter();
                setFiltered(true);
              }}
              label="Filter"
            />
          </ModalFooter>
        }
        bodyClassName="table-filter-form"
      >
        {props.filter}
      </SlidingPane>
    </Card>
  );
}
