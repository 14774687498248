import React, { useMemo, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import Card from '../../../../components/common/cards/cards';
import Tooltip from '../../../../components/common/tooltip/tooltip';
import WorkspaceCard from './workspace-card';
import './_workspace.scss';

//icon
import { ReactComponent as TooltipIcon } from '../../../../assets/icons/exclamation-with-border.svg';
import { ReactComponent as ArrowrightIcon } from '../../../../assets/icons/arrow-right.svg';
import { useAppDispatch, useAppSelector } from '../../../../store/customHooks';
import { IDecisionModels } from '../../../../store/decision-model/decision-model.interface';
import { objectToArray } from '../../../../utils/helper';
import Spinner from '../../../../components/common/spinner/spinner';
import { useFormik } from 'formik';
import {
  SortableContainer,
  SortableItem,
} from '../../../../components/sort-reorder/sort-reorder';
import EditModelPane from '../../../../components/decision-model/edit-model/edit-model-details';
import { updateModelAction } from '../../../../store/decision-model/decision-model.actions';
import CardBody from '../../../../components/common/cards/card-body';

function WorkspacesComp() {
  const [showEditModelPane, setShowEditModelPane] = useState(false);
  const workspaceRef = useRef(null);
  const dispatch = useAppDispatch();
  const decisionModel: IDecisionModels = useAppSelector(
    (state) => state.decisionModel
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: decisionModel?.focusModel?.decision_setting,
    onSubmit: async (values) => {
      let modelUpdate = {
        name: decisionModel.focusModel.name,
        description: decisionModel.focusModel.description,
        offer_setting: decisionModel.focusModel.offer_setting,
        decision_setting: values,
        status: decisionModel.focusModel.status,
      };
      dispatch(
        updateModelAction({
          payload: modelUpdate,
          id: decisionModel.focusModel.id,
        })
      );
    },
  });

  const [currentArr, setCurrentArr] = useState<{ arr: any; key: any }[]>([]);

  useMemo(() => {
    let x = objectToArray(decisionModel?.focusModel?.decision_setting);
    setCurrentArr(x);
  }, [decisionModel?.focusModel?.decision_setting]);

  const onSortEnd = async ({ oldIndex, newIndex }: any) => {
    let newItems = [...currentArr];

    if (oldIndex > newIndex) {
      for (let i = oldIndex - 1; i >= newIndex; i--) {
        newItems[i].arr.sequence++;
        newItems[oldIndex].arr.sequence = newIndex + 1;
      }
    } else if (oldIndex < newIndex) {
      for (let i = oldIndex + 1; i <= newIndex; i++) {
        newItems[i].arr.sequence--;
        newItems[oldIndex].arr.sequence = newIndex + 1;
      }
    }
    let sortedItems = newItems
      .slice()
      .sort((a, b) => a.arr.sequence - b.arr.sequence);
    setCurrentArr(sortedItems);

    const decisionSettingVal = newItems
      .sort((a, b) => a.arr.sequence - b.arr.sequence)
      .reduce(
        (a: any, moduleArr: any) => ({ ...a, [moduleArr.key]: moduleArr.arr }),
        {}
      );

    await formik.setValues(decisionSettingVal);

    formik.submitForm();
  };

  return (
    <Card className="workspaces-container">
      <CardBody>
        <div className="decision-card-header">
          <div className="decision-card-title">
            Workspace
            <Tooltip content="Press hold to select and drag each tab up and down to set your sequence">
              <TooltipIcon />
            </Tooltip>
          </div>

          <div>
            <button
              className="edit-button"
              onClick={() => {
                setShowEditModelPane(true);
              }}
            >
              Edit Details{' '}
              <span className="icon">
                <ArrowrightIcon />
              </span>
            </button>
          </div>
        </div>

        {decisionModel.focusModelStatus === 'loading' ? (
          <Spinner />
        ) : (
          <>
            <div className="basic-model-info">
              <div className="basic-model">
                <div className="title">MODEL NAME</div>
                <p>{decisionModel?.focusModel?.name}</p>
              </div>

              <div className="basic-model">
                <div className="title">MODEL DESCRIPTION</div>
                <p>{decisionModel?.focusModel?.description}</p>
              </div>
            </div>

            <div className="workspace-list">
              <div className="workspace-list-item">
                <div className="item-idex-container">
                  {currentArr?.map((workspace, idx: number) => {
                    return (
                      <div className="item-index" key={idx}>
                        0{idx + 1}
                      </div>
                    );
                  })}
                </div>

                <div className="workspace-card-container">
                  <SortableContainer
                    lockAxis="y"
                    axis="y"
                    lockToContainerEdges={true}
                    containerRef={workspaceRef}
                    getContainer={() =>
                      // eslint-disable-next-line react/no-find-dom-node
                      ReactDOM.findDOMNode(workspaceRef.current)
                    }
                    onSortEnd={onSortEnd}
                  >
                    {currentArr?.map((workspace, index) => {
                      return (
                        <SortableItem key={index + 1} index={index}>
                          <WorkspaceCard workspace={workspace} />
                        </SortableItem>
                      );
                    })}
                  </SortableContainer>
                </div>
              </div>
            </div>
          </>
        )}

        <EditModelPane
          isOpen={showEditModelPane}
          onClose={setShowEditModelPane}
        />
      </CardBody>
    </Card>
  );
}

export default WorkspacesComp;
