import React, { useState } from 'react';
import Modal, { ModalPosition } from '../../../components/common/modal/modal';
import ModalFooter from '../../../components/common/modal/modal-footer';
import FillButton from '../../../components/common/buttons/button';
import Input from '../../../components/input-fields/input/input';
import ClickToCopy from '../../../components/click-to-copy/click-to-copy';
import { ReactComponent as PasswordHidden } from '../../../assets/icons/content-hidden.svg';
import { ReactComponent as PasswordVisible } from '../../../assets/icons/content-visible.svg';

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  data: { appId: string; appName: string; appKey: string } | null;
};

function CreateAppSuccessTokenModal({
  isOpen,
  handleClose,
  data,
}: Readonly<Props>) {
  const [tokenVisible, setTokenVisible] = useState(false);

  return (
    <Modal
      isOpen={isOpen}
      title={'App created successfully'}
      onClose={() => {
        handleClose();
      }}
      className="reset-app-modal"
      modalPosition={ModalPosition.center}
      modalFooter={
        <ModalFooter>
          <FillButton
            onClick={() => {
              handleClose();
            }}
            label="Done"
          />
        </ModalFooter>
      }
    >
      <>
        <p>
          You have successfully created <b>{data?.appName}</b>.<br /> <br />
          Here are your app credentials. Please note that we will only show your
          secret key to you once (right now). If you forget it, you’ll have to
          reset your app's API key so make sure you copy it somewhere safe immediately.
        </p>

        <Input
          type={'text'}
          inputContainerStyle={{ marginTop: '1rem' }}
          value={data?.appId}
          label="App ID"
          disabled
          icon={
            <>
              <ClickToCopy
                showTooltip={false}
                className="click-to-copy-icon"
                text={data?.appId ?? ''}
              />
            </>
          }
          iconClassName="token-action-buttons"
        />

        <Input
          type={tokenVisible ? 'text' : 'password'}
          inputContainerStyle={{ marginTop: '1rem' }}
          value={data?.appKey}
          label="API Key"
          disabled
          icon={
            <>
              <div
                className="click-to-copy-icon"
                onClick={() => {
                  if (tokenVisible) {
                    setTokenVisible(false);
                  } else {
                    setTokenVisible(true);
                  }
                }}
              >
                {tokenVisible ? <PasswordVisible /> : <PasswordHidden />}
              </div>

              <ClickToCopy
                showTooltip={false}
                className="click-to-copy-icon"
                text={data?.appKey ?? ''}
              />
            </>
          }
          iconClassName="token-action-buttons"
        />
      </>
    </Modal>
  );
}

export default CreateAppSuccessTokenModal;
