import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { MultiSelectCheckboxOptionProps } from './types';
import Divider from '../../common/divider/divider';
import IconButton from '../../common/buttons/icon-button';
import Styles from './_multi-select-checkbox.module.scss';
import { ReactComponent as ArrowDown } from '../../../assets/icons/arrowhead-down.svg';
import MultiSelectCheckboxElement from './multi-select-checbox';

type MultiSelectCheckboxCategoryProps = {
  filteredOptions: MultiSelectCheckboxOptionProps[];
  values: MultiSelectCheckboxOptionProps['value'][];
  setValues: Dispatch<
    SetStateAction<MultiSelectCheckboxOptionProps['value'][]>
  >;
};

function MultiSelectCheckboxCategory({
  filteredOptions,
  values,
  setValues,
}: MultiSelectCheckboxCategoryProps) {
  const [collapsed, setCollapsed] = useState<{
    [category: string]: boolean;
  }>({});

  const toggleCollapse = (category: string) => {
    setCollapsed((prevCollapsed) => ({
      ...prevCollapsed,
      [category.toLowerCase()]: !prevCollapsed[category.toLowerCase()],
    }));
  };

  const getCategories = useMemo(() => {
    const categorized_data: {
      category_name: string;
      option: any[];
    }[] = [];

    filteredOptions.forEach((item) => {
      const exisitng_catgory = categorized_data.find((min) => {
        return (
          min.category_name?.toLowerCase() === item?.category?.toLowerCase()
        );
      });

      const uncategorized = categorized_data.find((min) => {
        return (
          min.category_name?.toLowerCase() === 'Uncategorized'.toLowerCase()
        );
      });

      if (item.category) {
        if (!exisitng_catgory && item.category) {
          categorized_data.push({
            category_name: item.category,
            option: [item],
          });
        }

        exisitng_catgory?.option.push(item);
      } else {
        if (!uncategorized) {
          categorized_data.push({
            category_name: 'Uncategorized',
            option: [item],
          });
        }

        uncategorized?.option.push(item);
      }
    });

    return categorized_data;
  }, [filteredOptions]);

  useEffect(() => {
    const categories = getCategories.map((option) => {
      return option.category_name?.toLowerCase?.();
    });

    const defaultValue = false;

    setCollapsed(
      categories.reduce<{ [key: string]: boolean }>((acc, key) => {
        acc[key] = defaultValue;
        return acc;
      }, {})
    );
  }, [getCategories]);

  return (
    <>
      {getCategories.map((option) => {
        const getCount = () => {
          let count = 0;
          values.forEach((value) => {
            option.option.find((option) => {
              if (option.value === value) {
                count += 1;
              }
            });
          });

          return count;
        };

        return (
          <div className={Styles.selected_category} key={option.category_name}>
            <div
              className={Styles.selected_category_header}
              onClick={() => {
                toggleCollapse(option.category_name);
              }}
            >
              <p className="sm font-600">
                {option.category_name ?? 'Uncategorized'} ({getCount()} of{' '}
                {option.option?.length} scopes selected)
              </p>

              <IconButton
                type="button"
                icon={<ArrowDown />}
                className={Styles.selected_category_header_close_btn}
              />
            </div>

            {collapsed[option.category_name?.toLowerCase()] && (
              <div className={Styles.selected_options}>
                {option.option.map((option) => {
                  return (
                    <MultiSelectCheckboxElement
                      key={`${option?.value}`}
                      option={option}
                      value={values.includes(option.value)}
                      handleChange={(e: any) => {
                        if (e.target.checked) {
                          setValues([...values, option?.value]);
                          return;
                        }

                        const updatedValue = values?.filter((services) => {
                          return (
                            services.toLowerCase() !==
                            option?.value?.toLowerCase()
                          );
                        });
                        setValues(updatedValue);
                      }}
                    />
                  );
                })}
              </div>
            )}

            <Divider />
          </div>
        );
      })}
    </>
  );
}

export default MultiSelectCheckboxCategory;
