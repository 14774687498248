import React from 'react';
import Card from '../../../../components/common/cards/cards';
import { useAppSelector } from '../../../../store/customHooks';
import ModuleCard from './module-card';
import './_modules.scss';
import { IDecisionModels } from '../../../../store/decision-model/decision-model.interface';
import Spinner from '../../../../components/common/spinner/spinner';
import CardBody from '../../../../components/common/cards/card-body';

function ModulesComp() {
  const decisionModel: IDecisionModels = useAppSelector(
    (state) => state.decisionModel
  );

  return (
    <Card className="modules-container">
      <CardBody>
        <div className="decision-card-header">
          <p className="decision-card-title">Modules</p>
        </div>

        <div className="module-list-container">
          <div className="module-list">
            {decisionModel.configModelStatus === 'loading' ||
            decisionModel.focusModelStatus === 'loading' ||
            decisionModel.focusModelStatus === 'updating' ||
            decisionModel?.configModel?.length === 0 ? (
              <Spinner />
            ) : (
              decisionModel?.configModel?.map((module) => {
                return (
                  <div
                    key={module.id}
                    // style={!decisionModel?.focusModel?.decision_setting?.[module.name] ? disabledDiv : {}}
                  >
                    <ModuleCard
                      key={module.id}
                      module={module}
                      required={
                        decisionModel?.focusModel?.decision_setting?.[
                          module.name
                        ]?.required
                          ? true
                          : false
                      }
                    />
                  </div>
                );
              })
            )}
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

export default ModulesComp;
