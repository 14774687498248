import React from 'react';
import '../_common-input-styles.scss';
import './_select.scss';
import ReactSelect, { components, MultiValueRemoveProps } from 'react-select';
import { ReactComponent as MultiValueRemoveIcon } from '../../../assets/icons/cancel.svg';
import { ReactComponent as DropdownIcon } from '../../../assets/icons/arrowhead-down.svg';
import Checkbox from '../checkbox/checkbox';
import Tooltip from '../../common/tooltip/tooltip';

export interface SelectProps {
  icon?: React.ReactElement;
  selectOptions: Array<any>;
  selectPlaceholder?: string | number;
  label?: string;
  isMulti?: boolean;
  canSelectAll?: boolean;
  maxMenuHeight?: number;
  menuPlacement?: 'top' | 'bottom';
  onChange: (val: any) => void;
  [rest: string]: any;
}

const selectAllOption = { label: 'Select All', value: '*' };

const MultiValueRemove = (props: MultiValueRemoveProps) => {
  return (
    <components.MultiValueRemove {...props}>
      <span className="multivalue-remove-icon">
        <MultiValueRemoveIcon />
      </span>
    </components.MultiValueRemove>
  );
};

const Option = (props: any) => {
  const selected =
    props?.selectProps?.value?.find?.((selected: any) => {
      return selected?.value === props.value;
    })?.value || false;

  return (
    <div>
      <components.Option {...props}>
        <div className="option">
          <label className="option-label">
            {props.label}{' '}
            {props?.data?.description && (
              <Tooltip content={props?.data?.description} />
            )}
          </label>{' '}
          {props.isMulti &&
            props.value !== selectAllOption.value &&
            (selected ? (
              <Checkbox
                name="checkbox"
                variant="no-fill"
                containerClassName="select-checkbox"
                id={props.data.value}
                value={props.data.value}
                defaultChecked={props.isSelected}
                checked={props.isSelected}
                onChange={() => null}
              />
            ) : (
              <Checkbox
                name="checkbox"
                variant="no-fill"
                containerClassName="select-checkbox"
                id={props.data.value}
                value={false}
                defaultChecked={false}
                checked={false}
                onChange={() => null}
              />
            ))}
        </div>
      </components.Option>
    </div>
  );
};

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <DropdownIcon />
    </components.DropdownIndicator>
  );
};

const Select: React.FC<SelectProps> = (props) => {
  const {
    selectOptions,
    label,
    menuPlacement,
    isMulti,
    maxMenuHeight,
    canSelectAll = false,
    onChange,
    ...rest
  } = props;

  let mutatedSelectOptions = [...selectOptions];
  if (canSelectAll) {
    mutatedSelectOptions = [selectAllOption, ...mutatedSelectOptions];
  }

  return (
    <div className="input-container select-container">
      {label && (
        <label
          className={`input-label ${
            (isMulti && props.value?.length > 0) || props.value?.value
              ? 'active'
              : ''
          }`}
        >
          {label}
        </label>
      )}

      <div className="input-field-container">
        <ReactSelect
          className="react-select_container"
          classNamePrefix="react-select"
          components={{ MultiValueRemove, Option, DropdownIndicator }}
          options={mutatedSelectOptions}
          placeholder={null}
          closeMenuOnSelect={!isMulti}
          blurInputOnSelect={!isMulti}
          isMulti={isMulti}
          hideSelectedOptions={false}
          menuPlacement={menuPlacement ? 'top' : 'auto'}
          onBlur={(event) => event.preventDefault()}
          maxMenuHeight={maxMenuHeight ?? 100}
          onChange={(e) => {
            if (isMulti && Array.isArray(e)) {
              const all = e?.find((val) => {
                return val.value === selectAllOption.value;
              });

              if (canSelectAll && all?.value) {
                onChange(selectOptions);
                return;
              }
            }

            onChange(e);
          }}
          {...rest}
        />
      </div>
    </div>
  );
};

export default Select;
