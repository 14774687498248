import React, { useEffect, useState } from 'react';
import './_configuration-field.scss';
import { useFormikContext } from 'formik';
import Select from '../../../../input-fields/Select/select';
import { convertToOptions } from '../../../../../utils/form';
import { useSearchParams } from 'react-router-dom';

function DecisionSelectField(props: any) {
  const { setFieldValue } = useFormikContext();
  const { id, value, options } = props;

  const [searchParams] = useSearchParams();

  const [data, setData] = useState('');

  useEffect(() => {
    setData(value);
  }, [value]);

  const handleChange = (e: any) => {
    const { value } = e;
    setData(value);

    setFieldValue(
      `decision_setting.${searchParams?.get('module-id')}.${props.id}`,
      value
    );
  };

  return (
    <div className="decision-setting-field">
      <div className="decision-setting-title">{id}</div>
      <div className="decision-setting-select">
        <Select
          name={id}
          onChange={handleChange}
          selectOptions={convertToOptions(options)}
          value={convertToOptions([data])?.[0]}
        />
      </div>
    </div>
  );
}

export default DecisionSelectField;
