import React, { useMemo, useState } from 'react';
import './_input.scss';
import '../_common-input-styles.scss';
import { ReactComponent as PasswordHidden } from '../../../assets/icons/content-hidden.svg';
import { ReactComponent as PasswordVisible } from '../../../assets/icons/content-visible.svg';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

export interface InputProps {
  label?: string;
  icon?: React.ReactNode;
  iconPosition?: string;
  iconAction?: Function;
  iconClassName?: string;
  error?: boolean | string;
  type?: string;
  success?: boolean | string;
  successMessage?: string;
  errorMessage?: string;
  inputContainerStyle?: React.CSSProperties;
  [rest: string]: any;
}

const Input: React.FC<InputProps> = (props) => {
  const {
    label,
    type = 'text',
    icon,
    iconPosition,
    iconAction,
    iconClassName,
    error,
    errorMessage,
    successMessage,
    success,
    disabled,
    inputContainerStyle,
    value,
    ...rest
  } = props;

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordType, setPasswordType] = useState(type);

  useMemo(() => {
    setPasswordType(type);
  }, [type]);

  return (
    <div
      style={{
        ...inputContainerStyle,
        minHeight: type === 'date' ? '2.75rem' : '',
      }}
      className="input-container"
    >
      <div
        className={`input-field-container ${
          (icon || type === 'password') &&
          (iconPosition === 'left' ? 'icon-left' : 'icon-right')
        } ${error && 'input-error'} ${success && 'input-success'}`}
      >
        {label && (
          <label
            className={`input-label ${
              value?.length > 0 ||
              rest?.defaultValue?.length > 0 ||
              type === 'phone'
                ? 'active'
                : ''
            } ${
              type === 'file' && (value || rest?.defaultValue) ? 'active' : ''
            }`}
          >
            {label}
          </label>
        )}

        {type !== 'phone' ? (
          <input
            type={passwordType || 'text'}
            style={{
              opacity: `${disabled ? '0.7' : '1'}`,
              cursor: `${disabled ? 'not-allowed' : 'text'}`,
              color: `${type === 'date' && value && 'currentColor'}`,
            }}
            disabled={disabled}
            value={value}
            {...rest}
          />
        ) : (
          <PhoneInput
            inputProps={{
              name: rest.name,
              required: true,
            }}
            country={'ng'}
            containerClass="phoneContainer"
            inputClass={`phoneInput`}
            disabled={disabled}
            value={props.value}
            onChange={props.onChange}
            enableAreaCodes={false}
            onlyCountries={['ng', 'rw', 'ke', 'us', 'uk']}
            countryCodeEditable={false}
            masks={{ ng: '... ... ....' }}
          />
        )}

        {type === 'password' && !icon ? (
          <span
            className="icon password-icon"
            onClick={() => {
              if (type === 'password') {
                if (passwordVisible) {
                  setPasswordVisible(false);
                  setPasswordType('password');
                } else {
                  setPasswordVisible(true);
                  setPasswordType('text');
                }
              }
            }}
          >
            {passwordVisible ? <PasswordVisible /> : <PasswordHidden />}
          </span>
        ) : (
          <span
            className={`icon ${iconClassName ?? ''}`}
            onClick={() => {
              iconAction?.();
            }}
          >
            {icon}
          </span>
        )}
      </div>

      {error && errorMessage && (
        <label className="error-message xs">{errorMessage}</label>
      )}
      {success && (
        <label className="success-message xs">{successMessage}</label>
      )}
    </div>
  );
};

export default Input;
