import React from 'react';
import Style from './_get-started.module.scss';
import FillButton from '../../../components/common/buttons/button';
import { Link } from 'react-router-dom';
import { ReactComponent as CheckedIcon } from '../../../assets/icons/checked-rounded.svg';
import { ReactComponent as UncheckedIcon } from '../../../assets/icons/unchecked-rounded.svg';

type Props = {
  title: string;
  description: string;
  button: { label: string; link: string };
  done: boolean;
};

function TodoTask({ title, description, button, done }: Props) {
  return (
    <div className={Style.todo_container}>
      <div className={Style.todo_task}>
        <div>{done ? <CheckedIcon /> : <UncheckedIcon />}</div>

        <div>
          <p className="font-600 text-text-primary sm">{title}</p>
          <p style={{ marginTop: '0.125rem' }} className="sm">
            {description}
          </p>
        </div>
      </div>

      {!done && (
        <div>
          <Link to={button.link}>
            <FillButton
              className={Style.todo_task_button}
              label={button.label}
            />
          </Link>
        </div>
      )}
    </div>
  );
}

export default TodoTask;
