import React, { useState, useEffect, useCallback } from 'react';
import './_wallet.scss';

//components
import Card from '../../../components/common/cards/cards';
import Table from '../../../components/table/table';
import TableRow from '../../../components/table/tablebody';
import Chip from '../../../components/common/chip/chip';

//Icons

import MobileTable from '../../../components/table/mobile-table/mobile-table';
import MobileTableRow from '../../../components/table/mobile-table/mobile-tablebody';
import { currencyFormat, fullDateFormat } from '../../../utils/formatter';
import { useAppDispatch, useAppSelector } from '../../../store/customHooks';
import {
  getAllTransactionsAction,
  getWalletInfoAction,
} from '../../../store/wallets/wallet.actions';
import {
  ITransactionsType,
  IWalletState,
} from '../../../store/wallets/wallet.interface';
import PageLoading from '../../../components/page-loading/pageLoading';
import Pagination from '../../../components/common/pagination/Pagination';
import EmptyTable from '../../../components/empty-table/EmptyTable';
import PageTitle from '../../../components/common/page-title';
import BillingAccountCard from './billing-account-card';
import WalletBalanceCard from './wallet-balance-card';
import CardFooter from '../../../components/common/cards/card-footer';
import useFilterHook from '../../../hooks/useFilterHook';
import useScreenSizeHook from '../../../hooks/useScreenSizeHook';
import TransactionFilterModal from './transaction-filter';

function WalletPage() {
  const dispatch = useAppDispatch();
  const TransactionHistoryData = useAppSelector(
    (state) => state.wallet.allTransactions
  );
  const walletInfo: IWalletState = useAppSelector((state) => state.wallet);

  const { isDesktop } = useScreenSizeHook();

  useEffect(() => {
    dispatch(getWalletInfoAction());
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const initialFilter = {
    page: currentPage,
    limit: perPage,
  };

  const { filter, handleFilter, setFilter, resetFilter } = useFilterHook({
    initialFilter,
  });

  const fetchTransations = useCallback(() => {
    dispatch(
      getAllTransactionsAction({
        ...filter,
        page: currentPage,
        limit: perPage,
      })
    );
  }, [perPage, currentPage]);

  const loadPage = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  return (
    <div className="wallet-page">
      <PageTitle
        title={`Wallet`}
        description="Manage your wallet and monitor all your transactions."
      />

      {walletInfo.isLoading &&
      walletInfo.isTransactionsLoading &&
      !walletInfo.allTransactions.length ? (
        <PageLoading loadingMessage="Getting Your Wallet informarion" />
      ) : (
        <>
          <div className="analytics-grid">
            <WalletBalanceCard />

            <BillingAccountCard />
          </div>

          <Card className="recent-transactions-table">
            {isDesktop ? (
              <Table
                title="Transactions"
                tableHeader={[
                  'Date',
                  'Transaction ID',
                  'Narration',
                  'Status',
                  'Type',
                  'Amount (NGN)',
                ]}
                filter={
                  <TransactionFilterModal
                    filter={filter}
                    setFilter={setFilter}
                    initialFilter={initialFilter}
                    handleFilter={handleFilter}
                  />
                }
                handleFilterReset={() => {
                  setFilter(initialFilter);
                  resetFilter();
                  dispatch(getAllTransactionsAction(initialFilter));
                }}
                handleFilterSubmit={() => {
                  dispatch(getAllTransactionsAction(filter));
                }}
                isLoading={
                  walletInfo.isTransactionsLoading &&
                  TransactionHistoryData.length === 0
                }
                isEmpty={TransactionHistoryData.length === 0}
                emptyStateComponent={
                  <EmptyTable
                    message="No data to show yet"
                    additional_message="Your transaction history will appear here"
                  />
                }
              >
                {TransactionHistoryData.map(
                  (transaction: ITransactionsType) => {
                    return (
                      <TableRow key={transaction.id}>
                        <td>{fullDateFormat(transaction.created_on)}</td>

                        <td className="request-id-field">
                          {transaction.reference}
                        </td>

                        <td className="request-id-field">
                          {transaction.narration}
                        </td>

                        <td>
                          <Chip
                            label={transaction.status}
                            className="status-chip"
                            data-status={transaction.status.toLowerCase()}
                          />
                        </td>

                        <td>{transaction.transaction_type}</td>

                        <td>
                          {currencyFormat(
                            Number(transaction.amount),
                            transaction.currency,
                            'table'
                          )}
                        </td>
                      </TableRow>
                    );
                  }
                )}
              </Table>
            ) : (
              <MobileTable
                className="recent-transactions-table"
                title="Transaction History"
                filter={
                  <TransactionFilterModal
                    filter={filter}
                    setFilter={setFilter}
                    initialFilter={initialFilter}
                    handleFilter={handleFilter}
                  />
                }
                isLoading={
                  walletInfo.isTransactionsLoading &&
                  TransactionHistoryData.length === 0
                }
                isEmpty={TransactionHistoryData.length === 0}
                emptyStateComponent={
                  <EmptyTable
                    message="No Transactions yet"
                    additional_message="Your transaction history will appear here"
                  />
                }
              >
                {TransactionHistoryData.map(
                  (transaction: ITransactionsType) => {
                    return (
                      <MobileTableRow key={transaction.id}>
                        <div className="field-container">
                          <span className="field-title">Date:</span>
                          {fullDateFormat(transaction.created_on)}
                        </div>

                        <div className="field-container">
                          <span className="field-title">Transaction ID:</span>
                          {transaction.reference}
                        </div>

                        <div className="field-container">
                          <span className="field-title">Narration:</span>
                          {transaction.narration}
                        </div>

                        <div className="field-container">
                          <span className="field-title">Status:</span>
                          <Chip
                            label={transaction.status}
                            className="status-chip"
                            data-status={transaction.status.toLowerCase()}
                          />
                        </div>

                        <div className="field-container">
                          <span className="field-title">Type:</span>
                          {transaction.transaction_type}
                        </div>

                        <div className="field-container">
                          <span className="field-title">Amount:</span>
                          {currencyFormat(
                            Number(transaction.amount),
                            transaction.currency
                          )}
                        </div>
                      </MobileTableRow>
                    );
                  }
                )}
              </MobileTable>
            )}

            <CardFooter
              style={{
                display:
                  walletInfo.totalTransationSize === 0 ? 'none' : 'block',
              }}
            >
              <Pagination
                total={walletInfo.totalTransationSize}
                perPage={perPage}
                setPerPage={setPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                loadPage={loadPage}
                perPageTitle="Transactions"
                updateDataCallback={fetchTransations}
              />
            </CardFooter>
          </Card>
        </>
      )}
    </div>
  );
}

export default WalletPage;
