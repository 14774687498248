import React, { CSSProperties } from 'react';
import FillButton, { ButtonColor } from './button';
import './_button.scss';
import { ButtonProps } from './button.interface';

interface Props extends ButtonProps {
  icon: React.ReactElement;
  style?: CSSProperties;
  className?: string;
}

function IconButton(props: Props) {
  return (
    <FillButton
      {...props}
      colorVariant={ButtonColor.neutral}
      className={`${props.className ?? ''} icon-button-container`}
    >
      {props.icon}
    </FillButton>
  );
}

export default IconButton;
