import React from 'react';
import ConfigurationIntegerField from './fields/configuration-integer-field';
import ConfigurationCheckField from './fields/configuration-check-field';

export enum DECISION_FIELD_TYPES {
  integer = 'integer',
  float = 'float',
  boolean = 'boolean',
}

export interface IDecisionIntegerFieldProps {
  type: DECISION_FIELD_TYPES.integer | DECISION_FIELD_TYPES.float;
  id: string;
  label: string;
  description: string;
  value: any;
  minimum: number;
  maximum: number;
}

export interface IDecisionCheckFieldProps {
  id: string;
  check: boolean;
}

export interface IRenderModelConfigOptions {
  field: any;
  name: any;
}

export function RenderModelConfigOptions(props: IRenderModelConfigOptions) {
  const { field, name } = props;

  if (typeof field?.value === DECISION_FIELD_TYPES.boolean) {
    return <ConfigurationCheckField id={name} check={field.value} />;
  } else {
    switch (field.type) {
      case DECISION_FIELD_TYPES.integer:
      case DECISION_FIELD_TYPES.float: {
        return (
          <ConfigurationIntegerField
            id={name}
            label={name}
            description={field.description}
            value={field.value}
            minimum={field.minimum}
            maximum={field.maximum}
            type={field.type}
          />
        );
      }

      default:
        return <></>;
    }
  }
}
