import { IAuthState } from './auth.interface';
import { createSlice } from '@reduxjs/toolkit';
import {
  loginUserAction,
  resetPasswordAction,
  sendResetPasswordMailAction,
} from './auth.actions';
import { createUserAccountAction } from '../onboarding/onboarding.actions';
import { eventTaggingAction } from '../utility/utility.actions';
import { switchOrganizationAction } from '../account/account.actions';

const initialState = {
  isAuthenticated: false,
  user: {},
  isLoading: false,
  error: null,
  success: false,
  sessionId: '',
  access_token: '',
  userId: null,
  org_id: null,
  organizations: [],
  activated: 0,
  showTwoFA: false,
  loginKey: '',
  is_twofa_enabled: false,
} as IAuthState;

const AuthSlice = createSlice({
  name: 'AuthenticationState',
  initialState,

  reducers: {
    resetLogin: (state) => {
      state.error = null;
      state.success = false;
    },

    toggleTwoFAEnabled: (state) => {
      state.is_twofa_enabled = !state.is_twofa_enabled;
    },
    resetAuth: (state) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state = initialState; // eslint-disable-line unused-imports/no-unused-vars
    },
  },

  extraReducers: (builder) => {
    builder.addCase(loginUserAction.pending, (state: IAuthState) => {
      state.isLoading = true;
    });
    builder.addCase(
      loginUserAction.fulfilled,
      (state: IAuthState, action: any) => {
        if (action.payload.twofa === true) {
          state.showTwoFA = true;
          state.loginKey = action.payload.loginKey;
          state.isLoading = false;
          return;
        }
        state.isAuthenticated = true;
        state.userId = action.payload.data.id;
        state.activated = action.payload.data.activated;
        state.access_token = action.payload.data.token;
        state.org_id = action.payload.org_id;
        state.sessionId = action.payload.data.session_id;
        if (action.payload.data.is_twofa_enabled) {
          state.is_twofa_enabled = action.payload.data.is_twofa_enabled;
        }
        state.isLoading = false;
        eventTaggingAction({
          event_name: 'Sign in',
          description: `User ${action.payload.data.id} successfully signed in on ${action.payload.org_id}`,
        });
      }
    );
    builder.addCase(
      loginUserAction.rejected,
      (state: IAuthState, action: any) => {
        state.error = action.payload;
        state.isLoading = false;
      }
    );

    // Create Account
    builder.addCase(createUserAccountAction.pending, (state: any) => {
      state.isLoading = true;
    });
    builder.addCase(createUserAccountAction.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.user.email = payload.email;
    });
    builder.addCase(
      createUserAccountAction.rejected,
      (state: any, action: any) => {
        state.error = action.payload;
        state.isLoading = false;
      }
    );

    //Send Reset Password mail
    builder.addCase(sendResetPasswordMailAction.pending, (state: any) => {
      state.isLoading = true;
    });
    builder.addCase(sendResetPasswordMailAction.fulfilled, (state: any) => {
      state.success = true;
      state.error = null;
      state.isLoading = false;
    });
    builder.addCase(
      sendResetPasswordMailAction.rejected,
      (state: any, action) => {
        state.error = action.payload;
        state.isLoading = false;
      }
    );

    //Reset Password
    builder.addCase(resetPasswordAction.pending, (state: any) => {
      state.isLoading = true;
    });
    builder.addCase(resetPasswordAction.fulfilled, (state: any) => {
      state.success = true;
      state.error = null;
      state.isLoading = false;
    });
    builder.addCase(resetPasswordAction.rejected, (state: any, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });

    //SWITCH ORGANIZATION
    builder.addCase(
      switchOrganizationAction.fulfilled,
      (state: IAuthState, action: any) => {
        state.isAuthenticated = true;
        state.userId = action.payload.data.id;
        state.activated = action.payload.data.activated;
        state.access_token = action.payload.data.token;
        state.org_id = action.payload.data.org_id;
        state.sessionId = action.payload.data.session_id;
        if (action.payload.data.is_twofa_enabled) {
          state.is_twofa_enabled = action.payload.data.is_twofa_enabled;
        }
        state.isLoading = false;
        eventTaggingAction({
          event_name: 'Sign in',
          description: `User ${action.payload.data.id} successfully signed in on ${action.payload.org_id}`,
        });
      }
    );
  },
});

export const { resetLogin, toggleTwoFAEnabled, resetAuth } = AuthSlice.actions;

export default AuthSlice.reducer;
