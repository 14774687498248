import React, { useLayoutEffect, useEffect, useState } from "react";
import "./_in-app.scss";
import { useAppSelector } from "../../store/customHooks";
import Sidebar from "./sidebar/sidebar";
import Topbar from "./topbar/topbar";
import { scrollToTop } from "../../utils/helper";
import { useLocation, useNavigate } from "react-router-dom";
import urls from "../../routes/all-routes";
import SessionTimeoutModal from "./sessionTimeout/sessionTimeout";
import { logoutAction } from "../../store/auth/auth.actions";
import { BreadcrumbsProps } from "../../components/common/breadcrumb";

export interface InAppLayoutProps {
  children: React.ReactNode;
  breadcrumb: BreadcrumbsProps['breadcrumb']
}

export default function InAppLayout(props: Readonly<InAppLayoutProps>) {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAppSelector((state) => state.auth);
  const inactivityLimit: any = process.env.REACT_APP_SESSION_TIMEOUT;

  useLayoutEffect(() => {
    scrollToTop();
  }, [location.pathname]);

  useEffect(() => {
    if (!(auth.isAuthenticated && auth.access_token)) {
      return navigate(urls.login.index);
    }
  }, [location.pathname]);

  const sessionTimeout = Number(inactivityLimit) * 60 * 1000; // minutes in milliseconds
  const [lastActivity, setLastActivity] = useState(Date.now());
  const [showTimeoutModal, setShowTimeoutModal] = useState(false);
  const [sessionTimeLeft, setSessionTimeLeft] = useState(60);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = Date.now();
      if (Number(now - lastActivity) > sessionTimeout) {
        sessionStorage.setItem("adjutor:lastLocation", location.pathname);
        clearInterval(intervalId);
       logoutAction();
      } else if (now - lastActivity >= sessionTimeout - 60000) {
        setSessionTimeLeft(sessionTimeLeft - 1);
        if (!showTimeoutModal) {
          setShowTimeoutModal(true);
        }
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [location, lastActivity, sessionTimeout, sessionTimeLeft]);

  function handleActivity() {
    setLastActivity(Date.now());
    setSessionTimeLeft(60);
  }

  return (
    <div className="in-app-layout" onClick={handleActivity}>
      <Sidebar />
      <Topbar breadcrumb={props.breadcrumb} />

      <div className="main-content" id="hide-flow-tag-1">{props.children}</div>

      <SessionTimeoutModal
        showTimeoutModal={showTimeoutModal}
        setShowTimeoutModal={() => {
          setShowTimeoutModal(false);
        }}
        remainingTime={`${sessionTimeLeft} Seconds`}
      />
    </div>
  );
}
