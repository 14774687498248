import { FormikProps, useFormikContext } from 'formik';
import React, { useState, useMemo } from 'react';
import { IDecisionCheckFieldProps } from '../../render-model-config-options';
import Toggle from '../../../../input-fields/toggle/toggle';
import { useSearchParams } from 'react-router-dom';
import { ISetting } from '../../../../../store/decision-model/decision-model.interface';

function DecisionCheckField(props: IDecisionCheckFieldProps) {
  const { setFieldValue, values }: FormikProps<ISetting['setting']> =
    useFormikContext();
  const { id, check } = props;

  const [searchParams] = useSearchParams();

  const [data, setData] = useState(check);

  useMemo(() => {
    setData(check);
  }, [check]);

  const handleChange = () => {
    setData(!data);

    setFieldValue(
      `decision_setting.${searchParams?.get('module-id')}.${id}`,
      !check
    );

    //This is used to add the sequence if required is checked
    if (
      !values?.decision_setting[
        searchParams?.get('module-id') as string
      ]?.hasOwnProperty('sequence')
    ) {
      setFieldValue(
        `decision_setting.${searchParams?.get('module-id')}.sequence`,
        Object.keys(values?.decision_setting || {})?.length + 1
      );
    }
  };

  return (
    <div className="decision-setting-field">
      <div className="decision-setting-title">{id?.replace(/_/g, ' ')}</div>
      <div className="decision-setting-toggle">
        <Toggle name={id} value={id} checked={data} onChange={handleChange} />{' '}
        <div>{data ? 'True' : 'False'}</div>
      </div>
    </div>
  );
}

export default DecisionCheckField;
