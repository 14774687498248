import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AuthLayout from '../../../layout/authLayout/authLayout';
import './_forgot-password.scss';
import { useAppDispatch, useAppSelector } from '../../../store/customHooks';
import { resetLogin } from '../../../store/auth/auth.reducer';
import { sendResetPasswordMailAction } from '../../../store/auth/auth.actions';

//components
import { useFormik } from 'formik';
import { ForgetPasswordSchema } from './forget-password-validation-schema';
import FillButton, {
  ButtonColor,
} from '../../../components/common/buttons/button';

//images
import { ReactComponent as NewMessageIcon } from '../../../assets/icons/new-message.svg';
import { IAuthState } from '../../../store/auth/auth.interface';
import urls from '../../../routes/all-routes';

export default function ForgotPasswordPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const resetState: IAuthState = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (location.state?.email) {
      return;
    } else {
      navigate(urls.forgotPassword.index);
    }
  }, [location.state]);

  const formik = useFormik({
    initialValues: { email: location.state?.email ?? '' },

    validationSchema: ForgetPasswordSchema,

    onSubmit: (values: { email: string }) => {
      dispatch(sendResetPasswordMailAction(values));
    },
  });

  useEffect(() => {
    dispatch(resetLogin());
  }, []);

  return (
    <AuthLayout>
      <div className="forgot-password-page">
        <div>
          <div className="auth-page-heading-icon">
            <NewMessageIcon />
          </div>

          <h6>Check your inbox</h6>

          <p className="sm">
            We’ve sent an email to your registered email address containing
            instructions on what to do next.
          </p>
        </div>

        <form onSubmit={formik.handleSubmit}>
          <FillButton
            type="submit"
            isLoading={resetState.isLoading}
            disabled={!formik.isValid}
            variant="outlined"
            label="Resend email"
            className="resend-email-btn"
            colorVariant={ButtonColor.neutral}
          />
        </form>

        <div className="sign-up">
          <Link to={urls.login.index}>
            <FillButton
              variant="ghost"
              label="Oh! I remember my password now"
            />
          </Link>
        </div>
      </div>
    </AuthLayout>
  );
}
