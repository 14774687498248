import React, { useState, useMemo, useEffect } from 'react';
import './_onboarding.scss';
import AuthLayout from '../../../layout/authLayout/authLayout';
import StepWizard from 'react-step-wizard';
import { useAppDispatch, useAppSelector } from '../../../store/customHooks';
import {
  getAllBankAction,
  getAllStates,
} from '../../../store/utility/utility.actions';

//components
import CompletedSetup from './onboarding-success';

//images
import { scrollToTop } from '../../../utils/helper';
import { gotoStep } from '../../../store/onboarding/onboarding.reducer';
import { getOnboardingDetailsActions } from '../../../store/onboarding/onboarding.actions';
import { useNavigate, useSearchParams } from 'react-router-dom';
import urls from '../../../routes/all-routes';
import { ICompleteRegisteration } from '../../../store/onboarding/onboarding.interface';
import PageLoading from '../../../components/page-loading/pageLoading';

export default function CompleteSetupPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { currentStep, isOnboadingDetailsStatus }: ICompleteRegisteration =
    useAppSelector((state) => state.onboarding);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams?.get('token')) {
      dispatch(getOnboardingDetailsActions(searchParams.get('token')));
    } else {
      navigate(urls.login.index);
    }
  }, [searchParams]);

  useMemo(() => {
    if (isOnboadingDetailsStatus === 'error') {
      navigate(urls.login.index);
    }
  }, [isOnboadingDetailsStatus]);

  useEffect(() => {
    dispatch(getAllStates());
    dispatch(getAllBankAction());
    dispatch(gotoStep(1));
  }, []);

  const [instance, updateInstance] = useState({
    goToStep: (_step: number) => {},
  });
  const setInstance = (SW: any) => updateInstance(SW);

  useMemo(() => {
    instance?.goToStep(currentStep);
    scrollToTop();
  }, [currentStep]);

  const saveStepStatus = (_stepNo: number, props: any) => {
    props.nextStep();
  };

  return (
    <AuthLayout banner_type="listed" long_child={currentStep < 3}>
      <div className="complete-setup-page">
        {isOnboadingDetailsStatus === 'loading' ? (
          <PageLoading loadingMessage="Getting Ready" />
        ) : (
          <StepWizard
            isLazyMount={true}
            initialStep={currentStep}
            instance={setInstance}
          >
            {/* <CompleteBusinessProfile stepNo={0} /> */}

            {/* <CompleteBusinessAddress
              stepNo={1}
              skipStep={skipStep}
              prevStep={prevStep}
            /> */}

            {/* <CompleteBusinessDocuments
              stepNo={2}
              skipStep={skipStep}
              prevStep={prevStep}
            /> */}

            {/* <CompleteBankInfo stepNo={2} prevStep={prevStep} /> */}

            <CompletedSetup saveStepStatus={saveStepStatus} stepNo={0} />
          </StepWizard>
        )}
      </div>
    </AuthLayout>
  );
}
