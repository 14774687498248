import React from "react";
import Card from "../../../../components/common/cards/cards";

function WorkspaceCard(props: any) {
  const { workspace } = props;

  let newArr: { key: string; arr: any }[] = [];

  Object.keys(workspace?.arr).forEach((key) =>
    newArr.push({
      key: key,
      arr: workspace?.arr[key],
    })
  );

  return (
    <Card className="workspace-card">
      <p className="card-title">{workspace?.key?.replace(/_/g, ' ')}</p>

      {newArr
        .filter((item) => item.key !== "sequence" && item.key !== "writable")
        .map((item) => {
          if (typeof item.arr === "number" || typeof item.arr === "string") {
            return (
              <p className="provider" key={item.key}>
                {item.key}: <span>{item.arr}</span>
              </p>
            );
          } else if (typeof item.arr === "boolean") {
            return (
              <p className="boolean-value" key={item.key}>
                {item.key}:{" "}
                <span data-boolean-value={item.arr}>
                  {item.arr ? "True" : "False"}
                </span>
              </p>
            );
          }
        })}
    </Card>
  );
}

export default WorkspaceCard;
