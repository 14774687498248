import React, { Dispatch, SetStateAction, useMemo } from 'react';
import './_pagination.scss';
import { ReactComponent as NextIcon } from '../../../assets/icons/arrowhead-right.svg';
import Select from '../../input-fields/Select/select';
import FillButton, { ButtonColor } from '../buttons/button';
import { convertToNumOptions } from '../../../utils/form';

export interface IOptions {
  label: number;
  value: number;
}

export interface IPaginationTypes {
  total: number;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  perPage: number;
  loadPage: Function;
  setPerPage: Dispatch<SetStateAction<number>>;
  pageSize?: IOptions[];
  perPageTitle: string;
  updateDataCallback: Function;
}

export default function Pagination(props: IPaginationTypes) {
  const {
    total,
    currentPage,
    setCurrentPage,
    perPage,
    loadPage,
    setPerPage,
    pageSize = convertToNumOptions([10, 25, 50]),
    updateDataCallback,
  } = props;

  const totalPages = useMemo(() => {
    return Math.ceil(total / perPage);
  }, [total, perPage]);

  useMemo(() => {
    setCurrentPage(1);
  }, [perPage]);

  useMemo(() => {
    updateDataCallback();
  }, [perPage, currentPage]);

  return (
    <React.Fragment>
      <div className="pagination-box">
        <div className="showing-box sm">
          Showing {currentPage} of {totalPages} entries
        </div>

        <div className="pagination-nav-container">
          <div className="sm">
            Rows per page{' '}
            <div className="per-page">
              <Select
                onChange={(val: IOptions) => {
                  setPerPage(val.value);
                }}
                selectOptions={pageSize}
                defaultOption={perPage}
                value={convertToNumOptions([perPage])}
                selectPlaceholder={perPage}
                isSearchable={false}
                menuPlacement="top"
              />
            </div>
          </div>

          <nav>
            <ul className="pagination">
              <li className="page-item prev-btn">
                <FillButton
                  disabled={currentPage < 2}
                  onClick={() => loadPage(Math.max(1, currentPage - 1))}
                  className="page-link"
                  variant="outlined"
                  colorVariant={ButtonColor.neutral}
                >
                  <span className="icon">
                    <NextIcon />
                  </span>
                </FillButton>
              </li>

              <li className="page-item active">
                <button className="page-link font-500">{currentPage}</button>
              </li>

              <li className="page-item next-btn">
                <FillButton
                  disabled={currentPage === totalPages}
                  onClick={() => {
                    loadPage(Math.min(totalPages, currentPage + 1));
                  }}
                  className="page-link"
                  // href="#1"
                >
                  <span className="icon">
                    <NextIcon />
                  </span>
                </FillButton>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </React.Fragment>
  );
}
