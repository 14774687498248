import React, { useEffect, useState } from 'react';
import MfaInput from '../mfa-input/mfa-input';
import Modal, { ModalPosition } from '../common/modal/modal';
import NoticeModal from './notice';
import FillButton, { ButtonColor } from '../common/buttons/button';
import ModalFooter from '../common/modal/modal-footer';
import {
  initializeToggletwofaAction,
  toggletwofaAction,
} from '../../store/settings/settings.actions';
import { useAppDispatch, useAppSelector } from '../../store/customHooks';

type Props = {
  showTwoFaModal: boolean;
  toggleShowTwoFaModal: () => void;
};

function ToggleTwo2FAModal({ toggleShowTwoFaModal, showTwoFaModal }: Props) {
  const [MFAValue, setMFAValue] = useState('');
  const dispatch = useAppDispatch();
  const authState = useAppSelector((state) => state.auth);
  const settingState = useAppSelector((state) => state.settings);
  const [activeScreen, setActiveScreen] = useState<
    'warning' | 'code' | 'qr' | ''
  >('');

  const handleClose = () => {
    setActiveScreen('');
    toggleShowTwoFaModal();
  };

  useEffect(() => {
    if (showTwoFaModal) {
      setActiveScreen('warning');
    }
  }, [showTwoFaModal]);

  return (
    <>
      <NoticeModal
        title="Two-Factor-Authentication"
        message={`You are About to ${
          authState.is_twofa_enabled ? 'Deactivate' : 'Activate'
        } Two-Factor-Authentication?`}
        showNoticeModal={activeScreen === 'warning'}
        setShowNoticeModal={() => {
          handleClose();
        }}
        actionButtons={
          <ModalFooter>
            <FillButton
              onClick={() => {
                handleClose();
              }}
              colorVariant={ButtonColor.neutral}
              className="close-button"
              variant="outlined"
              label="Cancel"
            />

            <FillButton
              onClick={() => {
                if (authState.is_twofa_enabled) {
                  setActiveScreen('code');
                  return;
                }
                dispatch(initializeToggletwofaAction())
                  .unwrap()
                  .then(() => {
                    setActiveScreen('qr');
                  });
              }}
              isLoading={settingState.toggleTwoFAState === 'initialize.loading'}
              colorVariant={
                authState.is_twofa_enabled
                  ? ButtonColor.danger
                  : ButtonColor.primary
              }
              label={`Yes, ${
                authState.is_twofa_enabled ? `Deactivate` : `Activate`
              }`}
            />
          </ModalFooter>
        }
      />

      <Modal
        isOpen={activeScreen === 'qr'}
        title="Scan QR Code"
        onClose={() => {
          handleClose();
        }}
        className="activate-2fa-modal"
        modalPosition={ModalPosition.center}
        animated={false}
        modalFooter={
          <ModalFooter>
            <FillButton
              onClick={() => {
                handleClose();
              }}
              colorVariant={ButtonColor.neutral}
              variant="outlined"
              className="close-button"
              label="Cancel"
            />

            <FillButton
              onClick={() => {
                setActiveScreen('code');
              }}
              colorVariant={ButtonColor.primary}
              label="Proceed"
            />
          </ModalFooter>
        }
      >
        <div>
          <p>Scan the code to activate Two Factor Authentication</p>

          <div className="twofa-qrcode-container">
            <img
              src={settingState?.twofaqrcode}
              alt=""
              className="twofa-qrcode"
            />
          </div>
        </div>
      </Modal>

      <MfaInput
        showMFA={activeScreen === 'code'}
        closeMFA={() => {
          handleClose();
        }}
        value={MFAValue}
        isLoading={settingState.toggleTwoFAState === 'loading'}
        onChange={(value) => {
          setMFAValue(value.toString());
        }}
        onComplete={(value) => {
          const payload = {
            token: value,
            type: authState.is_twofa_enabled ? 'deactivate' : 'activate',
          };
          dispatch(toggletwofaAction(payload))
            .unwrap()
            .then(() => {
              handleClose();
            })
            .finally(() => {
              setMFAValue('');
            });
        }}
        canUseRecovery={false}
      />
    </>
  );
}

export default ToggleTwo2FAModal;
