export const convertToOptions = (data: Array<string | number>) => {
  return data.map((item) => {
    return {
      value: item,
      label: item,
    };
  });
};

export const convertToNumOptions = (data: Array<number>) => {
  return data.map((item) => {
    return {
      value: item,
      label: item,
    };
  });
};

export const convertToUtilityOptions = (
  data: Array<{ code_description: string; ref_code: string }>
) => {
  return data.map((item) => {
    return {
      value: item.ref_code,
      label: item.code_description,
    };
  });
};

export const convertToUtilityOptions_Additional_Code = (
  data: Array<{ code_description: string; additional_code: string }>
) => {
  return data.map((item) => {
    return {
      value: item.additional_code,
      label: item.code_description,
    };
  });
};

export const getOptionsByKey = (allOptions: Array<any>, key: string) => {
  const option: any = allOptions.find((o: any) => o.key === key);
  if (option) {
    return option?.options;
  } else {
    return [];
  }
};
