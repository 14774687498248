import React, { useState } from 'react';
import SlidingPane from '../../../components/slidingpane/slidingpane';
import { useAppDispatch, useAppSelector } from '../../../store/customHooks';
import Chip from '../../../components/common/chip/chip';
import './_switchstore.scss';
import { ReactComponent as CheckmarkIcon } from '../../../assets/icons/checkmark.svg';
import { switchOrganizationAction } from '../../../store/account/account.actions';
import Spinner from '../../../components/common/spinner/spinner';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

function SwitchStoresPane({ isOpen, onClose }: Props) {
  const dispatch = useAppDispatch();
  const { organizations, current_organization_profile, isLoading } =
    useAppSelector((state) => state.account);

  const [focusedOrg, setFocusedOrg] = useState(0);

  return (
    <SlidingPane
      isOpen={isOpen}
      title="My Businesses"
      onClose={() => {
        onClose();
      }}
    >
      <div className="switch-store-container">
        {organizations?.length > 0 &&
          organizations?.map?.((org) => {
            return (
              <button
                className="store-container"
                key={org.id}
                onClick={() => {
                  if (org.id === current_organization_profile.id || isLoading)
                    return;
                  setFocusedOrg(org.id);
                  dispatch(switchOrganizationAction(org.id));
                }}
              >
                <div className="store">
                  <div className="store-image">
                    <img src={org.logo_url} alt="" />
                  </div>

                  <div>
                    <p className='font-600'>{org.name}</p>
                    <div>
                      <Chip label={org.primary_email} data-status={200} />
                    </div>
                  </div>
                </div>

                {org.id === current_organization_profile.id && (
                  <div className="active-store">
                    <CheckmarkIcon />
                  </div>
                )}
                {org.id === focusedOrg && isLoading && (
                  <div className="active-store">
                    <Spinner />
                  </div>
                )}
              </button>
            );
          })}
      </div>
    </SlidingPane>
  );
}

export default SwitchStoresPane;
