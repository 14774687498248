import React from 'react';
import './_audit-trail.scss';

//compoenets

import ApiLogs from './Api-logs/api-logs';
import Events from './Events/events';
import { useLocation } from 'react-router-dom';
import urls from '../../../routes/all-routes';
import PageTitle from '../../../components/common/page-title';
import CustomTabs from '../../../components/tabls';

function AuditTrailPage() {
  const location = useLocation();
  const tabs = [
    {
      title: 'API Logs',
      content: <ApiLogs />,
      route: urls.business.audits_trails.audit.index,
    },
    {
      title: 'Events',
      content: <Events />,
      route: urls.business.audits_trails.events.index,
    },
  ];

  const selectedTab = tabs.indexOf(
    tabs.filter((tab) => {
      return tab.route.startsWith(location.pathname);
    })[0]
  );

  return (
    <div className="audit-trail-page">
      <PageTitle
        title="Audit log"
        description="Manage all the activities that happen on your system."
      />

      <CustomTabs defaultIndex={selectedTab} tabs={tabs} />
    </div>
  );
}

export default AuditTrailPage;
