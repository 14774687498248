/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from 'react';
import './_dashboard.scss';
import { useNavigate } from 'react-router-dom';

//components
import Card from '../../components/common/cards/cards';
import Table from '../../components/table/table';
import TableRow from '../../components/table/tablebody';
import Chip from '../../components/common/chip/chip';
import {
  currencyFormat,
  formatNumber,
  fullDateFormat,
  truncateString,
} from '../../utils/formatter';

//Illustration

//Icons
import MobileTable from '../../components/table/mobile-table/mobile-table';
import MobileTableRow from '../../components/table/mobile-table/mobile-tablebody';
import { useAppDispatch, useAppSelector } from '../../store/customHooks';
import {
  IAuditsLogType,
  IAuditsLogAggregate,
  IAuditState,
} from '../../store/audits/audits.interface';
import {
  getAuditsAction,
  getAuditsActionAggregate,
} from '../../store/audits/audits.actions';
import { getWalletInfoAction } from '../../store/wallets/wallet.actions';
import { IWalletState } from '../../store/wallets/wallet.interface';
import { IAccountState } from '../../store/account/account.interface';
import PageLoading from '../../components/page-loading/pageLoading';
import urls from '../../routes/all-routes';
import { requestColorMatch } from '../../utils/helper';
import { ADJUTOR_SCOPES } from '../../utils/hooks/permission-maps';
import PermissionsGate from '../../utils/hooks/permission-gate';
import EmptyTable from '../../components/empty-table/EmptyTable';
import PageTitle from '../../components/common/page-title';
import Select from '../../components/input-fields/Select/select';
import useScreenSizeHook from '../../hooks/useScreenSizeHook';
import DashboardAnalytics from '../../shared/dashboard-analytics';
import { CalculateDashboardAnalytics } from '../../hooks/useCalculateDashboardAnalytics';

function DashboardPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const account: IAccountState = useAppSelector((state) => state.account);
  const auditState: IAuditState = useAppSelector((state) => state.audit);
  const wallet: IWalletState = useAppSelector((state) => state.wallet);
  const auditsLogTotal: IAuditsLogAggregate = useAppSelector(
    (state) => state.auditsLogAggregate
  );

  const { isDesktop } = useScreenSizeHook();

  useEffect(() => {
    dispatch(getAuditsAction({ page: 0, limit: 10 }));
    dispatch(getAuditsActionAggregate({ limit: 1000 }));
    dispatch(getWalletInfoAction());
  }, []);

  const viewDetails = (id: string) => {
    navigate(urls.business.audits_trails.audit.for(`${id}`));
  };

  const dateFilterOptions = [
    { label: 'Last 24 hours', value: 0 },
    { label: 'Last 7 days', value: 7 },
    { label: 'Last 30 days', value: 30 },
  ];

  const [dataFilter, setDataFilter] = useState<(typeof dateFilterOptions)[0]>(
    dateFilterOptions[0]
  );

  const { TotalCallMade, TotalCallsWithError, AveResponseTime } =
    CalculateDashboardAnalytics(
      auditsLogTotal.auditsLogAggregate,
      dataFilter.value
    );

  return (
    <div className="dashboard_page">
      <PageTitle
        title="Dashboard"
        description={`Hello ${
          account.last_name || account.full_name
        } 😊👋, How are you doing
          today`}
        rightElement={
          <div className="filter">
            <Select
              value={dataFilter}
              selectOptions={dateFilterOptions}
              onChange={(val: any) => {
                setDataFilter(val);
              }}
            />
          </div>
        }
      />

      {auditState.auditState === 'loading' ||
      wallet.isLoading ||
      auditState.auditAnalyticsState === 'loading' ? (
        <PageLoading loadingMessage="Preparing your dashboard" />
      ) : (
        <>
          <DashboardAnalytics
            totalAveResTime={AveResponseTime}
            totalCall={TotalCallMade?.length}
            totalError={TotalCallsWithError?.length}
            dataFilter={dataFilter}
            allApiCalls={TotalCallMade}
            averageLatency={formatNumber(
              auditState.currentMonthData?.api_calls
            )}
          />

          <PermissionsGate scopes={[ADJUTOR_SCOPES.canViewAudit]}>
            <div className="recent_api_call_container">
              {isDesktop ? (
                <div className="recent_api_call">
                  <Table
                    tableHeader={[
                      'Date',
                      'Status',
                      'Method',
                      'URL',
                      'AMOUNT (NGN)',
                    ]}
                    title={'Recent API Calls'}
                    isEmpty={auditState.auditsLog?.length === 0}
                    emptyStateComponent={
                      <EmptyTable
                        message="No recent API calls"
                        additional_message="Your API calls will appear here"
                      />
                    }
                  >
                    {auditState.auditsLog.map?.((apiCall: IAuditsLogType) => {
                      return (
                        <TableRow
                          onRowClickAction={() => {
                            viewDetails(apiCall?.id);
                          }}
                          key={apiCall.request_id}
                        >
                          <td>{fullDateFormat(apiCall?.created_on)}</td>

                          <td>
                            <Chip
                              label={apiCall?.status_code}
                              data-status={requestColorMatch(
                                apiCall?.status_code
                              )}
                            />
                          </td>

                          <td>
                            <Chip
                              label={apiCall?.method}
                              data-status={requestColorMatch(apiCall?.method)}
                              transparentBackground
                            />
                          </td>

                          <td>{truncateString(apiCall?.url, 30)}</td>

                          <td>
                            {currencyFormat(apiCall.charge, 'NGN', 'table')}
                          </td>
                        </TableRow>
                      );
                    })}
                  </Table>
                </div>
              ) : (
                <Card className="table_mobile_card">
                  <MobileTable
                    title="Recent API Calls"
                    isEmpty={auditState.auditsLog?.length === 0}
                    emptyStateComponent={
                      <EmptyTable
                        message="No recent API calls"
                        additional_message="Your API calls will appear here"
                      />
                    }
                  >
                    {auditState?.auditsLog?.map?.((apiCall: IAuditsLogType) => {
                      return (
                        <MobileTableRow
                          key={apiCall.request_id}
                          onRowClickAction={() => {
                            viewDetails(apiCall?.id);
                          }}
                        >
                          <div className="field-container">
                            <span className="field-title">Date: </span>
                            {fullDateFormat(apiCall?.created_on)}
                          </div>

                          <div className="field-container">
                            <span className="field-title">Status: </span>
                            <Chip
                              label={apiCall?.status_code}
                              data-status={requestColorMatch(
                                apiCall?.status_code
                              )}
                              className="method-chip"
                            />
                          </div>

                          <div className="field-container">
                            <span className="field-title">Method: </span>
                            <Chip
                              label={apiCall?.method}
                              data-status={requestColorMatch(apiCall?.method)}
                              className="method-chip"
                              transparentBackground
                            />
                          </div>

                          <div className="field-container">
                            <span className="field-title">URL: </span>
                            {truncateString(apiCall?.url, 23)}
                          </div>

                          <div className="field-container">
                            <span className="field-title">Amount (NGN): </span>
                            {currencyFormat(apiCall.charge, 'NGN', 'table')}
                          </div>
                        </MobileTableRow>
                      );
                    })}
                  </MobileTable>
                </Card>
              )}
            </div>
          </PermissionsGate>
        </>
      )}
    </div>
  );
}

export default DashboardPage;
