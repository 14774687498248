import React, { useEffect, useMemo, useState } from 'react';
import Style from './_get-started.module.scss';
import Card from '../../../components/common/cards/cards';
import CardHeader from '../../../components/common/cards/card-header';
import ProgressBar from '../../../components/progress-bar/progress-bar';
import TodoTask from './todo';
import urls from '../../../routes/all-routes';
import CardBody from '../../../components/common/cards/card-body';
import { useAppDispatch, useAppSelector } from '../../../store/customHooks';
import { getAllTransactionsAction } from '../../../store/wallets/wallet.actions';
import { getAllAppsAction } from '../../../store/apps/apps.actions';
import useAdjutorServicesHook from '../../../hooks/useAdjutorServicesHook';

function GetStarted() {
  const dispatch = useAppDispatch();
  const { getServices } = useAdjutorServicesHook();
  const { is_twofa_enabled } = useAppSelector((state) => state.auth);
  const { email } = useAppSelector((state) => state.account);
  const { totalAppsSize } = useAppSelector((state) => state.apps);
  const { totalTransationSize } = useAppSelector((state) => state.wallet);

  const [showCard, setShowCard] = useState(true);

  const tasks = {
    verify_email_task: { task: 'verify_email', status: true },
    complete_kyc_task: { task: 'complete_kyc_email', status: false },
    enable_2fa_task: { task: 'enable_2fa', status: is_twofa_enabled },
    create_app_task: {
      task: 'create_app',
      status: Boolean(totalAppsSize > 0),
    },
    fund_wallet_task: {
      task: 'fund_wallet',
      status: Boolean(totalTransationSize > 0),
    },
  };

  const todo = Object.values(tasks);

  const totalDone = useMemo(() => {
    let count = 0;
    todo.forEach((task) => {
      if (task.status) {
        count = count + 1;
      }
    });
    const percentage = (count * 100) / todo.length;
    return { count, percentage };
  }, [totalTransationSize]);

  useEffect(() => {
    getServices();
    if (totalTransationSize === 0) {
      dispatch(getAllTransactionsAction({ page: 1, limit: 5 }));
    }
    if (totalAppsSize === 0) {
      dispatch(getAllAppsAction({ page: 1, limit: 5 }));
    }
  }, []);

  return (
    <div className={Style.getting_started_container}>
      <Card
        className={Style.getting_started}
        cardHeading={
          <CardHeader
            title="Getting started"
            description="Here are a few things you need to do to get up and running on Adjutor"
            collapsible
            toggleCollapse={() => {
              setShowCard(!showCard);
            }}
            additiionalElement={
              <div
                style={{
                  display: 'flex',
                  gap: '0.75rem',
                  alignItems: 'center',
                  marginTop: '0.75rem',
                }}
              >
                <div style={{ width: '100%' }}>
                  <ProgressBar
                    progress={totalDone.count}
                    maximum={todo.length}
                  />
                </div>
                <div className="sm">{totalDone.percentage}%</div>
              </div>
            }
          />
        }
        collapsed={!showCard}
      >
        <CardBody className={Style.getting_started_todo}>
          <TodoTask
            title="Verify your email address"
            description={`Confirm the verification link sent to your email ${email} to get access to the platform.`}
            button={{
              label: '',
              link: '',
            }}
            done={tasks.verify_email_task.status}
          />
          <TodoTask
            title="Complete your KYC"
            description="To get access to our APIs, we require that you provide some additional information to verify your identity."
            button={{
              label: 'Complete KYC',
              link: urls.settings.account_management.index,
            }}
            done={tasks.complete_kyc_task.status}
          />
          <TodoTask
            title="Create an app"
            description="Create apps specifying the services you want to access."
            button={{
              label: 'Create an app',
              link: urls.business.apps.index,
            }}
            done={tasks.create_app_task.status}
          />
          <TodoTask
            title="Fund your wallet"
            description="You'll need to fund your wallet to make API calls in production."
            button={{
              label: 'Fund wallet',
              link: urls.business.wallet.index,
            }}
            done={tasks.fund_wallet_task.status}
          />
          <TodoTask
            title="Setup Two-Factor Authentication"
            description="We encourage setting up two-factor authentication as an added bolster of security."
            button={{
              label: 'Set up 2FA',
              link: urls.settings.profile.index,
            }}
            done={tasks.enable_2fa_task.status}
          />
        </CardBody>
      </Card>
    </div>
  );
}

export default GetStarted;
