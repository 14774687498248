import React from 'react';
import { IAllAppsTypes } from '../../../../store/apps/apps.interface';
import { fullDateFormat } from '../../../../utils/formatter';
import useAdjutorServicesHook from '../../../../hooks/useAdjutorServicesHook';
import Chip from '../../../../components/common/chip/chip';

type Props = {
  focusApp: IAllAppsTypes;
};

function AppDetails({ focusApp }: Props) {
  const { adjutorServicesOptions } = useAdjutorServicesHook();
  return (
    <>
      <div className="detail-row">
        <div className="detail-field">
          <h6 className="field-title">Name</h6>
          <p className="text-text-primary">{focusApp?.name}</p>
        </div>

        <div className="detail-field">
          <h6 className="field-title">Date Created</h6>
          <p className="text-text-primary">
            {fullDateFormat(focusApp?.created_on)}
          </p>
        </div>

        <div className="detail-field">
          <h6 className="field-title">Description</h6>
          <p className="text-text-primary">{focusApp?.description ?? '-'}</p>
        </div>

        <div className="detail-field">
          <h6 className="field-title">Webhook Url</h6>
          <p className="text-text-primary">{focusApp?.webhook_url ?? '-'}</p>
        </div>
      </div>

      <div className="detail-row" style={{ display: 'block' }}>
        <div className="detail-field">
          <h6 className="field-title">Services</h6>
          <p className="service-field">
            {focusApp?.services.map((service) => {
              const name = adjutorServicesOptions?.find?.((option) => {
                return option.value.toLowerCase() === service.toLowerCase();
              })?.label;

              return <Chip key={`${name}`} label={name} />;
            })}
          </p>
        </div>
      </div>
    </>
  );
}

export default AppDetails;
