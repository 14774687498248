import React, { useEffect } from 'react';
import './_event-details.scss';
import { useLocation } from 'react-router-dom';

import Card from '../../../../../components/common/cards/cards';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../store/customHooks';
import { getSingleEventAction } from '../../../../../store/audits/audits.actions';
import { IAuditState } from '../../../../../store/audits/audits.interface';
import { fullDateFormat } from '../../../../../utils/formatter';
import PageLoading from '../../../../../components/page-loading/pageLoading';
import CardBody from '../../../../../components/common/cards/card-body';
import PageTitle from '../../../../../components/common/page-title';
import CardHeader from '../../../../../components/common/cards/card-header';

function EventDetailsPage() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const auditState: IAuditState = useAppSelector((state) => state.audit);

  useEffect(() => {
    const currentLocation = location.pathname.split('/').at(-1);
    dispatch(getSingleEventAction(Number(currentLocation)));
  }, []);

  return (
    <div className="view-role-page">
      <PageTitle
        title="Audit log"
        description={`Viewing activity by ${auditState.focusEvent.admin.full_name}`}
      />

      {auditState.singleEventState === 'loading' ? (
        <PageLoading loadingMessage="Loading Event" />
      ) : (
        <Card cardHeading={<CardHeader title="Details" />}>
          <CardBody className="event-details-card">
            <div className="detail-row">
              <div className="detail-field">
                <h6 className="field-title">User</h6>
                <p>{auditState.focusEvent?.admin.full_name || '-'}</p>
              </div>

              <div className="detail-field">
                <h6 className="field-title">ACTIVITY</h6>
                <p>{auditState.focusEvent?.action || '-'}</p>
              </div>

              <div className="detail-field">
                <h6 className="field-title">DESCRIPTION</h6>
                <p>{auditState.focusEvent?.description || '-'}</p>
              </div>

              <div className="detail-field">
                <h6 className="field-title">EMAIL ADDRESS</h6>
                <p>{auditState.focusEvent?.admin.email || '-'}</p>
              </div>

              <div className="detail-field">
                <h6 className="field-title">SERVICES</h6>
                <p>
                  {fullDateFormat(
                    auditState.focusEvent?.user_view ||
                      auditState.focusEvent?.created_on
                  ) || '-'}
                </p>
              </div>

              <div className="detail-field">
                <h6 className="field-title">IP ADDRESS</h6>
                <p>{auditState.focusEvent?.ip || '-'}</p>
              </div>

              <div className="detail-field">
                <h6 className="field-title">City</h6>
                <p>{auditState.focusEvent?.location.city || '-'}</p>
              </div>

              <div className="detail-field">
                <h6 className="field-title">Region</h6>
                <p>{auditState.focusEvent?.location.region || '-'}</p>
              </div>

              <div className="detail-field">
                <h6 className="field-title">Country</h6>
                <p>{auditState.focusEvent?.location.country || '-'}</p>
              </div>

              <div className="detail-field">
                <h6 className="field-title">GEOLOCATION</h6>
                <p>{auditState.focusEvent?.location.latlng || '-'}</p>
              </div>
            </div>
          </CardBody>
        </Card>
      )}
    </div>
  );
}

export default EventDetailsPage;
