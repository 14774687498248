import { IKeyState } from "./key-management.interface";
import { createSlice } from "@reduxjs/toolkit";
import { getAdjutorTokenAction } from "./key-management.actions";


const initialState = {
  adjutorToken: "",
  isLoading: false,
  error: "",
} as IKeyState;

const KeyManagementSlice = createSlice({
  name: "AuthenticationState",
  initialState,

  reducers: {
    resetKeys: (state: IKeyState) => {
      Object.assign(state, initialState);
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getAdjutorTokenAction.pending, (state: IKeyState) => {
      state.isLoading = true;
    });
    builder.addCase(
      getAdjutorTokenAction.fulfilled,
      (state, action: any) => {
        state.adjutorToken = action.payload.token;
        state.isLoading = false;
      }
    );
    builder.addCase(
      getAdjutorTokenAction.rejected,
      (state: IKeyState, action: any) => {
        state.error = action.payload;
        state.isLoading = false;
      }
    );
  },
});

export const { resetKeys } = KeyManagementSlice.actions;

export default KeyManagementSlice.reducer;
