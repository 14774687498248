import * as Yup from 'yup';
import { DEFAULT_CHIP_STATUS } from '../components/common/chip/chip';
import { isURL } from 'class-validator';

export const numberWithCommas = (val: string) => {
  return val.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

export const mediaQuery = () => {
  let x = '';

  if (window.innerWidth < 1024 && window.innerWidth > 639) {
    x = 'Tablet';
  }

  if (window.innerWidth < 640) {
    x = 'Mobile';
  }

  window.addEventListener('resize', (e: any) => {
    if (
      e.target.screen.availWidth < 1024 &&
      e.target.screen.availWidth >= 640
    ) {
      x = 'Tablet';
    }

    if (e.target.screen.availWidth < 640) {
      x = 'Mobile';
    }
  });

  return { size: x };
};

export const objectToArray = (
  payload: any = { 0: {} }
): { key: string | number; arr: any }[] => {
  let newArr: any = [];

  Object.keys(payload || {})?.forEach((key) =>
    newArr.push({
      key: key,
      arr: { ...payload[key] },
    })
  );

  return newArr;
};

export const phoneNumberValidationRegex = /^\+?\d{1,15}$/;
export const phoneNumberValidationErrorMessage =
  'Phone Number should be in format +2348012345678';

export const urlValidation = (value: any) => {
  if (!value) return true; // Allow empty values (optional field)
  return isURL(value);
};

export const urlValidationRegex =
  /(https?:\/\/)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-]+=[a-zA-Z0-9-%]+&?)?$/;
export const urlValidationErrorMessage =
  'URL should be of the format https://yourhook.com';

export const defaultErrorMessage =
  'There was a problem, please try again or contact support';

export const PasswordSchema = Yup.string()
  .min(8, 'Enter at least 8 characters')
  .matches(
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
    'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special case Character'
  )
  .required('Required');

export const requestColorMatch = (request: number | string) => {
  if (!Number.isNaN(Number(request))) {
    let value = Number(request);
    switch (true) {
      case 200 <= value && 299 >= value:
        return DEFAULT_CHIP_STATUS.green;
        break;
      case 400 <= value && 499 >= value:
        return DEFAULT_CHIP_STATUS.red;
        break;
      case 500 <= value && 599 >= value:
        return DEFAULT_CHIP_STATUS.orange;
        break;
      default:
        return DEFAULT_CHIP_STATUS.neutral;
      // code block
    }
  }

  if (typeof request === 'string') {
    switch (request.toUpperCase()) {
      case 'GET':
        return DEFAULT_CHIP_STATUS.blue;
        break;
      case 'POST':
        return DEFAULT_CHIP_STATUS.green;
        break;
      case 'DELETE':
        return DEFAULT_CHIP_STATUS.red;
        break;
      case 'PUT':
        return DEFAULT_CHIP_STATUS.yellow;
        break;
      case 'SUCCESSFUL':
        return DEFAULT_CHIP_STATUS.success;
        break;
      case 'FAILED':
        return DEFAULT_CHIP_STATUS.error;
        break;
      case 'PENDING':
        return DEFAULT_CHIP_STATUS.warning;
        break;
      default:
        return DEFAULT_CHIP_STATUS.neutral;
      // code block
    }
  }
};
