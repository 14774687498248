import React from 'react';
import './_avatar.scss';
import { useAppSelector } from '../../store/customHooks';

function Avatar() {
  const { image_url, last_name } = useAppSelector((state) => state.account);
  return (
    <div className='avatar-container'>
      {image_url ? (
        <img src={image_url} className='avatar-image' alt="" />
      ) : (
        <div className="default-avatar">{last_name?.split?.('')?.[0] ?? last_name}</div>
      )}
    </div>
  );
}

export default Avatar;
