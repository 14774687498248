import React from 'react';
import Style from './external-link.module.scss';

interface Props
  extends React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > {
  label: string | React.ReactElement;
  styled?: boolean;
}

function ExternalLink({ styled = true, label, ...rest }: Readonly<Props>) {
  return (
    <a
      {...rest}
      className={`${styled ? Style.external_link : ''}`}
      target="_blank"
      rel="noreferrer noopenner"
    >
      {label}
    </a>
  );
}

export default ExternalLink;
