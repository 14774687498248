import React, { useState, useEffect } from 'react';
import '../../../pages/business/apps/_create-app.scss';
import './_create-model.scss';
import { useAppDispatch, useAppSelector } from '../../../store/customHooks';

//form
import { useFormik } from 'formik';

//components
import Input from '../../input-fields/input/input';
import SlidingPane from '../../slidingpane/slidingpane';
import FillButton, { ButtonColor } from '../../common/buttons/button';
import SlidepaneStatus from '../../slidepane-status/slidepane-status';

//utils
import {
  IDecisionModels,
  IModel,
} from '../../../store/decision-model/decision-model.interface';
import {
  createModelAction,
  getConfigModelAction,
} from '../../../store/decision-model/decision-model.actions';
import Toggle from '../../input-fields/toggle/toggle';
import PageLoading from '../../page-loading/pageLoading';
import { CreateModelSchema } from './create-model-validation-schema';
import urls from '../../../routes/all-routes';
import { useNavigate } from 'react-router-dom';
import ModalFooter from '../../common/modal/modal-footer';

export interface EditCompanyProps {
  isOpen: boolean;
  onClose: Function;
}

function CreateModelPane(props: EditCompanyProps) {
  const { isOpen, onClose } = props;
  const [formResponse, setFormResponse] = useState('');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const decisionModel: IDecisionModels = useAppSelector(
    (state) => state.decisionModel
  );

  useEffect(() => {
    if (!decisionModel.configModel) {
      dispatch(getConfigModelAction());
    }
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      description: '',
      decision_setting: {} as any,
      settings: [] as any,
    },

    validationSchema: CreateModelSchema,
    onSubmit: async (values) => {
      const payload = {
        ...values,
        offer_setting: decisionModel?.configOfferSetting.setting_example,
      };

      dispatch(createModelAction(payload));
    },
  });

  useEffect(() => {
    if (decisionModel.focusModelStatus === 'created') {
      navigate(
        urls.business.decision_model.for(`${decisionModel.focusModel.id}`)
      );
      formik.resetForm();
    }
  }, [decisionModel.focusModelStatus]);

  const handleDecisionSetting = (model: IModel, name: string, setting: any) => {
    const settingValue = {
      decision_type: name,
      setting: setting,
    };
    if (!Object.keys(formik.values.decision_setting).includes(name)) {
      formik.setFieldValue('decision_setting', {
        ...formik.values.decision_setting,
        [name]: model,
      });
      formik.setFieldValue('settings', [
        ...formik.values.settings,
        settingValue,
      ]);
    } else {
      delete formik.values.decision_setting[name];
      formik.setFieldValue('decision_setting', {
        ...formik.values.decision_setting,
      });

      formik.setFieldValue(
        'settings',
        formik.values.settings.filter((setting: any) => {
          return setting !== name;
        })
      );
    }
  };

  let content;
  let contentFooter;

  if (formResponse) {
    content = (
      <SlidepaneStatus
        onContinue={() => {
          onClose();
          setFormResponse('');
        }}
        message={<span>Model Details Have Been Created</span>}
      />
    );

    contentFooter = (
      <FillButton
        type="submit"
        onClick={() => {
          onClose();
          setFormResponse('');
        }}
      >
        Close
      </FillButton>
    );
  } else if (decisionModel.configModelStatus === 'success') {
    content = (
      <form className="create-app-form" onSubmit={formik.handleSubmit}>
        <div className="form-section">
          <Input
            type="text"
            label="Name"
            name="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            error={formik.touched.name && formik.errors.name}
            errorMessage={formik.errors.name}
          />

          <Input
            type="text"
            label="Description"
            name="description"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.description}
            error={formik.touched.description && formik.errors.description}
            errorMessage={formik.errors.description}
          />

          <div className="select-setting-section">
            <b>Choose Decision Settings</b>

            {decisionModel.configModel.map((model) => {
              return (
                <div className="select-setting-option" key={model.id}>
                  <span className="setting-title">
                    {model.name?.replace(/_/g, ' ')}
                  </span>
                  <Toggle
                    id={model.name}
                    name={model.name}
                    value={model.name}
                    checked={Object.keys(
                      formik.values.decision_setting || {}
                    )?.includes(model.name)}
                    onChange={() => {
                      handleDecisionSetting(
                        model.decision_example,
                        model.name,
                        model.setting_example
                      );
                    }}
                  />
                </div>
              );
            })}

            {formik.errors.decision_setting &&
              formik.touched.decision_setting && (
                <label className="error-message">
                  {formik.errors.decision_setting as any}
                </label>
              )}
          </div>
        </div>
      </form>
    );
    contentFooter = (
      <ModalFooter>
        <FillButton
          variant="outlined"
          onClick={() => {
            onClose();
            setFormResponse('');
          }}
          className="close-button"
          colorVariant={ButtonColor.neutral}
          label="Cancel"
        />

        <FillButton
          type="submit"
          onClick={formik.submitForm}
          isLoading={decisionModel?.focusModelStatus === 'creating'}
          disabled={
            !(formik.isValid && formik.dirty) ||
            Object.keys(formik.values.decision_setting).length === 0
          }
          label="Create Model"
          loadingText="Creating"
        />
      </ModalFooter>
    );
  } else {
    content = <PageLoading loadingMessage="Getting Default Configuration" />;
  }

  return (
    <SlidingPane
      isOpen={isOpen}
      title="Create A Model"
      onClose={onClose}
      footer={contentFooter}
    >
      {content}
    </SlidingPane>
  );
}

export default CreateModelPane;
