import { useState } from 'react';

interface Props {
  initialFilter: any;
}

function useFilterHook({ initialFilter }: Props) {
  const [filter, setFilter] = useState(initialFilter);

  const handleFilter = (e: any) => {
    let name = '';
    let value = '';

    if (e?.target) {
      name = e.target.name;
      value = e.target.value;
    } else {
      name = e.name;
      value = e.value;
    }

    setFilter({
      ...filter,
      [name]: value,
    });
  };

  const resetFilter = () =>{
    setFilter(initialFilter)
  }

  return { filter, handleFilter, setFilter, resetFilter };
}

export default useFilterHook;
