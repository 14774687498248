import React, { useState } from 'react';
import Modal, {
  ModalPosition,
} from '../../../../components/common/modal/modal';
import ModalFooter from '../../../../components/common/modal/modal-footer';
import FillButton, {
  ButtonColor,
} from '../../../../components/common/buttons/button';
import { resetAppTokenAction } from '../../../../store/apps/apps.actions';
import { useAppDispatch, useAppSelector } from '../../../../store/customHooks';
import { useParams } from 'react-router-dom';
import Input from '../../../../components/input-fields/input/input';
import ClickToCopy from '../../../../components/click-to-copy/click-to-copy';
import { ReactComponent as PasswordHidden } from '../../../../assets/icons/content-hidden.svg';
import { ReactComponent as PasswordVisible } from '../../../../assets/icons/content-visible.svg';
import { resetAppState } from '../../../../store/apps/apps.reducer';

type Props = {
  isOpen: boolean;
  handleClose: () => void;
};

function ResetAppTokenModal({ isOpen, handleClose }: Readonly<Props>) {
  const [token, setToken] = useState('');
  const [tokenVisible, setTokenVisible] = useState(false);
  const dispatch = useAppDispatch();
  const params: any = useParams();
  const appState = useAppSelector((state) => state.apps);

  return (
    <Modal
      isOpen={isOpen}
      title={
        appState.appTokenStatus === 'reset.success'
          ? 'App key reset successfully'
          : 'Confirm action'
      }
      onClose={() => {
        handleClose();
      }}
      className="reset-app-modal"
      modalPosition={ModalPosition.center}
      modalFooter={
        <ModalFooter>
          {appState.appTokenStatus === 'reset.success' ? (
            <FillButton
              onClick={() => {
                handleClose();
              }}
              label="Done"
            />
          ) : (
            <React.Fragment>
              <FillButton
                onClick={() => {
                  handleClose();
                }}
                colorVariant={ButtonColor.neutral}
                variant="outlined"
                disabled={appState.appTokenStatus === 'resetting'}
                className="close-button"
                label="Cancel"
              />
              <FillButton
                onClick={() => {
                  dispatch(resetAppTokenAction(params.id))
                    .unwrap()
                    .then((token) => {
                      setToken(token);

                      if (appState.appTokenStatus === 'reset.success') {
                        dispatch(resetAppState());
                      }
                    });
                }}
                colorVariant={ButtonColor.danger}
                isLoading={appState.appTokenStatus === 'resetting'}
                label="Yes, Reset"
              />
            </React.Fragment>
          )}
        </ModalFooter>
      }
    >
      {appState.appTokenStatus === 'reset.success' ? (
        <>
          <p>
            You have successfully reset the secret key for{' '}
            <b>{appState.focusApp.name}</b>.<br /> <br />
            Please note that we will only show your secret key to you once
            (right now). If you forget it, you’ll have to reset your app's API
            key so make sure you copy it somewhere safe immediately.
          </p>

          <Input
            type={tokenVisible ? 'text' : 'password'}
            inputContainerStyle={{ marginTop: '1rem' }}
            value={token}
            label="API Key"
            disabled
            icon={
              <>
                <div
                  className="click-to-copy-icon"
                  onClick={() => {
                    if (tokenVisible) {
                      setTokenVisible(false);
                    } else {
                      setTokenVisible(true);
                    }
                  }}
                >
                  {tokenVisible ? <PasswordVisible /> : <PasswordHidden />}
                </div>

                <ClickToCopy
                  showTooltip={false}
                  className="click-to-copy-icon"
                  text={token}
                />
              </>
            }
            iconClassName="token-action-buttons"
          />
        </>
      ) : (
        <p>
          Are you sure you want to reset the key for{' '}
          <b>{appState.focusApp.name}</b>? The previously generated key would no
          longer be valid afterwards.
        </p>
      )}
    </Modal>
  );
}

export default ResetAppTokenModal;
