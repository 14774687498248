import React, { useEffect, useState } from 'react';
import Styles from './_multi-select-checkbox.module.scss';
import Input from '../input/input';
import { MultiSelectCheckboxProps } from './types';
import MultiSelectCheckboxCategory from './multi-select-checbox-category';
import MultiSelectCheckboxElement from './multi-select-checbox';
import Spinner from '../../common/spinner/spinner';

function MultiSelectCheckbox({
  title,
  description,
  SelectOptions,
  SelectValues,
  setSelectedValues,
  loading = false,
}: MultiSelectCheckboxProps) {
  const [values, setValues] = useState(SelectValues);
  const [search, setSearch] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(SelectOptions);

  useEffect(() => {
    setSelectedValues(values);
  }, [values]);

  const handleSearch = (e: any) => {
    if (e.target.value) {
      const filter = filteredOptions.filter((option) => {
        return option.label
          .toLowerCase()
          .includes(e.target.value.toLowerCase());
      });
      setFilteredOptions(filter);
      return;
    }
    setFilteredOptions(SelectOptions);
  };

  const optionKeys = SelectOptions.reduce((keys, obj) => {
    Object.keys(obj).forEach((key) => keys.add(key));
    return keys;
  }, new Set<string>());

  const useCategory = Array.from(optionKeys).includes('category');

  return (
    <div className={Styles.multi_select_checkbox}>
      <h6 className="sm font-600">{title}</h6>
      {description && <p className="sm">{description}</p>}

      {loading && <Spinner className={Styles.loading_icon} />}

      {!loading && (
        <div className={Styles.search_input}>
          <Input
            label="Search by name"
            value={search}
            onChange={(e: any) => {
              setSearch(e.target.value);
              handleSearch(e);
            }}
          />
        </div>
      )}

      {!loading && (
        <div className={Styles.selected_categories}>
          {useCategory ? (
            <MultiSelectCheckboxCategory
              filteredOptions={filteredOptions}
              values={SelectValues}
              setValues={setValues}
            />
          ) : (
            filteredOptions.map((option) => {
              return (
                <MultiSelectCheckboxElement
                  key={`${option?.value}`}
                  option={option}
                  value={SelectValues.includes(option.value)}
                  handleChange={(e: any) => {
                    if (e.target.checked) {
                      setValues([...values, option?.value]);
                      return;
                    }

                    const updatedValue = values?.filter((services) => {
                      return (
                        services.toLowerCase() !== option?.value?.toLowerCase()
                      );
                    });
                    setValues(updatedValue);
                  }}
                />
              );
            })
          )}
        </div>
      )}
    </div>
  );
}

export default MultiSelectCheckbox;
