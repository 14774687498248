import React from 'react';
import Style from './_card.module.scss';

export interface Props {
  className?: string;
  cardHeading?: React.ReactElement;
  children: React.ReactNode;
  onClick?: () => void;
  collapsed?: boolean;
  [rest: string]: any;
}

export default function Card(props: Props) {
  return (
    <div
      className={`${Style.card} ${props.className}`}
      onClick={props.onClick}
      {...props.rest}
    >
      {props.cardHeading && (
        <div
          className={`${Style.card_heading_container} ${
            props.collapsed ? Style.card_heading_collapsed : ''
          }`}
        >
          {props.cardHeading}
        </div>
      )}

      {!props.collapsed && props.children}
    </div>
  );
}
