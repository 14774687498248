import React, { CSSProperties } from 'react';
import './_progress-bar.scss';

type Props = {
  progress: number;
  maximum: number;
};

function ProgressBar({ progress, maximum }: Readonly<Props>) {
  const value = (progress * 100) / maximum;

  return (
    <div
      style={
        {
          '--progress-bar-value': `${value}%`,
        } as CSSProperties
      }
      className="progressbar-container"
    >
      <div className="loading"></div>
    </div>
  );
}

export default ProgressBar;
