import {
  IAuditAnalytics,
  IAuditsLogType,
  IAuditState,
  ISingleEventType,
} from './audits.interface';
import { createSlice } from '@reduxjs/toolkit';
import {
  getAuditsAction,
  getAuditsAnalyticsAction,
  getEventLogAction,
  getSingleAuditsAction,
  getSingleEventAction,
} from './audits.actions';

const initalEventState: ISingleEventType = {
  id: 0,
  action: '',
  ip: '',
  request_id: '',
  description: '',
  created_on: '',
  type: '',
  user_view: '',
  admin: {
    email: '',
    full_name: '',
  },
  location: {
    city: '',
    region: '',
    country: '',
    latlng: '',
  },
};

const initialAuditState: IAuditsLogType = {
  id: '',
  request_id: '',
  url: '',
  method: '',
  status_code: 0,
  created_on: '',
  charge: 0,
  response_time: 0,
  ip: '',
  request_body: '',
  response_body: '',
  host: '',
};

const initialAuditMonthState: IAuditAnalytics = {
  api_call_charge: 0,
  activity_month: '',
  api_calls: 0,
  api_calls_errors: 0,
  api_calls_not_found: 0,
  api_calls_successful: 0,
  days_in_month: 0,
};

const initialState = {
  auditsLog: [],
  eventsLog: [],
  auditAnalyticsState: 'idle',
  auditState: 'idle',
  eventsState: 'idle',
  auditLogAnalytics: [],
  singleAuditState: 'idle',
  singleEventState: 'idle',
  totalAuditSize: 0,
  totalEventsSize: 0,
  focusEvent: initalEventState,
  focusAudit: initialAuditState,
  error: '',
  currentMonthData: initialAuditMonthState,
} as IAuditState;

const AuditSlice = createSlice({
  name: 'AuditState',
  initialState,

  reducers: {},

  extraReducers: (builder) => {
    //Audits Analytics
    builder.addCase(getAuditsAnalyticsAction.pending, (state: IAuditState) => {
      state.auditAnalyticsState = 'loading';
    });

    builder.addCase(
      getAuditsAnalyticsAction.fulfilled,
      (state: IAuditState, action: any) => {
        if (action.payload.analytics?.length > 0) {
          state.auditLogAnalytics = action.payload.analytics;
          const currentMonthData = action.payload.analytics?.slice(-1)[0];
          state.currentMonthData = currentMonthData;
        }

        state.auditAnalyticsState = 'success';
      }
    );

    builder.addCase(
      getAuditsAnalyticsAction.rejected,
      (state: IAuditState, action: any) => {
        state.error = action.payload;
        state.auditAnalyticsState = 'error';
      }
    );

    //Audits Logs
    builder.addCase(getAuditsAction.pending, (state: IAuditState) => {
      state.auditState = 'loading';
    });

    builder.addCase(
      getAuditsAction.fulfilled,
      (state: IAuditState, action: any) => {
        state.auditsLog = action.payload.calls;
        state.totalAuditSize = action.payload.page_info.total;
        state.auditState = 'success';
      }
    );

    builder.addCase(
      getAuditsAction.rejected,
      (state: IAuditState, action: any) => {
        state.error = action.payload;
        state.auditState = 'error';
      }
    );

    // Single Events Logs
    builder.addCase(getSingleAuditsAction.pending, (state: IAuditState) => {
      state.singleAuditState = 'loading';
    });
    builder.addCase(
      getSingleAuditsAction.fulfilled,
      (state: IAuditState, action: any) => {
        const log = action.payload;

        state.focusAudit = log;
        state.focusAudit.response_time = log.tat;

        state.singleAuditState = 'success';
      }
    );
    builder.addCase(
      getSingleAuditsAction.rejected,
      (state: IAuditState) => {
        state.singleAuditState = 'error';
      }
    );

    // Events Logs
    builder.addCase(getEventLogAction.pending, (state: IAuditState) => {
      state.eventsState = 'loading';
    });
    builder.addCase(
      getEventLogAction.fulfilled,
      (state: IAuditState, action: any) => {
        state.eventsLog = action.payload.logs;
        state.totalEventsSize = action.payload.page_info.total;
        state.eventsState = 'success';
      }
    );
    builder.addCase(
      getEventLogAction.rejected,
      (state: IAuditState, action: any) => {
        state.error = action.payload;
        state.eventsState = 'error';
      }
    );

    // Single Events Logs
    builder.addCase(getSingleEventAction.pending, (state: IAuditState) => {
      state.singleEventState = 'loading';
    });
    builder.addCase(
      getSingleEventAction.fulfilled,
      (state: IAuditState, action: any) => {
        const log = action.payload.log;

        state.focusEvent = {
          ...log,
        };

        state.singleEventState = 'success';
      }
    );
    builder.addCase(
      getSingleEventAction.rejected,
      (state: IAuditState) => {
        state.singleEventState = 'error';
      }
    );
  },
});

export default AuditSlice.reducer;
