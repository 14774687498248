import React, { useState, useCallback } from 'react';
import './_app.scss';

//components
import Card from '../../../components/common/cards/cards';
import FillButton from '../../../components/common/buttons/button';
import Table from '../../../components/table/table';
import TableRow from '../../../components/table/tablebody';
import MobileTable from '../../../components/table/mobile-table/mobile-table';
import MobileTableRow from '../../../components/table/mobile-table/mobile-tablebody';
import DropdownMenu from '../../../components/common/dropdown/dropdown-menu';
import DropdownOption from '../../../components/common/dropdown/dropdown-option';

//Icons
import FallbackLogo from '../../../assets/illustrations/fallback-image.png';
import { ReactComponent as AddIcon } from '../../../assets/icons/plus.svg';
import { ReactComponent as MoreIcon } from '../../../assets/icons/more.svg';
import CreateAppPane from './create-app-pane';
import { useAppDispatch, useAppSelector } from '../../../store/customHooks';
import { getAllAppsAction } from '../../../store/apps/apps.actions';
import { IAllAppsTypes, IAppsState } from '../../../store/apps/apps.interface';
import { fullDateFormat } from '../../../utils/formatter';
import { useNavigate } from 'react-router-dom';
import urls, { externalRoutes } from '../../../routes/all-routes';
import PermissionsGate from '../../../utils/hooks/permission-gate';
import { ADJUTOR_SCOPES } from '../../../utils/hooks/permission-maps';
import EmptyTable from '../../../components/empty-table/EmptyTable';
import Pagination from '../../../components/common/pagination/Pagination';
import Chip from '../../../components/common/chip/chip';
import CardFooter from '../../../components/common/cards/card-footer';
import PageTitle from '../../../components/common/page-title';
import ExternalLink from '../../../components/common/text/external-link';
import CreateAppSuccessTokenModal from './create-app-token-modal';
import useScreenSizeHook from '../../../hooks/useScreenSizeHook';
import useAdjutorServicesHook from '../../../hooks/useAdjutorServicesHook';

const AppTableMoreMenu = (
  id: string,
  viewDetailsAction: (id: string) => void
) => {
  return (
    <DropdownMenu
      renderButton={<MoreIcon />}
      className="action-dropdown"
      id={`dropdown-menu-${id}`}
    >
      <DropdownOption
        onClick={() => {
          viewDetailsAction(id);
        }}
      >
        View Details
      </DropdownOption>
    </DropdownMenu>
  );
};

function AppPage() {
  const navigate = useNavigate();
  const { adjutorServicesOptions } = useAdjutorServicesHook();
  const [showCreateAppPane, setShowCreateAppPane] = useState(false);

  const [createdApp, setCreatedApp] = useState<{
    appName: string;
    appId: string;
    appKey: string;
  } | null>(null);
  const { isDesktop } = useScreenSizeHook();

  const dispatch = useAppDispatch();

  const { allApps, isLoading, totalAppsSize }: IAppsState = useAppSelector(
    (state) => state.apps
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const fetchAllApps = useCallback(() => {
    dispatch(getAllAppsAction({ page: currentPage - 1, limit: perPage }));
  }, [perPage, currentPage]);

  const loadPage = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  const viewDetailsAction = (id: string) => {
    navigate(urls.business.apps.for(id));
  };

  return (
    <div className="all-app-page">
      <div className="page-header-container">
        <PageTitle
          title="Apps"
          description={
            <>
              Manage and setup your apps. Check our{' '}
              <ExternalLink
                href={externalRoutes.docs.index}
                label={'API Documentation'}
              />{' '}
              for more.
            </>
          }
          rightElement={
            <PermissionsGate scopes={[ADJUTOR_SCOPES.canCreateApps]}>
              <div>
                <FillButton
                  iconClassName="create-app-icon"
                  onClick={() => {
                    setShowCreateAppPane(!showCreateAppPane);
                  }}
                  icon={<AddIcon />}
                  label="Create an App"
                />
              </div>
            </PermissionsGate>
          }
        />
      </div>

      <Card className="apps-table">
        {isDesktop ? (
          <Table
            title="My apps"
            tableHeader={['App Name', 'Services', 'Date Created']}
            more={true}
            isLoading={isLoading && allApps.length === 0}
            isEmpty={allApps.length === 0}
            emptyStateComponent={
              <EmptyTable
                message="No app created"
                additional_message="Apps you create will appear here. Create one to get started."
              />
            }
          >
            {allApps.map((app: IAllAppsTypes) => {
              return (
                <TableRow
                  moreOption={AppTableMoreMenu(`${app.id}`, viewDetailsAction)}
                  key={app.id}
                  onRowClickAction={() => {
                    viewDetailsAction(app.id);
                  }}
                >
                  <td className="app-name-col">
                    <img
                      src={app?.logo_url ?? FallbackLogo}
                      alt=""
                      className="app-logo"
                    />
                    {app.name}
                  </td>

                  <td className="service-col">
                    <div className="services-container">
                      {app.services.slice(0, 5).map((service, idx) => {
                        const name = adjutorServicesOptions?.find?.((option) => {
                          return (
                            option.value.toLowerCase() === service.toLowerCase()
                          );
                        })?.label;

                        return (
                          <span className="service" key={idx}>
                            <Chip label={name} />
                          </span>
                        );
                      })}
                      {app.services?.length > 5 && (
                        <span style={{ marginLeft: '0.5rem' }}>
                          + {app.services?.length - 5} others
                        </span>
                      )}
                    </div>
                  </td>

                  <td>{fullDateFormat(app.created_on)}</td>
                </TableRow>
              );
            })}
          </Table>
        ) : (
          <MobileTable
            title="My apps"
            className="table-mobile-card"
            isLoading={isLoading && allApps.length === 0}
            isEmpty={allApps.length === 0}
            emptyStateComponent={
              <EmptyTable
                message="No data to show yet"
                additional_message="Apps you create will appear here. Create one to get started."
              />
            }
          >
            {allApps.map((app: IAllAppsTypes) => {
              return (
                <MobileTableRow
                  moreOption={AppTableMoreMenu(`${app.id}`, viewDetailsAction)}
                  key={app.id}
                  onRowClickAction={() => {
                    viewDetailsAction(app.id);
                  }}
                >
                  <div className="field-container">
                    <img
                      src={app?.logo_url ?? FallbackLogo}
                      alt=""
                      className="app-logo"
                    />
                  </div>

                  <div className="field-container">
                    <span className="field-title">App Name:</span>
                    {app.name}
                  </div>

                  <div className="field-container">
                    <span className="field-title">Services:</span>
                    <div className="services-container">
                      {app.services.slice(0, 5).map((service, idx) => {
                        const name = adjutorServicesOptions?.find?.((option) => {
                          return (
                            option.value.toLowerCase() === service.toLowerCase()
                          );
                        })?.label;

                        return (
                          <span className="service" key={idx}>
                            <Chip label={name} />
                          </span>
                        );
                      })}
                      {app.services?.length > 5 && (
                        <span style={{ marginLeft: '0.5rem' }}>
                          + {app.services?.length - 5} others
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="field-container">
                    <span className="field-title">Created:</span>
                    {fullDateFormat(app.created_on)}
                  </div>
                </MobileTableRow>
              );
            })}
          </MobileTable>
        )}

        <CardFooter>
          {allApps && (
            <Pagination
              total={totalAppsSize}
              perPage={perPage}
              setPerPage={setPerPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              loadPage={loadPage}
              perPageTitle="Models"
              updateDataCallback={fetchAllApps}
            />
          )}
        </CardFooter>
      </Card>

      <CreateAppSuccessTokenModal
        isOpen={Boolean(createdApp?.appId)}
        handleClose={() => {
          setCreatedApp(null);
        }}
        data={createdApp}
      />

      <CreateAppPane
        isOpen={showCreateAppPane}
        onClose={(res) => {
          setShowCreateAppPane(!showCreateAppPane);

          if (res) {
            setCreatedApp({
              appId: res.data.uuid,
              appName: res.data.name,
              appKey: res.key,
            });
          }
        }}
        title="Create an app"
        type="create"
        initialValues={{
          name: '',
          description: '',
          webhook_url: '',
          services: [],
          logo_url: '',
        }}
      />
    </div>
  );
}

export default AppPage;
