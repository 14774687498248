import { createAsyncThunk } from '@reduxjs/toolkit';
import { callApi } from '../../config/apiSetup';
import { adjutorLogUrl } from '../../config/allUrl';
import { RootState } from '../store';
import { toast } from 'react-hot-toast';
import { defaultErrorMessage } from '../../utils/helper';

export const getAdjutorTokenAction = createAsyncThunk(
  'keys/getAdjutorTokenAction',
  async (dispatch, ThunkApi) => {
    try {
      const res = await callApi.get(`${adjutorLogUrl}/token?org_id=`);
      return res.data;
    } catch (error: any) {
      const message: string = error.response.data?.message || error?.message;
      toast.error(message ?? defaultErrorMessage);
      return ThunkApi.rejectWithValue(message);
    }
  }
);

export const resetAdjutorTokenAction = createAsyncThunk<
  { res: string },
  { values: { role_id: string; email: string } },
  { state: RootState }
>('keys/getAdjutorTokenAction', async (dispatch, ThunkApi) => {
  const { auth } = ThunkApi.getState();

  const org_id = auth.org_id;
  try {
    const res = await callApi.post(
      `${adjutorLogUrl}/token?org_id=${org_id}`,
      {}
    );
    return res.data;
  } catch (error: any) {
    const message: string = error.response.data?.message || error?.message;
    toast.error(message ?? defaultErrorMessage);
    return ThunkApi.rejectWithValue(message);
  }
});
