import React from 'react';
import './_settings.scss';

//compoenets
import TeamManagement from './Team Management/team-management';
import ApiPricing from './Api Pricing/api-pricing';
import AccountManagement from './Account Management';
import { useLocation } from 'react-router-dom';
import urls from '../../routes/all-routes';
import RoleManagement from './Role Management/role-management';
import { ADJUTOR_SCOPES } from '../../utils/hooks/permission-maps';
import PageTitle from '../../components/common/page-title';
import ProfileManagement from '../Profile/profile-management';
import CustomTabs from '../../components/tabls';

const tabs = [
  {
    title: 'Profile',
    content: <ProfileManagement />,
    route: urls.settings.profile.index,
  },
  {
    title: 'Account Settings',
    content: <AccountManagement />,
    route: urls.settings.account_management.index,
  },
  {
    title: 'Team',
    content: <TeamManagement />,
    route: urls.settings.team_management.index,
  },
  {
    title: 'Roles and Permissions',
    content: <RoleManagement />,
    route: urls.settings.role_management.index,
  },
  {
    title: 'API Pricing',
    content: <ApiPricing />,
    route: urls.settings.api_pricing.index,
    permissionScope: [ADJUTOR_SCOPES.canViewPricing],
  },
];

function SettingsPage() {
  const location = useLocation();

  const selectedTab = tabs.indexOf(
    tabs.filter((tab) => {
      return location.pathname === tab.route;
    })[0]
  );

  return (
    <div className="settings-page">
      <PageTitle
        title="Settings"
        description="Manage your profile and other platform settings."
      />

      <CustomTabs defaultIndex={selectedTab} tabs={tabs} />
    </div>
  );
}

export default SettingsPage;
