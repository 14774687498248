import React, { useState } from 'react';
import FillButton, { ButtonColor } from '../../common/buttons/button';
import './_mobile-table.scss';

//icon
import { ReactComponent as FilterIcon } from '../../../assets/icons/filter.svg';
import { TableProps } from './mobile-table.interface';
import Spinner from '../../common/spinner/spinner';
import CardBody from '../../common/cards/card-body';
import SlidingPane from '../../slidingpane/slidingpane';
import ModalFooter from '../../common/modal/modal-footer';

export default function MobileTable(props: TableProps) {
  const [openFilter, setOpenFilter] = useState(false);

  const handleToggleFilter = () => {
    setOpenFilter(!openFilter);
  };

  return (
    <CardBody
      className={`mobile-table-container ${
        props.className ? props.className : ''
      }`}
    >
      <div className="table-heading">
        <span className="title">{props.title}</span>

        {props.filter && (
          <FillButton
            variant="outlined"
            label="Filter"
            className="filter-btn"
            onClick={handleToggleFilter}
            icon={<FilterIcon />}
            colorVariant={ButtonColor.neutral}
          />
        )}

        {props.tableHeaderContent && !props.isEmpty && props.tableHeaderContent}
      </div>

      <div className={`table-wrapper`}>
        {props.isEmpty ? (
          props.emptyStateComponent
        ) : (
          <div className="mobile-table-body" data-loading={props.isLoading}>
            {props.isLoading ? (
              <Spinner className="table-spinner" />
            ) : (
              <>
                {' '}
                {props.moreOption}
                {props.children}
              </>
            )}
          </div>
        )}
      </div>

      <SlidingPane
        isOpen={openFilter}
        onClose={handleToggleFilter}
        title="Filter"
        footer={
          <ModalFooter>
            <FillButton
              variant="outlined"
              onClick={() => {
                handleToggleFilter();
                props.handleFilterReset?.();
              }}
              className="close-button"
              colorVariant={ButtonColor.neutral}
              label="Reset"
            />

            <FillButton
              type="submit"
              onClick={() => {
                props.handleFilterSubmit?.();
                handleToggleFilter();
              }}
              label="Filter"
            />
          </ModalFooter>
        }
      >
        {props.filter}
      </SlidingPane>
    </CardBody>
  );
}
