import React, { useEffect, useRef } from 'react';
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import ace from 'brace';
import 'brace/mode/json';
import 'brace/theme/github';

type Props = {
  value: string;
  onChange: (value: any) => void;
};

function JsonEditior({ value, onChange }: Props) {
  const jsonEditorRef: any = useRef(null);
  useEffect(() => {
    if (jsonEditorRef.current !== null) {
      jsonEditorRef.current?.set(value);
    }
  }, [value]);

  const setRef = (instance: any) => {
    if (instance) {
      jsonEditorRef.current = instance.jsonEditor;
    } else {
      jsonEditorRef.current = null;
    }
  };
  return (
    <Editor
      ref={setRef}
      value={value}
      ace={ace}
      mode={'code'}
      search={false}
      statusBar={true}
      theme="ace/theme/github"
      onChange={onChange}
      htmlElementProps={{ style: { height: '100%' } }}
    />
  );
}

export default JsonEditior;
