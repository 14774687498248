import React from 'react';
import Style from './_card.module.scss';

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  children: React.ReactNode;
}
function CardBody({ children, ...rest }: Props) {
  return (
    <div {...rest} className={`${rest?.className ?? ''} ${Style.card_body}`}>
      {children}
    </div>
  );
}

export default CardBody;
