import React, { Dispatch, SetStateAction } from 'react';
import Modal, { ModalPosition } from '../common/modal/modal';

type Props = {
  showNoticeModal: boolean;
  setShowNoticeModal: Dispatch<SetStateAction<boolean>>;
  message: string | React.ReactElement;
  title: string;
  actionButtons: React.ReactElement;
};

function NoticeModal(props: Props) {
  const { showNoticeModal, setShowNoticeModal, title, message, actionButtons } =
    props;
  return (
    <Modal
      isOpen={showNoticeModal}
      title={title}
      onClose={() => {
        setShowNoticeModal(!showNoticeModal);
      }}
      className="delete-app-modal"
      modalPosition={ModalPosition.center}
      modalFooter={actionButtons}
    >
      <>
        <p>{message}</p>
      </>
    </Modal>
  );
}

export default NoticeModal;
