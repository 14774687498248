import { createAsyncThunk } from '@reduxjs/toolkit';
import { callApi } from '../../config/apiSetup';
import {
  accountUrl,
  allApiPricingUrl,
  teamMembersUrl,
} from '../../config/allUrl';
import { toast } from 'react-hot-toast';
import { RootState } from '../store';
import { toggleTwoFAEnabled } from '../auth/auth.reducer';
import { defaultErrorMessage } from '../../utils/helper';

export const getAllApiPricingAction = createAsyncThunk(
  'settings/getAllApiPricing',
  async (dispatch, ThunkApi) => {
    try {
      const res = await callApi.get(allApiPricingUrl);
      return res.data;
    } catch (error: any) {
      const message: string = error?.response?.data?.message || error?.message;
      toast.error(message ?? defaultErrorMessage);
      return ThunkApi.rejectWithValue(message);
    }
  }
);

export const getTeamMembersAction = createAsyncThunk(
  'settings/getTeamMembers',
  async (dispatch, ThunkApi) => {
    try {
      const res = await callApi.get(teamMembersUrl);
      return res.data;
    } catch (error: any) {
      const message: string = error?.response?.data?.message || error?.message;
      toast.error(message ?? defaultErrorMessage);
      return ThunkApi.rejectWithValue(message);
    }
  }
);

export const inviteTeamMembersAction = createAsyncThunk<
  {},
  { role_id: string; email: string },
  { state: RootState }
>('settings/inviteTeamMembers', async (values, ThunkApi) => {
  const { auth } = ThunkApi.getState();
  const org_id = auth.org_id;

  const payload = {
    ...values,
    org_id: org_id,
  };

  try {
    await callApi.post(teamMembersUrl, payload);

    ThunkApi.dispatch(getTeamMembersAction());
  } catch (error: any) {
    const message: string = error?.response?.data?.message || error?.message;
    toast.error(message ?? defaultErrorMessage);
    return ThunkApi.rejectWithValue(message);
  }
});

export const manageTeamMembersAction = createAsyncThunk(
  'settings/manageTeamMembers',
  async (values: any, ThunkApi) => {
    try {
      await callApi.post(`${teamMembersUrl}/manage-role`, values);

      ThunkApi.dispatch(getTeamMembersAction());
    } catch (error: any) {
      const message: string = error?.response?.data?.message || error?.message;
      toast.error(message ?? defaultErrorMessage);
      return ThunkApi.rejectWithValue(message);
    }
  }
);

export const activateTeamMembersAction = createAsyncThunk(
  'settings/activateTeamMembers',
  async (id: number | null, ThunkApi) => {
    const payload = { status: 'activate' };
    try {
      await callApi.put(`${teamMembersUrl}/status/${id}`, payload);

      ThunkApi.dispatch(getTeamMembersAction());
    } catch (error: any) {
      const message: string = error?.response?.data?.message || error?.message;
      toast.error(message ?? defaultErrorMessage);
      return ThunkApi.rejectWithValue(message);
    }
  }
);

export const deactivateTeamMembersAction = createAsyncThunk(
  'settings/deactivateTeamMembers',
  async (id: number | null, ThunkApi) => {
    const payload = { status: 'deactivate' };
    try {
      await callApi.put(`${teamMembersUrl}/status/${id}`, payload);

      ThunkApi.dispatch(getTeamMembersAction());
    } catch (error: any) {
      const message: string = error?.response?.data?.message || error?.message;
      toast.error(message ?? defaultErrorMessage);
      return ThunkApi.rejectWithValue(message);
    }
  }
);

export const deleteTeamMembersAction = createAsyncThunk(
  'settings/deleteTeamMembers',
  async (id: number | null, ThunkApi) => {
    const payload = { status: 'delete' };
    try {
      await callApi.put(`${teamMembersUrl}/status/${id}`, payload);

      ThunkApi.dispatch(getTeamMembersAction());
    } catch (error: any) {
      const message: string = error?.response?.data?.message || error?.message;
      toast.error(message ?? defaultErrorMessage);
      return ThunkApi.rejectWithValue(message);
    }
  }
);

export const toggletwofaAction = createAsyncThunk(
  'settings/toggletwofaAction',
  async (payload: { type: string; token: string | number }, ThunkApi) => {
    try {
      await callApi.patch(`${accountUrl}/twofa/${payload.type}`, {
        token: payload.token,
      });
      ThunkApi.dispatch(toggleTwoFAEnabled());
    } catch (error: any) {
      const message: string = error?.response?.data?.message || error?.message;
      toast.error(message ?? defaultErrorMessage);
      return ThunkApi.rejectWithValue(message);
    }
  }
);

export const initializeToggletwofaAction = createAsyncThunk(
  'settings/initalizetwofaAction',
  async (payload, ThunkApi) => {
    try {
      const res = await callApi.post(`${accountUrl}/twofa/intialize`, {});

      return res.data;
    } catch (error: any) {
      const message: string = error?.response?.data?.message || error?.message;
      toast.error(message ?? defaultErrorMessage);
      return ThunkApi.rejectWithValue(message);
    }
  }
);
