import React from 'react';

export interface IModalFooter {
  useButton?: boolean;
  children: React.ReactNode;
  className?: string;
}

function ModalFooter({
  className,
  children,
  useButton = true,
}: Readonly<IModalFooter>) {
  return (
    <div
      className={`${useButton ? 'modal-footer-button' : ''} ${className ?? ''}`}
    >
      {children}
    </div>
  );
}

export default ModalFooter;
