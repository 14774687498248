import React from 'react';
import './_toggle.scss';

export interface ToggleProps {
  value: string;
  className?: string;
  name: string;
  id?: string;
  label?: string;
  labelClassName?: string;
  [rest: string]: any;
}

function Toggle(props: ToggleProps) {
  const { value, name, className, ...rest } = props;

  return (
    <div className={`${className ?? ''} toggle-component-container`}>
      <label className="switch">
        <input type="checkbox" name="name" value={value} {...rest} />
        <span className="slider"></span>
      </label>

      {props.label && (
        <label
          className={`label ${
            props.labelClassName ? props.labelClassName : ''
          }`}
          htmlFor={name}
        >
          {props.label}
        </label>
      )}
    </div>
  );
}

export default Toggle;
