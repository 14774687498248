import React from "react";
import { ReactComponent as BackIcon } from "../../assets/icons/arrow-back.svg";
import FillButton from "../../components/common/buttons/button";
import "./_404.scss";
import { useNavigate } from "react-router-dom";

function NotFoundPage() {
  const navigate = useNavigate();

  return (
    <div className="NotFoundPage">
      Page Not existing
      <FillButton
        onClick={() => {
          navigate(-1);
        }}
      >
        <span className="icon">
          <BackIcon />
        </span>
        Go Back To Previous Page
      </FillButton>
    </div>
  );
}

export default NotFoundPage;
