import React, { useState, useEffect } from 'react';
import './_team-management.scss';
import Table from '../../../components/table/table';
import TableRow from '../../../components/table/tablebody';
import DropdownMenu from '../../../components/common/dropdown/dropdown-menu';
import DropdownOption from '../../../components/common/dropdown/dropdown-option';
import FillButton, {
  ButtonColor,
} from '../../../components/common/buttons/button';

//icons
import { ReactComponent as MoreIcon } from '../../../assets/icons/more.svg';
import MobileTable from '../../../components/table/mobile-table/mobile-table';
import MobileTableRow from '../../../components/table/mobile-table/mobile-tablebody';
import EmptyTable from '../../../components/empty-table/EmptyTable';
import { useAppDispatch, useAppSelector } from '../../../store/customHooks';
import {
  ISettingsState,
  ITeamMember,
} from '../../../store/settings/settings.interface';
import {
  activateTeamMembersAction,
  deactivateTeamMembersAction,
  deleteTeamMembersAction,
  getTeamMembersAction,
} from '../../../store/settings/settings.actions';
import InviteMemberPane from './invite-member-pane';
import PageLoading from '../../../components/page-loading/pageLoading';
import ManageMemberPane from './manage-member-pane';
import NoticeModal from '../../../components/notice-modal/notice';
import { IAuthState } from '../../../store/auth/auth.interface';
import ModalFooter from '../../../components/common/modal/modal-footer';
import { ADJUTOR_ROLES } from '../../../utils/hooks/permission-maps';
import toast from 'react-hot-toast';
import useScreenSizeHook from '../../../hooks/useScreenSizeHook';

interface DropDownActionsProps {
  role: any;
  index: number;
  authState: IAuthState;
  showManageMemberPane: boolean;
  setShowManageMemberPane: React.Dispatch<React.SetStateAction<boolean>>;
  showActivateModal: boolean;
  setShowActivateModal: React.Dispatch<React.SetStateAction<boolean>>;
  showDeactivateModal: boolean;
  setShowDeactivateModal: React.Dispatch<React.SetStateAction<boolean>>;
  showDeleteMemberModal: boolean;
  setShowDeleteMemberModal: React.Dispatch<React.SetStateAction<boolean>>;
  setFocusMember: React.Dispatch<React.SetStateAction<ITeamMember | undefined>>;
}

const DropDownActions = ({
  role,
  index,
  setFocusMember,
  setShowActivateModal,
  showActivateModal,
  setShowDeleteMemberModal,
  showDeleteMemberModal,
  setShowManageMemberPane,
  setShowDeactivateModal,
  showManageMemberPane,
  showDeactivateModal,
}: DropDownActionsProps) => {
  const activated = role.organizations?.[0]?.activated;

  return (
    <DropdownMenu
      renderButton={<MoreIcon />}
      className="action-dropdown"
      id={`dropdown-menu-${index}`}
    >
      <DropdownOption
        onClick={() => {
          setFocusMember(role);
          setShowManageMemberPane(!showManageMemberPane);
        }}
      >
        Manage User
      </DropdownOption>

      <DropdownOption
        onClick={() => {
          setFocusMember(role);
          if (activated) {
            setShowDeactivateModal(!showDeactivateModal);
          } else {
            setShowActivateModal(!showActivateModal);
          }
        }}
      >
        {activated ? 'Deactivate User' : 'Activate User'}
      </DropdownOption>

      <DropdownOption
        onClick={() => {
          setShowDeleteMemberModal(!showDeleteMemberModal);
          setFocusMember(role);
        }}
      >
        Delete User
      </DropdownOption>
    </DropdownMenu>
  );
};

function TeamManagement() {
  const dispatch = useAppDispatch();
  const settingState: ISettingsState = useAppSelector(
    (state) => state.settings
  );
  const authState = useAppSelector((state) => state.auth);

  const [showInviteMemberPane, setShowInviteMemberPane] = useState(false);
  const [showManageMemberPane, setShowManageMemberPane] = useState(false);
  const [showActivateModal, setShowActivateModal] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [showDeleteMemberModal, setShowDeleteMemberModal] = useState(false);
  const [focusMember, setFocusMember] = useState<ITeamMember>();
  const { isDesktop } = useScreenSizeHook();

  useEffect(() => {
    dispatch(getTeamMembersAction());
  }, []);

  return (
    <div className="team-management-tab">
      {settingState.allTeamMemberState === 'loading' &&
      settingState.allTeamMembers.length === 0 ? (
        <PageLoading loadingMessage="Getting your Organization Members" />
      ) : isDesktop ? (
        <Table
          title="Team"
          tableHeader={['Member Name', 'Email Address', 'Role']}
          more={true}
          className="team-management-table"
          isEmpty={settingState.allTeamMembers.length === 0}
          emptyStateComponent={
            <EmptyTable
              message="No Member yet"
              additional_message="Your organization members will appear here"
            />
          }
          tableHeaderContent={
            <FillButton
              onClick={() => {
                setShowInviteMemberPane(!showInviteMemberPane);
              }}
            >
              Invite Member
            </FillButton>
          }
        >
          {settingState.allTeamMembers.map((role, index) => {
            const team_member_role_details = ADJUTOR_ROLES?.find((i) => {
              return i.id === role.role_id;
            });

            return (
              <TableRow
                more={true}
                key={role.id}
                moreOption={DropDownActions({
                  role,
                  index,
                  authState,
                  setFocusMember,
                  setShowActivateModal,
                  showActivateModal,
                  setShowDeleteMemberModal,
                  showDeleteMemberModal,
                  setShowManageMemberPane,
                  setShowDeactivateModal,
                  showManageMemberPane,
                  showDeactivateModal,
                })}
              >
                <td>{role.full_name}</td>

                <td>{role.email}</td>

                <td>{team_member_role_details?.name}</td>
              </TableRow>
            );
          })}
        </Table>
      ) : (
        <MobileTable
          title="Team"
          more={true}
          className="team-management-table"
          isEmpty={settingState.allTeamMembers.length === 0}
          emptyStateComponent={
            <EmptyTable
              message="No Members yet"
              additional_message="Your organization Members will appear here"
            />
          }
          tableHeaderContent={
            <FillButton
              onClick={() => {
                setShowInviteMemberPane(!showInviteMemberPane);
              }}
            >
              Invite Member
            </FillButton>
          }
        >
          {settingState.allTeamMembers.map((role, index) => {
            const team_member_role_details = ADJUTOR_ROLES?.find((i) => {
              return i.id === (role.role_id || 3);
            });

            return (
              <MobileTableRow
                key={role.id}
                moreOption={DropDownActions({
                  role,
                  index,
                  authState,
                  setFocusMember,
                  setShowActivateModal,
                  showActivateModal,
                  setShowDeleteMemberModal,
                  showDeleteMemberModal,
                  setShowManageMemberPane,
                  setShowDeactivateModal,
                  showManageMemberPane,
                  showDeactivateModal,
                })}
              >
                <div className="field-container">
                  <span className="field-title">Member Name: </span>
                  <span className="document-type-field">{role.full_name}</span>
                </div>

                <div className="field-container">
                  <span className="field-title">Email Address: </span>
                  {role.email}
                </div>

                <div className="field-container">
                  <span className="field-title">Role: </span>
                  {team_member_role_details?.name}
                </div>
              </MobileTableRow>
            );
          })}
        </MobileTable>
      )}

      <InviteMemberPane
        isOpen={showInviteMemberPane}
        onClose={() => {
          setShowInviteMemberPane(!showInviteMemberPane);
        }}
      />

      {focusMember && (
        <>
          <ManageMemberPane
            isOpen={showManageMemberPane}
            onClose={() => {
              setShowManageMemberPane(!showManageMemberPane);
            }}
            member={focusMember}
          />

          <NoticeModal
            title="Activate Member"
            showNoticeModal={
              showActivateModal && settingState.allTeamMemberState !== 'success'
            }
            setShowNoticeModal={setShowActivateModal}
            message={
              <>
                Are you sure you want to activate{' '}
                <b>{focusMember?.full_name || focusMember?.email}</b>
              </>
            }
            actionButtons={
              <ModalFooter>
                <FillButton
                  className="close-button"
                  onClick={() => {
                    setShowActivateModal(!showActivateModal);
                  }}
                  colorVariant={ButtonColor.neutral}
                  variant="outlined"
                  disabled={settingState.allTeamMemberState === 'updating'}
                  label="Cancel"
                />

                <FillButton
                  onClick={() => {
                    dispatch(activateTeamMembersAction(focusMember?.id))
                      .unwrap()
                      .then(() => {
                        setShowActivateModal(!showActivateModal);
                        toast.success('User has been successfully activated', {
                          position: 'bottom-center',
                        });
                      });
                  }}
                  colorVariant={ButtonColor.primary}
                  isLoading={settingState.allTeamMemberState === 'updating'}
                  label="Yes, Activate"
                />
              </ModalFooter>
            }
          />

          <NoticeModal
            title="Deactivate Member"
            showNoticeModal={
              showDeactivateModal &&
              settingState.allTeamMemberState !== 'success'
            }
            setShowNoticeModal={setShowDeactivateModal}
            message={
              <>
                Are you sure you want to deactivate{' '}
                <b>{focusMember?.full_name || focusMember?.email}</b>
              </>
            }
            actionButtons={
              <ModalFooter>
                <FillButton
                  onClick={() => {
                    setShowDeactivateModal(!showDeactivateModal);
                  }}
                  colorVariant={ButtonColor.neutral}
                  variant="outlined"
                  className="close-button"
                  disabled={settingState.allTeamMemberState === 'updating'}
                  label="Cancel"
                />

                <FillButton
                  onClick={() => {
                    dispatch(deactivateTeamMembersAction(focusMember?.id))
                      .unwrap()
                      .then(() => {
                        setShowDeactivateModal(!showDeactivateModal);
                        toast.success(
                          'User has been successfully deactivated',
                          {
                            position: 'bottom-center',
                          }
                        );
                      });
                  }}
                  colorVariant={ButtonColor.danger}
                  isLoading={settingState.allTeamMemberState === 'updating'}
                  label="Yes, Deactivate"
                />
              </ModalFooter>
            }
          />

          <NoticeModal
            title="Delete Member"
            showNoticeModal={showDeleteMemberModal}
            setShowNoticeModal={setShowDeleteMemberModal}
            message={
              <>
                Are you sure you want to delete{' '}
                <b>{focusMember?.full_name || focusMember?.email}</b>
              </>
            }
            actionButtons={
              <ModalFooter>
                <FillButton
                  onClick={() => {
                    setShowDeleteMemberModal(!showDeleteMemberModal);
                  }}
                  colorVariant={ButtonColor.neutral}
                  variant="outlined"
                  disabled={settingState.allTeamMemberState === 'updating'}
                  className="close-button"
                  label="Cancel"
                />

                <FillButton
                  onClick={() => {
                    dispatch(deleteTeamMembersAction(focusMember?.id))
                      .unwrap()
                      .then(() => {
                        setShowDeleteMemberModal(!showDeleteMemberModal);
                        toast.success('User has been successfully deleted', {
                          position: 'bottom-center',
                        });
                      });
                  }}
                  colorVariant={ButtonColor.danger}
                  isLoading={settingState.allTeamMemberState === 'updating'}
                  label="Yes, Delete"
                />
              </ModalFooter>
            }
          />
        </>
      )}
    </div>
  );
}

export default TeamManagement;
