import { IAuditsLogType } from '../store/audits/audits.interface';

export const CalculateDashboardAnalytics = (
  data: IAuditsLogType[],
  dateFilter: number
) => {
  const today = new Date();

  let filteredDate = new Date(today);
  filteredDate.setDate(today.getDate() - dateFilter);

  filteredDate.setHours(0, 0, 0, 0);

  // TotalCallMade
  const TotalCallMade =
    data.filter((i: IAuditsLogType) => {
      return new Date(i.created_on) >= filteredDate;
    }) ?? null;

  // TotalCallsWithError
  const TotalCallsWithError =
    data.filter((i: IAuditsLogType) => {
      return (
        new Date(i.created_on) >= filteredDate && Number(i.status_code) > 299
      );
    }) ?? null;

  // AveResponseTime
  let TotalResponseTime = 0;
  TotalCallMade?.forEach((i: IAuditsLogType) => {
    return (TotalResponseTime = TotalResponseTime + i?.response_time || 0);
  });

  const average = TotalResponseTime / TotalCallMade?.length;
  const AveResponseTime = average || 0;

  return {
    TotalCallMade,
    TotalCallsWithError,
    AveResponseTime,
  };
};
