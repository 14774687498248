import React from 'react';
import FillButton from '../buttons/button';
import { ReactComponent as ArrowHead } from '../../../assets/icons/arrowhead-right.svg';
import { Link, useParams } from 'react-router-dom';
import styles from './_breadcrumb.module.scss'

export type BreadcrumbsProps = {
  breadcrumb: {
    title: string;
    path: string;
  }[];
};

function Breadcrumbs({ breadcrumb }: Readonly<BreadcrumbsProps>) {
  const params: any = useParams();

  return (
    <div className={styles.breadcrumb_container}>
      {breadcrumb.map((step, idx) => {
        const arr: string[] = [];
        let useParamValue = false;
        step.path.split('/').forEach((path) => {
          if (path.includes(`:`)) {
            arr.push(params[path.replace(':', '')]);
            useParamValue = true;
          } else {
            arr.push(path);
          }
        });

        if (breadcrumb.length - 1 === idx) {
          return (
            <span
              key={`${step.title}_${idx}`}
              className="sm font-500"
            >
              {useParamValue ? arr[arr.length - 1] : step.title}
            </span>
          );
        }
        return (
          <Link key={`${step.title}_${idx}`} to={step.path}>
            <FillButton
              variant="ghost"
              label={step.title}
              iconPosition="right"
              icon={<ArrowHead />}
            />
          </Link>
        );
      })}
    </div>
  );
}

export default Breadcrumbs;
