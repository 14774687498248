import React, { useLayoutEffect } from 'react';
import './_authLayout.scss';
import { scrollToTop } from '../../utils/helper';
import { Link, useLocation } from 'react-router-dom';

//illustration
import AdjutorLogo from '../../assets/brand/full-lendsqr-logo.svg';
import AdjutorLogoDark from '../../assets/brand/dark-full-lendsqr-logo.svg';
import { ReactComponent as BackgroundMesh } from '../../assets/illustrations/background-mesh.svg';
import { ReactComponent as CheckListIcon } from '../../assets/illustrations/auth-checkbox.svg';
import HolderImage from '../../assets/illustrations/holder.png';
import urls, { externalRoutes } from '../../routes/all-routes';
import ExternalLink from '../../components/common/text/external-link';

export interface AuthLayoutProps {
  illustration?: string;
  children: React.ReactNode;
  banner_type?: 'listed' | 'not_listed';
  long_child?: boolean;
}

const AuthLayout = (props: AuthLayoutProps) => {
  const {
    illustration,
    children,
    banner_type = 'not_listed',
    long_child = false,
  } = props;
  const location = useLocation();

  useLayoutEffect(() => {
    scrollToTop();
  }, [location.pathname]);

  return (
    <div className="auth-layout">
      {banner_type === 'not_listed' && (
        <div className="svg-section-1">
          <a href={externalRoutes.adjutor.index}>
            <div className="logo">
              <img src={AdjutorLogo} alt="ADJUTOR" />
            </div>
          </a>

          <div className="illustration-container">
            <div className="illustration-content">
              <h3 className="heading">
                Supercharge your <br /> growth with Adjutor’s <br /> lending
                APIs
              </h3>
            </div>

            <img
              src={illustration ?? HolderImage}
              className="illustration"
              alt="Sign In To Lendsqr"
            />
          </div>
        </div>
      )}

      {banner_type === 'listed' && (
        <div className="svg-section-1">
          <Link to={urls.login.index}>
            <div className="logo">
              <img src={AdjutorLogo} alt="ADJUTOR" />
            </div>
          </Link>

          <div className="background-mesh">
            <BackgroundMesh />
          </div>

          <div className="illustration-container">
            <div className="illustration-content">
              <h3 className="heading">
                Get the APIs to power <br />
                your services.
              </h3>

              <div className="message-list-container">
                <p className="message-list">By creating an account, you can:</p>

                <ul>
                  <li className="message-list">
                    <div>
                      <CheckListIcon />
                    </div>
                    <div>Verify your customers</div>
                  </li>
                  <li className="message-list">
                    <div>
                      <CheckListIcon />
                    </div>
                    <div>
                      Embed loan capabilities into any of your apps or services
                    </div>
                  </li>
                  <li className="message-list">
                    <div>
                      <CheckListIcon />
                    </div>
                    <div>Get paid seamlessly with direct debit</div>
                  </li>
                </ul>
              </div>
            </div>

            <img
              src={illustration ?? HolderImage}
              className="illustration"
              alt="Sign In To Lendsqr"
            />
          </div>
        </div>
      )}

      <div className="form-section-container">
        <div className="logo">
          <img src={AdjutorLogoDark} alt="ADJUTOR" />
        </div>

        <div
          className={`main-content ${long_child ? 'main-content-long' : ''}`}
          id="hide-flow-tag"
        >
          <div className="form-section">{children}</div>
        </div>

        <div className="action-row">
          <ExternalLink
            href={externalRoutes.docs.index}
            target="_blank"
            rel="noreferrer noopenner"
            label="Developer Documentation"
          />
          <ExternalLink
            href={externalRoutes.docs.api_reference}
            target="_blank"
            rel="noreferrer noopenner"
            label="API References"
          />
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
