import React, { useState } from 'react';
import '../../business/apps/_create-app.scss';
import { useAppDispatch, useAppSelector } from '../../../store/customHooks';

//form
import { useFormik } from 'formik';

//components
import Input from '../../../components/input-fields/input/input';
import SlidingPane from '../../../components/slidingpane/slidingpane';
import FillButton, {
  ButtonColor,
} from '../../../components/common/buttons/button';

//utils
import { IEditAccountDetails } from './edit-account.interface';
import { updateCompanyDetails } from './edit-account-details-schema';
import { editOrgProfileAction } from '../../../store/account/account.actions';
import { resetUpdatingAccount } from '../../../store/account/account.reducer';
import ModalFooter from '../../../components/common/modal/modal-footer';
import SuccessModal from '../../../components/notice-modal/success-modal';

export interface EditCompanyProps {
  isOpen: boolean;
  onClose: Function;
  initialValues: IEditAccountDetails;
}

function EditCompanyPane(props: EditCompanyProps) {
  const { isOpen, onClose, initialValues } = props;
  const dispatch = useAppDispatch();
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const accountState = useAppSelector((state) => state.account);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: initialValues?.name,
      support_phone: initialValues?.support_phone ?? '',
      primary_email: initialValues?.primary_email ?? '',
      support_email: initialValues?.support_email ?? '',
      address: initialValues?.address ?? '',
      website_url: initialValues?.website_url ?? '',
      facebook_url: initialValues?.facebook_url ?? '',
      instagram_url: initialValues?.instagram_url ?? '',
      twitter_url: initialValues?.twitter_url ?? '',
      privacy_url: initialValues?.privacy_url ?? '',
      terms_url: initialValues?.terms_url ?? '',
    },

    validationSchema: updateCompanyDetails,

    onSubmit: async (values) => {
      dispatch(editOrgProfileAction(values))
        .unwrap()
        .then(() => {
          handlePaneClose();
          setUpdateSuccess(true);
        });
    },
  });

  const handlePaneClose = () => {
    onClose();
    dispatch(resetUpdatingAccount());
  };

  const handleCloseSuccess = () => {
    dispatch(resetUpdatingAccount());
    setUpdateSuccess(false);
  };

  return (
    <>
      <SlidingPane
        isOpen={isOpen}
        title="Edit Company Details"
        onClose={handlePaneClose}
        footer={
          <ModalFooter>
            <React.Fragment>
              <FillButton
                variant="outlined"
                onClick={() => {
                  handlePaneClose();
                }}
                className="close-button"
                colorVariant={ButtonColor.neutral}
                label="Close"
              />

              <FillButton
                type="submit"
                onClick={formik.submitForm}
                isLoading={accountState.isUpdatingAccount === 'loading'}
                disabled={!(formik.isValid && formik.dirty)}
                label="Save Details"
                loadingText="Saving"
              />
            </React.Fragment>
          </ModalFooter>
        }
      >
        <form className="create-app-form" onSubmit={formik.handleSubmit}>
          <div className="form-section">
            <Input
              type="text"
              label="Name"
              name="name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              error={formik.touched.name && formik.errors.name}
              errorMessage={formik.errors.name}
              disabled
            />

            <Input
              type="text"
              label="Support Phone Number"
              name="support_phone"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.support_phone}
              error={
                formik.touched.support_phone && formik.errors.support_phone
              }
              errorMessage={formik.errors.support_phone}
            />

            <Input
              type="email"
              label="Support Email"
              name="support_email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.support_email}
              error={
                formik.touched.support_email && formik.errors.support_email
              }
              errorMessage={formik.errors.support_email}
            />

            <Input
              type="email"
              label="Primary Email"
              name="primary_email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.primary_email}
              error={
                formik.touched.primary_email && formik.errors.primary_email
              }
              errorMessage={formik.errors.primary_email}
            />

            <Input
              type="text"
              label="Address"
              name="address"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.address}
              error={formik.touched.address && formik.errors.address}
              errorMessage={formik.errors.address}
            />

            <Input
              type="text"
              label="Website"
              name="website_url"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.website_url}
              error={formik.touched.website_url && formik.errors.website_url}
              errorMessage={formik.errors.website_url}
            />
          </div>
        </form>
      </SlidingPane>

      <SuccessModal
        isOpen={updateSuccess}
        onClose={handleCloseSuccess}
        continueAction={handleCloseSuccess}
        title="Success"
        message="Updated successfully"
        description="Your Organization's Details Have Been Updated"
      />
    </>
  );
}

export default EditCompanyPane;
