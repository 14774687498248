import * as Sentry from '@sentry/react';

Sentry.init({
  dsn:
    process.env.NODE_ENV === 'development'
      ? undefined
      : process.env.REACT_APP_SENTRY_KEY,
  environment: process.env.NODE_ENV || 'development',
  debug: process.env.NODE_ENV === 'development',

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control

  tracesSampleRate: 0.5,
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay({
      // Additional SDK configuration goes in here, for example:

      maskAllInputs: true,
    }),
  ],
});

export default Sentry;