import React, { useState } from 'react';
import './_role-management.scss';
import { useNavigate } from 'react-router-dom';
import { ADJUTOR_ROLES } from '../../../utils/hooks/permission-maps';

import Table from '../../../components/table/table';
import TableRow from '../../../components/table/tablebody';
import DropdownMenu from '../../../components/common/dropdown/dropdown-menu';
import DropdownOption from '../../../components/common/dropdown/dropdown-option';

//icons
import { ReactComponent as MoreIcon } from '../../../assets/icons/more.svg';
import MobileTable from '../../../components/table/mobile-table/mobile-table';
import MobileTableRow from '../../../components/table/mobile-table/mobile-tablebody';
import urls from '../../../routes/all-routes';
import EmptyTable from '../../../components/empty-table/EmptyTable';
import ViewRole from './view-role/view-role';
import useScreenSizeHook from '../../../hooks/useScreenSizeHook';

function RoleManagement() {
  const navigate = useNavigate();
  const [showViewRolePane, setShowViewRolePane] = useState({
    show: false,
    role_id: 0,
  });
  const { isDesktop } = useScreenSizeHook();

  const viewDetails = (id: number) => {
    navigate(urls.settings.role_management.details.for(`${id}`));
    setShowViewRolePane({
      role_id: id,
      show: true,
    });
  };

  return (
    <div className="team-management-tab">
      {isDesktop ? (
        <Table
          title="Roles"
          tableHeader={['Role Type', 'Description', 'Permission']}
          more={true}
          className="team-management-table"
          isEmpty={ADJUTOR_ROLES.length === 0}
          emptyStateComponent={
            <EmptyTable
              message="No Roles yet"
              additional_message="Your organization roles will appear here"
            />
          }
        >
          {ADJUTOR_ROLES.map((role, index) => {
            return (
              <TableRow
                more={true}
                key={role.id}
                moreOption={
                  <DropdownMenu
                    renderButton={<MoreIcon />}
                    className="action-dropdown"
                    id={`dropdown-menu-${index}`}
                  >
                    <DropdownOption
                      onClick={() => {
                        viewDetails(role.id);
                      }}
                    >
                      View Details
                    </DropdownOption>
                  </DropdownMenu>
                }
                onRowClickAction={() => {
                  viewDetails(role.id);
                }}
              >
                <td>
                  <span className="role-field">{role.name.toLowerCase()}</span>
                </td>

                <td className="role-field">{role.description}</td>

                <td className="permissions-field">
                  {role.permissions?.length}
                </td>
              </TableRow>
            );
          })}
        </Table>
      ) : (
        <MobileTable
          title="Roles"
          more={true}
          className="team-management-table"
          isEmpty={ADJUTOR_ROLES.length === 0}
          emptyStateComponent={
            <EmptyTable
              message="No Roles yet"
              additional_message="Your organization roles will appear here"
            />
          }
        >
          {ADJUTOR_ROLES.map((role, index) => {
            return (
              <MobileTableRow
                key={role.id}
                moreOption={
                  <DropdownMenu
                    renderButton={<MoreIcon />}
                    className="action-dropdown"
                    id={`dropdown-menu-${index}`}
                  >
                    <DropdownOption
                      onClick={() => {
                        viewDetails(role.id);
                      }}
                    >
                      View Details
                    </DropdownOption>
                  </DropdownMenu>
                }
                onRowClickAction={() => {
                  viewDetails(role.id);
                }}
              >
                <div className="field-container">
                  <span className="field-title">Role Type: </span>
                  <span className="role-field">{role.name}</span>
                </div>

                <div className="field-container">
                  <span className="field-title">Description: </span>
                  <span className="role-field">{role.description}</span>
                </div>

                <div className="field-container permissions-field">
                  <span className="field-title">Permissions: </span>
                  {role.permissions?.length}
                </div>
              </MobileTableRow>
            );
          })}
        </MobileTable>
      )}

      <ViewRole
        role_id={showViewRolePane.role_id}
        isOpen={showViewRolePane.show}
        onClose={() => {
          setShowViewRolePane({
            role_id: 0,
            show: false,
          });
        }}
      />
    </div>
  );
}

export default RoleManagement;
