import React, { ReactElement } from 'react';
import Card from '../cards/cards';
import './_modal.scss';
import CloseButton from '../buttons/close-button';

export enum ModalPosition {
  center = 'modal-center',
  top_center = 'modal-top-center',
}
export enum ModalSize {
  md = 'size-medium',
  lg = 'size-large',
}

export interface ModalProps {
  children: React.ReactNode;
  className?: string;
  contentClassName?: string;
  title?: string;
  isOpen: boolean;
  onClose: Function;
  showCloseIcon?: boolean;
  modalPosition?: string;
  modalSize?: string;
  modalFooter?: ReactElement;
  animated?: boolean;
}

function Modal({
  showCloseIcon = true,
  modalPosition = ModalPosition.center,
  modalSize = ModalSize.lg,
  modalFooter,
  animated = true,
  ...props
}: Readonly<ModalProps>) {
  return (
    <div
      className={`modal-container ${props.className ? props.className : ''} ${
        props.isOpen ? 'open' : ''
      }`}
    >
      <div
        className="modal-backdrop"
        onClick={() => {
          props.onClose();
        }}
      />
      <Card
        className={`modal ${modalSize} ${modalPosition} ${
          animated ? 'animate' : ''
        }`}
      >
        <div className="modal-header">
          <div className="title">{props.title}</div>

          {showCloseIcon && <CloseButton onClick={() => props.onClose()} />}
        </div>

        <div className={`modal-body ${props?.contentClassName}`}>
          {props.children}
        </div>

        {modalFooter && <div className="modal-footer">{modalFooter}</div>}
      </Card>
    </div>
  );
}

export default Modal;
