import React from 'react';
import FillButton, {
  ButtonColor,
} from '../../../components/common/buttons/button';
import { useAppDispatch, useAppSelector } from '../../../store/customHooks';
import { createUserAccountAction } from '../../../store/onboarding/onboarding.actions';
import { ReactComponent as NewMessageIcon } from '../../../assets/icons/new-message.svg';
import { ISignUp } from '../../../store/onboarding/onboarding.interface';
import { trimObjectValues } from '../../../utils/hooks/cleanup';
import toast from 'react-hot-toast';

export interface CompleteSignupProps {
  savedData: Partial<ISignUp>;
  stepNo: number;
}

export default function CompletedSignUp(_props: CompleteSignupProps) {
  const dispatch = useAppDispatch();
  const {user, isLoading} = useAppSelector((state) => state.auth);

  return (
    <div>
      <div>
        <div className="auth-page-heading-icon">
          <NewMessageIcon />
        </div>

        <h6>Confirm your email address</h6>

        <p className="sm">
          We’ve sent a validation email to{' '}
          {<strong>{user.email}</strong> || 'your email'}. It may take a few
          minutes before you get the validation email. <br />
          <br />
          Kindly access the email to proceed with your account setup. If you
          can’t find the email, kindly check your spam folder.
        </p>
      </div>

      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <FillButton
          className="resend-email-btn"
          type="button"
          onClick={() => {
            dispatch(
              createUserAccountAction(trimObjectValues(_props.savedData))
            )
              .unwrap()
              .then(() => {
                toast.success('Email Sent')
              });
          }}
          variant="outlined"
          label="Resend email"
          colorVariant={ButtonColor.neutral}
          isLoading={isLoading}
          loadingText='Resending'
        />
      </form>
    </div>
  );
}
