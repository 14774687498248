import { IAllAppsTypes, IAppsState } from "./apps.interface";
import { createSlice } from "@reduxjs/toolkit";
import {
  getAllAppsAction,
  getSingleAppAction,
  createAppAction,
  editAppAction,
  deleteAppAction,
  resetAppTokenAction,
} from "./apps.actions";
import { eventTaggingAction } from "../utility/utility.actions";

const initalFocusAppState: IAllAppsTypes = {
  name: "",
  services: [],
  id: "",
  org_id: "",
  uuid: "",
  created_on: "",
};

const initialState = {
  allApps: [],
  focusApp: initalFocusAppState,
  isLoading: false,
  isSingleAppLoading: false,
  error: "",
  createAppStatus: "idle",
  deleteAppStatus: "idle",
  appTokenStatus: "idle",
  totalAppsSize: 0,
} as IAppsState;

const AppSlice = createSlice({
  name: "AppState",
  initialState,

  reducers: {
    resetAppState: (state: IAppsState) => {
      state.error = "";
      state.createAppStatus = "";
      state.appTokenStatus = "idle";
    },
  },

  extraReducers: (builder) => {
    //All APPS Logs
    builder.addCase(getAllAppsAction.pending, (state: IAppsState) => {
      state.isLoading = true;
    });
    builder.addCase(
      getAllAppsAction.fulfilled,
      (state: IAppsState, action: any) => {
        state.allApps = action.payload.apps;
        state.totalAppsSize = action.payload.page_info.total;
        state.isLoading = false;
      }
    );
    builder.addCase(
      getAllAppsAction.rejected,
      (state: IAppsState, action: any) => {
        state.error = action.payload;
        state.isLoading = false;
      }
    );

    //Single All APPS Logs
    builder.addCase(getSingleAppAction.pending, (state: IAppsState) => {
      state.isSingleAppLoading = true;
    });
    builder.addCase(
      getSingleAppAction.fulfilled,
      (state: IAppsState, action: any) => {
        state.focusApp = action.payload;
        state.isSingleAppLoading = false;
      }
    );
    builder.addCase(
      getSingleAppAction.rejected,
      (state: IAppsState, action: any) => {
        state.error = action.payload;
        state.isSingleAppLoading = false;
      }
    );

    //Create Single APP
    builder.addCase(createAppAction.pending, (state: IAppsState) => {
      state.createAppStatus = "loading";
    });
    builder.addCase(
      createAppAction.fulfilled,
      (state: IAppsState) => {
        state.createAppStatus = "success";
        eventTaggingAction({
          event_name: `App ${state.focusApp.id} key generated`,
          description: `Successfully generated app ${state.focusApp.id} key`,
        });
      }
    );
    builder.addCase(
      createAppAction.rejected,
      (state: IAppsState) => {
        state.createAppStatus = "error";
      }
    );

    //Edit Single APP
    builder.addCase(editAppAction.pending, (state: IAppsState) => {
      state.createAppStatus = "loading";
    });
    builder.addCase(
      editAppAction.fulfilled,
      (state: IAppsState, action: any) => {
        state.focusApp = action.payload;
        state.createAppStatus = "success";
      }
    );
    builder.addCase(
      editAppAction.rejected,
      (state: IAppsState, action: any) => {
        state.error = action.payload;
        state.createAppStatus = "error";
      }
    );

    //Delete Single APP
    builder.addCase(deleteAppAction.pending, (state: IAppsState) => {
      state.deleteAppStatus = "loading";
    });
    builder.addCase(
      deleteAppAction.fulfilled,
      (state: IAppsState) => {
        state.deleteAppStatus = "success";
      }
    );
    builder.addCase(
      deleteAppAction.rejected,
      (state: IAppsState, action: any) => {
        state.error = action.payload;
        state.deleteAppStatus = "error";
      }
    );

    //Reset App Token
    builder.addCase(resetAppTokenAction.pending, (state: IAppsState) => {
      state.appTokenStatus = "resetting";
    });
    builder.addCase(
      resetAppTokenAction.fulfilled,
      (state: IAppsState) => {
        state.appTokenStatus = "reset.success";

        eventTaggingAction({
          event_name: `App ${state.focusApp.id} key reset`,
          description: `Successfully reset app ${state.focusApp.id} key`,
        });
      }
    );
    builder.addCase(
      resetAppTokenAction.rejected,
      (state: IAppsState, action: any) => {
        state.error = action.payload;
        state.appTokenStatus = "error";
      }
    );
  },
});

export const { resetAppState } = AppSlice.actions;

export default AppSlice.reducer;
