import React, { useState } from 'react';
import Modal, { ModalPosition } from '../../common/modal/modal';
import {
  activateModelAction,
  deactivateModelAction,
} from '../../../store/decision-model/decision-model.actions';
import FillButton, { ButtonColor } from '../../common/buttons/button';
import { IDecisionModels } from '../../../store/decision-model/decision-model.interface';
import { useAppDispatch, useAppSelector } from '../../../store/customHooks';
import urls from '../../../routes/all-routes';
import { useNavigate } from 'react-router-dom';
import ModalFooter from '../../common/modal/modal-footer';

interface Props {
  modelStatus: string;
  modelId: number;
  showModal: boolean;
  setShowModal: (value: boolean) => void;
}

function ToggleModelStatusModal({
  modelId = 0,
  showModal,
  setShowModal,
  modelStatus,
}: Props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const decisionModel: IDecisionModels = useAppSelector(
    (state) => state.decisionModel
  );
  const [loading, setLoading] = useState(false);

  const toggleStatus = () => {
    setLoading(true);
    if (modelStatus === 'active') {
      dispatch(deactivateModelAction(modelId))
        .unwrap()
        .then(() => {
          modelStatus = 'inactive';
          navigate(urls.business.decision_model.index);
        })
        .catch((error: any) => {
          modelStatus = 'active';
          console.log(error.message);
        })
        .finally(() => {
          setLoading(false);
          setShowModal(false);
        });
      return;
    }

    dispatch(activateModelAction(modelId))
      .unwrap()
      .then(() => {
        modelStatus = 'active';
        navigate(urls.business.decision_model.index);
      })
      .catch((error: any) => {
        modelStatus = 'inactive';
        console.log(error.message);
      })
      .finally(() => {
        setLoading(false);
        setShowModal(false);
      });
  };

  return (
    <Modal
      isOpen={showModal}
      title={modelStatus === 'active' ? 'Deactivate Model' : 'Activate Model'}
      onClose={() => {
        setShowModal(!showModal);
      }}
      className="delete-app-modal"
      modalPosition={ModalPosition.center}
      modalFooter={
        <ModalFooter>
          <FillButton
            onClick={() => {
              setShowModal(!showModal);
            }}
            colorVariant={ButtonColor.secondary}
            variant="outlined"
            disabled={loading}
            className="close-button"
          >
            No, Cancel
          </FillButton>

          <FillButton
            onClick={() => {
              toggleStatus();
            }}
            colorVariant={
              modelStatus === 'active'
                ? ButtonColor.danger
                : ButtonColor.primary
            }
            isLoading={loading}
          >
            Yes, {modelStatus === 'active' ? 'Deactivate' : 'Activate'}
          </FillButton>
        </ModalFooter>
      }
    >
      <p>
        Are you sure you want to{' '}
        {modelStatus === 'active' ? 'Deactivate' : 'Activate'}{' '}
        <b>{decisionModel?.focusModel?.name}</b>
      </p>
    </Modal>
  );
}

export default ToggleModelStatusModal;
