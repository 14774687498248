import React, { useState } from 'react';
import './_create-role.scss';
import { ADJUTOR_ROLES } from '../../../utils/hooks/permission-maps';

//form
import { useFormik } from 'formik';
import { CreateRoleSchema } from './create-role-validation-schema';

//components
import Input from '../../../components/input-fields/input/input';
import SlidingPane from '../../../components/slidingpane/slidingpane';
import FillButton, {
  ButtonColor,
} from '../../../components/common/buttons/button';
import Select from '../../../components/input-fields/Select/select';
import { inviteTeamMembersAction } from '../../../store/settings/settings.actions';
import { useAppDispatch, useAppSelector } from '../../../store/customHooks';
import { ISettingsState } from '../../../store/settings/settings.interface';
import { resetInvitingTeamMember } from '../../../store/settings/settings.reducer';
import ModalFooter from '../../../components/common/modal/modal-footer';
import SuccessModal from '../../../components/notice-modal/success-modal';

export interface CreateRoleProps {
  isOpen: boolean;
  onClose: Function;
}

function InviteMemberPane(props: CreateRoleProps) {
  const { isOpen, onClose } = props;
  const [invitiationSuccess, setInvitiationSuccess] = useState(false);
  const dispatch = useAppDispatch();
  const settingState: ISettingsState = useAppSelector(
    (state) => state.settings
  );

  const formik = useFormik({
    initialValues: {
      email: '',
      role: '',
    },

    validationSchema: CreateRoleSchema,

    onSubmit: async (values) => {
      dispatch(
        inviteTeamMembersAction({
          email: values.email,
          role_id: values.role,
        })
      )
        .unwrap()
        .then(() => {
          onClose();
          setInvitiationSuccess(true);
        });
    },
  });

  const roleOptions = ADJUTOR_ROLES.map((role) => {
    return {
      label: role.name,
      value: role.id,
    };
  });

  const handleReset = () => {
    formik.resetForm();
    dispatch(resetInvitingTeamMember());
  };

  const handlePaneClose = () => {
    onClose();
    handleReset();
  };

  const handleCloseSuccess = () => {
    setInvitiationSuccess(false);
    handleReset();
  };

  const filterOptions = (id: number) => {
    return roleOptions?.find((i) => {
      return i.value === id;
    }) || null;
  };

  return (
    <>
      <SlidingPane
        isOpen={isOpen}
        title="Invite a member"
        onClose={handlePaneClose}
        footer={
          <ModalFooter>
            <FillButton
              variant="outlined"
              onClick={() => {
                handlePaneClose();
              }}
              className="close-button"
              colorVariant={ButtonColor.neutral}
              label="Close"
            />

            <FillButton
              type="submit"
              onClick={formik.submitForm}
              disabled={!(formik.isValid && formik.dirty)}
              isLoading={settingState.allTeamMemberState === 'updating'}
              label="Send Invite"
              loadingText="Sending"
            />
          </ModalFooter>
        }
      >
        <form className="create-role-form" onSubmit={formik.handleSubmit}>
          <div className="form-section">
            <Input
              type="email"
              label="Email Address"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              error={formik.touched.email && formik.errors.email}
              errorMessage={formik.errors.email}
            />

            <Select
              label="Select Role"
              selectPlaceholder="Assign Role"
              name="role"
              selectOptions={roleOptions}
              onChange={(val: any) => {
                formik.setFieldValue('role', val.value);
              }}
              value={filterOptions(Number(formik.values.role))}
              error={formik.touched.role && formik.errors.role}
              errorMessage={formik.errors.role}
            />
          </div>
        </form>
      </SlidingPane>

      <SuccessModal
        isOpen={invitiationSuccess}
        onClose={handleCloseSuccess}
        continueAction={handleCloseSuccess}
        title="Success"
        message="Invite sent successfully"
        description={`An invite has been sent to ${formik.values.email}`}
      />
    </>
  );
}

export default InviteMemberPane;
