import { createAsyncThunk } from '@reduxjs/toolkit';
import { callApi } from '../../config/apiSetup';
import {
  adjutorAppTokenUrl,
  organizationAppsUrl,
  postUploadPhotoUrl,
} from '../../config/allUrl';
import { RootState } from '../store';
import { ICreateApp } from './apps.interface';
import { toast } from 'react-hot-toast';
import { defaultErrorMessage } from '../../utils/helper';
import { getAuditsAction } from '../audits/audits.actions';

export const getAllAppsAction = createAsyncThunk(
  'business/getAllAppsAction',
  async (pageDetails: { page: number; limit: number }, ThunkApi) => {
    try {
      const res = await callApi.get(
        `${organizationAppsUrl}?page=${pageDetails.page}&limit=${pageDetails.limit}`
      );
      return res.data;
    } catch (error: any) {
      const message: string = error.response.data?.message || error?.message;
      toast.error(message ?? defaultErrorMessage);
      return ThunkApi.rejectWithValue(message);
    }
  }
);

export const getSingleAppAction = createAsyncThunk(
  'business/getSingleAppAction',
  async (app_id: string | number, ThunkApi) => {
    try {
      const res = await callApi.get(`${organizationAppsUrl}/${app_id}`);
      return res.data;
    } catch (error: any) {
      const message: string = error.response.data?.message || error?.message;
      toast.error(message ?? defaultErrorMessage);
      return ThunkApi.rejectWithValue(message);
    }
  }
);

export const getSingleAppLogsAction = createAsyncThunk(
  'business/getSingleAppLogsAction',
  async (
    payload: {
      app_id: number;
      page: number;
      limit: number;
      [filter: string]: any;
    },
    ThunkApi
  ) => {
    try {
      const res = await ThunkApi.dispatch(
        getAuditsAction({
          ...payload,
          app_id: payload.app_id,
          page: payload.page,
          limit: payload.limit,
        })
      )
        .unwrap()
        .then((data) => {
          return data;
        })
        .catch((error) => {
          return error;
        });

      return res;
    } catch (error: any) {
      const message: string = error.response.data?.message || error?.message;
      toast.error(message ?? defaultErrorMessage);
      return ThunkApi.rejectWithValue(message);
    }
  }
);

export const resetAppTokenAction = createAsyncThunk(
  'business/resetAppTokenAction',
  async (app_id: string | number, ThunkApi) => {
    try {
      const res = await callApi.postWithReturnHeaders(
        `${adjutorAppTokenUrl}?app_id=${app_id}`
      );

      if (res?.data && res.headers['x-api-key']) {
        navigator.clipboard
          .writeText(`${res.headers['x-api-key']}`)
          .then(() => {})
          .catch(() => {
            console.log('error copying app key');
          });
        return res.headers['x-api-key'];
      }
      return res?.data;
    } catch (error: any) {
      const message: string = error.response.data?.message || error?.message;
      toast.error(message ?? defaultErrorMessage);
      return ThunkApi.rejectWithValue(message);
    }
  }
);

export const createAppAction = createAsyncThunk<
  any,
  ICreateApp,
  { state: RootState }
>('business/createAppAction', async (values: ICreateApp, ThunkApi) => {
  const { auth } = ThunkApi.getState();
  const org_id = auth.org_id;

  let formData: any = new FormData();
  formData.append('file', values.logo_url);

  let config = {
    onUploadProgress: function () {
      console.log('uploading document...');
    },
  };

  if (values.webhook_url === '') {
    delete values.webhook_url;
  }
  if (values.callback_url === '') {
    delete values.callback_url;
  }
  if (!values.logo_url) {
    delete values.logo_url;
  } else if (typeof values.logo_url !== 'string') {
    const postRes: any = await callApi.post(
      postUploadPhotoUrl,
      formData,
      config
    );
    values.logo_url = postRes.data.url;
  }
  try {
    const res = await callApi.postWithReturnHeaders(
      `${organizationAppsUrl}?org_id=${org_id}`,
      values
    );
    if (res?.data && res.headers['x-api-key']) {
      await navigator.clipboard
        .writeText(`${res.headers['x-api-key']}`)
        .then(() => {})
        .catch(() => {
          console.log('error copying app key');
        });
    }
    ThunkApi.dispatch(getAllAppsAction({ page: 0, limit: 10 }));
    return { key: res?.headers['x-api-key'], data: res?.data?.data };
  } catch (error: any) {
    const message: string = error.response.data?.message || error?.message;
    toast.error(message ?? defaultErrorMessage);
    return ThunkApi.rejectWithValue(message);
  }
});

export const editAppAction = createAsyncThunk(
  'business/editAppAction',
  async (data: { app_id: string | number; values: ICreateApp }, ThunkApi) => {
    let formData: any = new FormData();
    formData.append('file', data.values.logo_url);

    let config = {
      onUploadProgress: function () {
        console.log('uploading document...');
      },
    };

    if (data.values.webhook_url === '') {
      delete data.values.webhook_url;
    }
    if (data.values.callback_url === '') {
      delete data.values.callback_url;
    }
    if (!data.values.logo_url) {
      data.values.logo_url = null;
    } else if (typeof data.values.logo_url !== 'string') {
      const postRes: any = await callApi.post(
        postUploadPhotoUrl,
        formData,
        config
      );
      data.values.logo_url = postRes.data.url;
    }

    try {
      const res = await callApi.patch(
        `${organizationAppsUrl}/${data.app_id}`,
        data.values
      );
      return res.data;
    } catch (error: any) {
      const message: string = error?.response?.data?.message;
      toast.error(message ?? defaultErrorMessage);
      return ThunkApi.rejectWithValue(message);
    }
  }
);

export const deleteAppAction = createAsyncThunk(
  'business/deleteAppAction',
  async (app_id: string | number, ThunkApi) => {
    try {
      await callApi.delete(`${organizationAppsUrl}/${app_id}`);
      toast.success('App deleted successfully', {
        position: 'bottom-center',
      });
      ThunkApi.dispatch(getAllAppsAction({ page: 0, limit: 10 }));
    } catch (error: any) {
      const message: string = error.response.data?.message || error?.message;
      toast.error(message ?? defaultErrorMessage);
      return ThunkApi.rejectWithValue(message);
    }
  }
);
