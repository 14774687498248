import React, { useEffect } from 'react';
import './_slidingpane.scss';
import CloseButton from '../common/buttons/close-button';

export interface SlidepaneProps {
  children: React.ReactNode;
  className?: string;
  bodyClassName?: string;
  title: string;
  isOpen: boolean;
  onClose: Function;
  footer?: React.ReactElement;
}

export default function SlidingPane(props: SlidepaneProps) {
  useEffect(() => {
    const comp1 = document.getElementById('hide-flow-tag');
    const comp2 = document.getElementById('hide-flow-tag-1');

    if (props.isOpen) {
      document.body.classList.add('hide-flow');
      comp1?.classList.add('hide-flow');
      comp2?.classList.add('hide-flow');
    } else {
      document.body.classList.remove('hide-flow');
      comp1?.classList.remove('hide-flow');
      comp2?.classList.remove('hide-flow');
    }
  }, [props.isOpen]);

  return (
    <div
      className={`slidepane-container ${
        props.className ? props.className : ''
      } ${props.isOpen === true ? 'open' : ''}`}
      data-testid="sliding-pane"
    >
      <div
        className="slidepane-backdrop"
        data-testid="sliding-pane-close-btn"
        onClick={() => {
          props.onClose();
        }}
      />
      <div className="slidepane">
        <div className="slidepane-header">
          <div className="title font-600 text-text-primary lead">
            {props.title}
          </div>

          <CloseButton onClick={props.onClose} />
        </div>

        <div className={`slidepane-body ${props?.bodyClassName ?? ''}`}>
          {props.children}
        </div>

        {props.footer && <div className="slidepane-footer">{props.footer}</div>}
      </div>
    </div>
  );
}
