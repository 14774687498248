import React from 'react';
import './_chip.scss';

export enum DEFAULT_CHIP_STATUS {
  yellow = 'YELLOW',
  neutral = 'NEUTRAL',
  blue = 'BLUE',
  green = 'GREEN',
  red = 'RED',
  orange = 'ORANGE',
  success = 'SUCCESS',
  error = 'ERROR',
  warning = 'WARNING',
}

export interface ChipProps {
  className?: string;
  label?: string | number;
  indicatior?: boolean;
  transparentBackground?: boolean;
  status?: DEFAULT_CHIP_STATUS;
  icon?: React.ReactElement;
  [rest: string]: any;
}

export default function Chip({
  transparentBackground = false,
  className,
  indicatior,
  label,
  status = DEFAULT_CHIP_STATUS.neutral,
  icon,
  ...rest
}: ChipProps) {
  return (
    <div
      data-status={status}
      {...rest}
      className={`${className ?? ''} ${
        (!label || transparentBackground) && 'no-background'
      } chip sm`}
    >
      {indicatior && <span className="indicator" />}
      <div className="label">{label}</div>
      {icon && <div>{icon}</div>}
    </div>
  );
}
