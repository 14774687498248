import React, { useState, useCallback } from 'react';
import './_api-logs.scss';

import Table from '../../../../components/table/table';
import TableRow from '../../../../components/table/tablebody';
import DropdownMenu from '../../../../components/common/dropdown/dropdown-menu';
import DropdownOption from '../../../../components/common/dropdown/dropdown-option';

//icons
import { ReactComponent as MoreIcon } from '../../../../assets/icons/more.svg';

import Chip from '../../../../components/common/chip/chip';
import MobileTable from '../../../../components/table/mobile-table/mobile-table';
import MobileTableRow from '../../../../components/table/mobile-table/mobile-tablebody';
import { useAppDispatch, useAppSelector } from '../../../../store/customHooks';
import { getAuditsAction } from '../../../../store/audits/audits.actions';
import {
  currencyFormat,
  fullDateFormat,
  truncateString,
} from '../../../../utils/formatter';
import Pagination from '../../../../components/common/pagination/Pagination';
import Card from '../../../../components/common/cards/cards';
import { useNavigate } from 'react-router-dom';
import urls from '../../../../routes/all-routes';
import EmptyTable from '../../../../components/empty-table/EmptyTable';
import CardFooter from '../../../../components/common/cards/card-footer';
import { requestColorMatch } from '../../../../utils/helper';
import useFilterHook from '../../../../hooks/useFilterHook';
import useScreenSizeHook from '../../../../hooks/useScreenSizeHook';
import ApiLogsFilterModal from '../api-logs-filter';

function ApiLogs() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const auditState = useAppSelector((state) => state.audit);

  const { isDesktop } = useScreenSizeHook();

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const initialFilter = {
    page: currentPage,
    limit: perPage,
  };

  const { filter, handleFilter, setFilter, resetFilter } = useFilterHook({ initialFilter });

  const fetchAudits = useCallback(async () => {
    await dispatch(
      getAuditsAction({
        ...filter,
        page: currentPage,
        limit: perPage,
      })
    );
  }, [perPage, currentPage]);

  const loadPage = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  const viewDetails = (id: string) => {
    navigate(urls.business.audits_trails.audit.for(`${id}`));
  };

  return (
    <div className="api-log-table">
      <Card>
        {isDesktop ? (
          <Table
            tableHeader={['Date', 'Status', 'Method', 'URL', 'AMOUNT (NGN)']}
            title="API Logs"
            // filter={<FilterModal />}
            more={true}
            isLoading={
              auditState.auditState === 'loading' &&
              auditState.auditsLog.length === 0
            }
            isEmpty={auditState.auditsLog.length === 0}
            emptyStateComponent={
              <EmptyTable
                message="No API calls yet"
                additional_message="Your APIS calls will appear here"
              />
            }
            filter={
              <ApiLogsFilterModal
                filter={filter}
                setFilter={setFilter}
                initialFilter={initialFilter}
                handleFilter={handleFilter}
              />
            }
            handleFilterReset={() => {
              setFilter(initialFilter);
              resetFilter()
              dispatch(getAuditsAction(initialFilter));
            }}
            handleFilterSubmit={() => {
              dispatch(getAuditsAction(filter));
            }}
          >
            {auditState.auditsLog.map((apiCall, idx) => {
              return (
                <TableRow
                  key={idx}
                  moreOption={
                    <DropdownMenu
                      renderButton={<MoreIcon />}
                      className="action-dropdown"
                      id={`dropdown-menu-${apiCall?.request_id}`}
                    >
                      <DropdownOption
                        onClick={() => {
                          viewDetails(apiCall.id);
                        }}
                      >
                        View Details
                      </DropdownOption>
                    </DropdownMenu>
                  }
                  onRowClickAction={() => {
                    viewDetails(apiCall.id);
                  }}
                >
                  <td>{fullDateFormat(apiCall?.created_on)}</td>

                  <td>
                    <Chip
                      label={apiCall?.status_code}
                      data-status={requestColorMatch(apiCall?.status_code)}
                    />
                  </td>

                  <td>
                    <Chip
                      label={apiCall?.method}
                      data-status={requestColorMatch(apiCall?.method)}
                      transparentBackground
                    />
                  </td>

                  <td>{truncateString(apiCall?.url, 30)}</td>

                  <td>{currencyFormat(apiCall.charge, 'NGN', 'table')}</td>
                </TableRow>
              );
            })}
          </Table>
        ) : (
          <MobileTable
            isLoading={
              auditState.auditState === 'loading' &&
              auditState.auditsLog.length === 0
            }
            isEmpty={auditState.auditsLog.length === 0}
            emptyStateComponent={
              <EmptyTable
                message="No API calls yet"
                additional_message="Your APIS calls will appear here"
              />
            }
            title="API Logs"
            filter={
              <ApiLogsFilterModal
                filter={filter}
                setFilter={setFilter}
                initialFilter={initialFilter}
                handleFilter={handleFilter}
              />
            }
            handleFilterReset={() => {
              setFilter(initialFilter);
              resetFilter()
              dispatch(getAuditsAction(initialFilter));
            }}
            handleFilterSubmit={() => {
              dispatch(getAuditsAction(filter));
            }}
          >
            {auditState.auditsLog.map((apiCall, idx) => {
              return (
                <MobileTableRow
                  key={idx}
                  moreOption={
                    <DropdownMenu
                      renderButton={<MoreIcon />}
                      className="action-dropdown"
                      id={`dropdown-menu-${apiCall?.request_id}`}
                    >
                      <DropdownOption
                        onClick={() => {
                          viewDetails(apiCall.id);
                        }}
                      >
                        View Details
                      </DropdownOption>
                    </DropdownMenu>
                  }
                  onRowClickAction={() => {
                    viewDetails(apiCall.id);
                  }}
                >
                  <div className="field-container">
                    <span className="field-title">Date: </span>
                    {fullDateFormat(apiCall?.created_on)}
                  </div>

                  <div className="field-container">
                    <span className="field-title">Status: </span>
                    <Chip
                      label={apiCall?.status_code}
                      data-status={requestColorMatch(apiCall?.status_code)}
                      className="method-chip"
                    />
                  </div>

                  <div className="field-container">
                    <span className="field-title">Method: </span>
                    <Chip
                      label={apiCall?.method}
                      data-status={requestColorMatch(apiCall?.method)}
                      className="method-chip"
                      transparentBackground
                    />
                  </div>

                  <div className="field-container">
                    <span className="field-title">URL: </span>
                    {truncateString(apiCall?.url, 23)}
                  </div>

                  <div className="field-container">
                    <span className="field-title">Amount (NGN): </span>
                    {currencyFormat(apiCall.charge, 'NGN', 'table')}
                  </div>
                </MobileTableRow>
              );
            })}
          </MobileTable>
        )}

        <CardFooter>
          {auditState.auditsLog && (
            <Pagination
              total={auditState.totalAuditSize}
              perPage={perPage}
              setPerPage={setPerPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              loadPage={loadPage}
              perPageTitle="API Logs"
              updateDataCallback={fetchAudits}
            />
          )}
        </CardFooter>
      </Card>
    </div>
  );
}

export default ApiLogs;
