import React from 'react';
import '../_common-input-styles.scss';
import './_phone-number-input.scss';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

export interface InputProps {
  label?: string;
  error?: boolean | string;
  success?: boolean | string;
  successMessage?: string;
  errorMessage?: string;
  inputContainerStyle?: React.CSSProperties;
  [rest: string]: any;
}

const CustomPhoneInput: React.FC<InputProps> = (props) => {
  const {
    label,
    error,
    errorMessage,
    successMessage,
    success,
    disabled,
    inputContainerStyle,
    ...rest
  } = props;

  return (
    <div style={inputContainerStyle} className="input-container">
      <div
        className={`input-field-container  ${error && 'input-error'} ${
          success && 'input-success'
        }`}
      >
        {label && <label className={`input-label active`}>{label}</label>}

        <PhoneInput
          inputProps={{
            name: rest.name,
            required: true,
          }}
          country={'ng'}
          containerClass="phoneContainer"
          inputClass={`phoneInput`}
          disabled={disabled}
          value={props.value}
          onChange={props.onChange}
          enableAreaCodes={false}
          onlyCountries={['ng', 'rw', 'ke', 'us', 'uk']}
          countryCodeEditable={false}
          masks={{ ng: '... ... ....' }}
        />
      </div>

      {error && errorMessage && (
        <label className="error-message">{errorMessage}</label>
      )}
      {success && <label className="success-message">{successMessage}</label>}
    </div>
  );
};

export default CustomPhoneInput;
