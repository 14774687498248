import React, { useEffect, useState } from 'react';
import Modal from '../common/modal/modal';
import FillButton, { ButtonColor } from '../common/buttons/button';
import DragAndDrop from '../drag-and-drop/drag-and-drop';
import ModalFooter from '../common/modal/modal-footer';
import Style from './_upload-image.module.scss';
import { ReactComponent as EmptyImage } from '../../assets/icons/empty-image.svg';
import { ReactComponent as UploadIcon } from '../../assets/icons/upload.svg';
import PermissionsGate from '../../utils/hooks/permission-gate';

type Props = {
  title: string;
  isLoading: boolean;
  modal_title?: string;
  modal_description: string;
  uploadAction: (image: string, onSuccess: () => void) => void;
  resetAction: (onSuccess: () => void) => void;
  value: string;
  fileTypes?: string;
  permissions?: string[];
};

interface ButtonRow extends Pick<Props, 'resetAction'> {
  setOpenUploadModal: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
}

const ButtonRow = ({
  setOpenUploadModal,
  isLoading,
  resetAction,
}: ButtonRow) => {
  return (
    <div className={Style.button_row}>
      <FillButton
        label="Upload Image"
        onClick={() => {
          setOpenUploadModal(true);
        }}
        icon={<UploadIcon />}
        isLoading={isLoading}
        loadingText="Updading"
        className={Style.button}
      />

      <FillButton
        variant="outlined"
        colorVariant={ButtonColor.neutral}
        label="Remove"
        onClick={resetAction}
        disabled={isLoading}
        className={Style.button}
      />
    </div>
  );
};

function UploadImageModal({
  title,
  modal_title = 'Upload Image',
  modal_description,
  uploadAction,
  resetAction,
  isLoading,
  value,
  fileTypes = 'image/png, image/jpeg, image/jpg',
  permissions,
}: Props) {
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [full_logo, setFullLogo] = useState<string>('');

  useEffect(() => {
    if (openUploadModal && value) {
      setFullLogo(value);
    }
  }, [openUploadModal]);

  const handleClose = () => {
    setOpenUploadModal(false);
  };

  return (
    <div>
      <div className={Style.placeholder}>
        {value ? (
          <img src={value} alt="Short Logo" className={Style.logo_display} />
        ) : (
          <EmptyImage />
        )}

        <div>
          <p className="text-text-primary font-600 title">{title}</p>
          <p className="sm">(Max file size is 20MB)</p>
        </div>

        {permissions && permissions?.length > 0 ? (
          <PermissionsGate scopes={permissions}>
            <ButtonRow
              setOpenUploadModal={setOpenUploadModal}
              isLoading={isLoading}
              resetAction={resetAction}
            />
          </PermissionsGate>
        ) : (
          <ButtonRow
            setOpenUploadModal={setOpenUploadModal}
            isLoading={isLoading}
            resetAction={resetAction}
          />
        )}
      </div>

      <Modal
        isOpen={openUploadModal}
        onClose={handleClose}
        title={modal_title}
        modalFooter={
          <ModalFooter>
            <FillButton
              type="button"
              variant="outlined"
              onClick={handleClose}
              className="close-button"
              label="Cancel"
              colorVariant={ButtonColor.neutral}
            />

            <FillButton
              isLoading={isLoading}
              onClick={() => {
                if (!full_logo) return;
                uploadAction(full_logo, handleClose);
              }}
              disabled={!full_logo}
              label="Upload"
              loadingText="Uploading"
            />
          </ModalFooter>
        }
      >
        <div>
          <p className={`sm ${Style.modal_description}`}>{modal_description}</p>
          <DragAndDrop
            fileTypes={fileTypes}
            handleChange={(e: any) => {
              setFullLogo(e);
            }}
            buttonLabelText="Upload Image"
            value={full_logo}
            setFileToUpload={setFullLogo}
            status={'idle'}
            resetStatusAction={() => {}}
          />
        </div>
      </Modal>
    </div>
  );
}

export default UploadImageModal;
