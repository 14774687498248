import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../store/customHooks';
import { getAllAppServicesAction } from '../store/utility/utility.actions';

function useAdjutorServicesHook() {
  const dispatch = useAppDispatch();
  const { adjutorServicesOptions, loadingAdjutorOptions } = useAppSelector(
    (state) => state.utility
  );

  const getServices = () => {
    dispatch(getAllAppServicesAction());
  };

  useEffect(() => {
    if (adjutorServicesOptions?.length === 0) {
      getServices();
    }
  }, []);

  return { adjutorServicesOptions, loadingAdjutorOptions, getServices };
}

export default useAdjutorServicesHook;
