import React, { useState } from 'react';
import '../business/apps/_create-app.scss';
import { useAppDispatch, useAppSelector } from '../../store/customHooks';

//form
import { useFormik } from 'formik';

//components
import Input from '../../components/input-fields/input/input';
import SlidingPane from '../../components/slidingpane/slidingpane';
import FillButton, {
  ButtonColor,
} from '../../components/common/buttons/button';

//utils
import { changePasswordSchema } from './profile-details-schema';
import { IAccountState } from '../../store/account/account.interface';
import { changePasswordAction } from '../../store/account/account.actions';
import { resetUpdatingAccount } from '../../store/account/account.reducer';
import ModalFooter from '../../components/common/modal/modal-footer';
import PasswordStrengthProgress from '../../components/common/password-strength';
import SuccessModal from '../../components/notice-modal/success-modal';

export interface EditCompanyProps {
  isOpen: boolean;
  onClose: Function;
}

function ChangePasswordPane(props: EditCompanyProps) {
  const { isOpen, onClose } = props;
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const dispatch = useAppDispatch();
  const { isUpdatingAccount }: IAccountState = useAppSelector(
    (state) => state.account
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      old_password: '',
      new_password: '',
    },

    validationSchema: changePasswordSchema,

    onSubmit: async (values: {
      old_password: string;
      new_password: string;
    }) => {
      dispatch(changePasswordAction(values))
        .unwrap()
        .then(() => {
          onClose();
          setUpdateSuccess(true);
        });
    },
  });

  const handlePaneClose = () => {
    onClose();
    setTimeout(() => {
      dispatch(resetUpdatingAccount());
    }, 1000);
  };

  const handleCloseSuccess = () => {
    setUpdateSuccess(false);
    dispatch(resetUpdatingAccount());
  };

  return (
    <>
      <SlidingPane
        isOpen={isOpen}
        title="Change Passwword"
        onClose={handlePaneClose}
        footer={
          <ModalFooter>
            <FillButton
              variant="outlined"
              onClick={() => {
                handlePaneClose();
              }}
              className="close-button"
              colorVariant={ButtonColor.neutral}
              label="Close"
            />

            <FillButton
              type="submit"
              onClick={formik.submitForm}
              isLoading={isUpdatingAccount === 'loading'}
              disabled={!(formik.isValid && formik.dirty)}
              label="Change Password"
              loadingText="Saving"
            />
          </ModalFooter>
        }
      >
        <form className="create-app-form" onSubmit={formik.handleSubmit}>
          <div className="form-section">
            <Input
              type="password"
              label="Current Password"
              name="old_password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.old_password}
              error={formik.touched.old_password && formik.errors.old_password}
              errorMessage={formik.errors.old_password}
            />

            <div>
              <Input
                type="password"
                label="New Password"
                name="new_password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.new_password}
                error={
                  formik.touched.new_password && formik.errors.new_password
                }
                errorMessage={formik.errors.new_password}
              />

              <PasswordStrengthProgress password={formik.values.new_password} />
              <p className="xs">
                Password must contain a number, uppercase letter, lowercase
                letter and symbol(@,.%)
              </p>
            </div>
          </div>
        </form>
      </SlidingPane>

      <SuccessModal
        isOpen={updateSuccess}
        onClose={handleCloseSuccess}
        continueAction={handleCloseSuccess}
        title="Success"
        message="Password updated successfully"
      />
    </>
  );
}

export default ChangePasswordPane;
