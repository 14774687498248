import React, { ReactNode } from 'react';
import Style from './_card.module.scss';

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  children: ReactNode;
}

function CardFooter({ children, ...rest }: Props) {
  return (
    <div {...rest} className={Style.card_footer_container}>
      {children}
    </div>
  );
}

export default CardFooter;
