import { createSlice } from '@reduxjs/toolkit';
import {
  createModelAction,
  deleteModelAction,
  duplicateModelAction,
  getAllModelsAction,
  getConfigModelAction,
  getSingleModelAction,
  updateModelSettingAction,
} from './decision-model.actions';
import { IDecisionModels } from './decision-model.interface';

const initialState = {
  allModuleStatus: 'idle',
  allModels: [],
  error: '',
  totalModelSize: 0,
  focusModelStatus: 'idle',
  deleteModelStatus: 'idle',
  configModelStatus: 'idle',
  focusModel: null as any,
  configModel: null as any,
  configOfferSetting: null as any,
} as IDecisionModels;

const DecisionModelSlice = createSlice({
  name: 'DecisionModelState',
  initialState,

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getAllModelsAction.pending, (state: IDecisionModels) => {
      state.allModuleStatus = 'loading';
    });

    builder.addCase(
      getAllModelsAction.fulfilled,
      (state: IDecisionModels, action: any) => {
        state.allModels = action.payload.models;
        state.totalModelSize = action.payload.page_info.total;
        state.allModuleStatus = 'success';
      }
    );

    builder.addCase(
      getAllModelsAction.rejected,
      (state: IDecisionModels, action: any) => {
        state.error = action.payload;
        state.allModuleStatus = 'error';
      }
    );

    //Single Model
    builder.addCase(getSingleModelAction.pending, (state: IDecisionModels) => {
      state.focusModelStatus = 'loading';
    });
    builder.addCase(
      getSingleModelAction.fulfilled,
      (state: IDecisionModels, action: any) => {
        state.focusModel = action.payload;
        state.focusModelStatus = 'success';
      }
    );
    builder.addCase(
      getSingleModelAction.rejected,
      (state: IDecisionModels, action: any) => {
        state.error = action.payload;
        state.focusModelStatus = 'error';
      }
    );

    //Update Model
    builder.addCase(
      updateModelSettingAction.pending,
      (state: IDecisionModels) => {
        state.focusModelStatus = 'updating';
      }
    );
    builder.addCase(
      updateModelSettingAction.fulfilled,
      (state: IDecisionModels) => {
        state.focusModelStatus = 'success';
      }
    );
    builder.addCase(
      updateModelSettingAction.rejected,
      (state: IDecisionModels, action: any) => {
        state.error = action.payload;
        state.focusModelStatus = 'error';
      }
    );

    //Create Model
    builder.addCase(createModelAction.pending, (state: IDecisionModels) => {
      state.focusModelStatus = 'creating';
    });
    builder.addCase(
      createModelAction.fulfilled,
      (state: IDecisionModels, action: any) => {
        state.focusModel = action.payload;
        state.focusModelStatus = 'created';
      }
    );
    builder.addCase(
      createModelAction.rejected,
      (state: IDecisionModels, action: any) => {
        state.error = action.payload;
        state.focusModelStatus = 'error';
      }
    );

    //duplicate Model
    builder.addCase(duplicateModelAction.pending, (state: IDecisionModels) => {
      state.focusModelStatus = 'duplicating';
    });
    builder.addCase(
      duplicateModelAction.fulfilled,
      (state: IDecisionModels, action: any) => {
        state.focusModel = action.payload;
        state.focusModelStatus = 'duplicated';
      }
    );
    builder.addCase(
      duplicateModelAction.rejected,
      (state: IDecisionModels, action: any) => {
        state.error = action.payload;
        state.focusModelStatus = 'error';
      }
    );

    //Delete Model
    builder.addCase(deleteModelAction.pending, (state: IDecisionModels) => {
      state.deleteModelStatus = 'loading';
    });
    builder.addCase(
      deleteModelAction.fulfilled,
      (state: IDecisionModels, action: any) => {
        state.allModels = state.allModels.filter((model) => {
          return model.id !== action.payload;
        });
        state.deleteModelStatus = 'success';
      }
    );
    builder.addCase(
      deleteModelAction.rejected,
      (state: IDecisionModels, action: any) => {
        state.error = action.payload;
        state.deleteModelStatus = 'error';
      }
    );

    //Config Model
    builder.addCase(getConfigModelAction.pending, (state: IDecisionModels) => {
      state.configModelStatus = 'loading';
    });
    builder.addCase(
      getConfigModelAction.fulfilled,
      (state: IDecisionModels, action: any) => {
        const allowedConfig = action.payload.configuration.filter(
          (config: any) => {
            return (
              config.name === 'karma' ||
              config.name === 'ecosystem' ||
              config.name === 'statement' ||
              config.name === 'credit_bureau' ||
              config.name === 'scoring'
            );
          }
        );
        state.configModel = allowedConfig;

        state.configOfferSetting = action.payload.configuration.filter(
          (config: any) => {
            return config.name === 'offer';
          }
        )[0];

        state.configModelStatus = 'success';
      }
    );
    builder.addCase(
      getConfigModelAction.rejected,
      (state: IDecisionModels, action: any) => {
        state.error = action.payload;
        state.configModelStatus = 'error';
      }
    );
  },
});

export default DecisionModelSlice.reducer;
