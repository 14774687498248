import React, { useMemo, useState } from 'react';
import WizardHeader from '../../wizard/wizard-header';
import zxcvbn from 'zxcvbn';
import * as Yup from 'yup';
import { PasswordSchema } from '../../../utils/helper';

type Props = {
  password: string;
};

function PasswordStrengthProgress({ password }: Readonly<Props>) {
  const [currentStep, setCurrentStep] = useState<number[]>([]);

  useMemo(async () => {
    const isPasswordValid = await Yup.object()
      .shape({
        password: PasswordSchema,
      })
      .validate({ password })
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });

    if (!isPasswordValid) {
      setCurrentStep([]);
      return;
    }

    const score = zxcvbn(password)?.score;

    if (score > 0) {
      setCurrentStep([0]);
    }
    if (score > 1) {
      setCurrentStep([0, 1]);
    }
    if (score > 3) {
      setCurrentStep([0, 1, 2]);
    }
  }, [password]);

  return <WizardHeader totalSteps={3} completedSteps={currentStep} />;
}

export default PasswordStrengthProgress;
