import React from 'react';
import './_tabs.scss';
import { Tab, Tabs, TabList, TabPanel, TabsProps } from 'react-tabs';
import { useNavigate } from 'react-router-dom';
import PermissionsGate from '../../utils/hooks/permission-gate';

interface Props extends TabsProps {
  tabs: {
    title: string;
    route: string;
    action?: () => void;
    content: React.ReactElement;
    permissionScope?: string[];
  }[];
}

function CustomTabs({ tabs, ...rest }: Props) {
  const navigate = useNavigate();
  return (
    <Tabs {...rest}>
      <div className="tab-list-container">
        <TabList>
          {tabs.map((tab) => {
            return (
              <>
                {tab.permissionScope ? (
                  <PermissionsGate
                    key={`${tab.route}`}
                    scopes={tab.permissionScope}
                  >
                    <Tab
                      onClick={() => {
                        navigate(tab.route);
                        tab.action;
                      }}
                    >
                      {tab.title}
                    </Tab>
                  </PermissionsGate>
                ) : (
                  <Tab
                    key={`${tab.route}`}
                    onClick={() => {
                      navigate(tab.route);
                      tab.action;
                    }}
                  >
                    {tab.title}
                  </Tab>
                )}
              </>
            );
          })}
        </TabList>
      </div>

      <div className="tab-content">
        {tabs.map((tab) => {
          return tab.permissionScope ? (
            <PermissionsGate key={`${tab.route}`} scopes={tab.permissionScope}>
              <TabPanel>
                <div className="tab-content">{tab.content}</div>
              </TabPanel>
            </PermissionsGate>
          ) : (
            <TabPanel key={`${tab.route}`}>
              <div className="tab-content">{tab.content}</div>
            </TabPanel>
          );
        })}
      </div>
    </Tabs>
  );
}

export default CustomTabs;
