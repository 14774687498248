import React from 'react';
import Styles from './_home.module.scss';
import Card from '../../components/common/cards/cards';
import FillButton from '../../components/common/buttons/button';
import { ReactComponent as ArrowRight } from '../../assets/icons/arrow-right.svg';
import { Link } from 'react-router-dom';
import ExternalLink from '../../components/common/text/external-link';

export interface ShortcutCardProps {
  className?: string;
  title: string;
  description: string;
  icon: React.ReactElement;
  button: { label: string; link: string; externalLink?: boolean };
}

function ShortcutCard(props: ShortcutCardProps) {
  const { title, description, icon, button, className } = props;
  return (
    <Card className={`${className ?? ''} ${Styles.shortcut_card}`}>
      <div>
        <div className={Styles.shortcut_card_icon}>{icon}</div>

        <div className={Styles.shortcut_card_info}>
          <p className={`${Styles.shortcut_card_title} font-600`}>{title}</p>
          <p className={`${Styles.description} sm`}>{description}</p>
        </div>
      </div>

      <div>
        {button.externalLink ? (
          <ExternalLink
            href={button.link}
            label={
              <FillButton
                label={button.label}
                variant="ghost"
                icon={<ArrowRight />}
                iconPosition="right"
              />
            }
          />
        ) : (
          <Link to={button.link}>
            <FillButton
              label={button.label}
              variant="ghost"
              icon={<ArrowRight />}
              iconPosition="right"
            />
          </Link>
        )}
      </div>
    </Card>
  );
}

export default ShortcutCard;
