import React from 'react';
import Styles from './_home.module.scss';

//components
import ShortcutCard from './shortcut-card';

//images
import { ReactComponent as APIDocsIcon } from '../../assets/icons/file-question.svg';
import { ReactComponent as PricingIcon } from './assets/pricing.svg';
import { useAppSelector } from '../../store/customHooks';
import { IAccountState } from '../../store/account/account.interface';
import urls, { externalRoutes } from '../../routes/all-routes';
import PageTitle from '../../components/common/page-title';
import ExternalLink from '../../components/common/text/external-link';
import GetStarted from './get-started/get-started';

function HomePage() {
  const account: IAccountState = useAppSelector((state) => state.account);

  return (
    <div className={Styles.home_page_container}>
      <PageTitle
        title={`Hello ${account?.last_name || account.full_name}`}
        description="What would you like to do today?"
      />

      <div className={Styles.home_page}>
        <GetStarted />

        <div style={{ width: '100%' }}>
          <p className={`lead font-600 ${Styles.title}`}>
            Learn more about Adjutor
          </p>

          <div className={Styles.shortcut_card_row}>
            <ShortcutCard
              icon={<APIDocsIcon />}
              title={'API Docs'}
              description={
                'Learn all about Adjutor’s available products and features and how to use them.'
              }
              button={{
                label: 'Get Started',
                link: externalRoutes.docs.index,
                externalLink: true,
              }}
            />
            <ShortcutCard
              icon={<PricingIcon />}
              title={'Pricing'}
              description={
                'See the cost for API calls available on Adjutor. Our policy is no hit, no charge'
              }
              button={{
                label: 'View pricing',
                link: urls.settings.api_pricing.index,
              }}
            />
          </div>
        </div>

        <p style={{ width: '100%', textAlign: 'center' }} className=" sm">
          Do you have a feature request or suggestions?{' '}
          <ExternalLink
            href={externalRoutes.adjutor.support}
            label="Send us a message"
          />{' '}
          or{' '}
          <ExternalLink href={externalRoutes.adjutor.support} label="report" />{' '}
          a bug.
        </p>
      </div>
    </div>
  );
}

export default HomePage;
