import React, { useState } from 'react';
import './_configuration-field.scss';
import Checkbox from '../../../input-fields/checkbox/checkbox';
import Input from '../../../input-fields/input/input';
import { IDecisionIntegerFieldProps } from '../render-model-config-options';
import { useFormikContext } from 'formik';
import { ISetting } from '../../../../store/decision-model/decision-model.interface';

function ConfigurationIntegerField(props: IDecisionIntegerFieldProps) {
  const { label, id, type, minimum, maximum } = props;
  const { setFieldValue, setValues, values, initialValues } =
    useFormikContext<ISetting['setting']>();

  const [data, setData] = useState({
    allowField: values[id]?.allowField ?? true,
    ...props,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: Number(value) });
    setFieldValue(`${id}.${name}`, value);
  };

  const handleRemoveField = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setData({ ...data, allowField: checked });

    let updatedValues = values as object;

    if (!checked) {
      updatedValues = {
        ...updatedValues,
        [name]: {
          type,
          allowField: checked,
        },
      };
    } else {
      delete initialValues[name].allowField;
      const updatedField = { ...initialValues[name] };
      updatedValues = { ...updatedValues, [name]: updatedField };
    }

    setValues(updatedValues);
  };

  return (
    <div className="configuration-field">
      <div className="configuration-field-title">
        <Checkbox
          variant="no-fill"
          value={data.allowField}
          checked={data.allowField}
          name={id}
          label={label?.replace(/_/g, ' ')}
          labelClassName="config-title"
          onChange={handleRemoveField}
        />
      </div>

      <div className="config-input-fields">
        <div className="range-fields">
          {data.allowField && (
            <>
              <Input
                name="minimum"
                placeholder="00"
                type="number"
                minimum={minimum}
                defaultValue={minimum}
                value={data.minimum}
                onChange={handleChange}
              />

              {maximum >= 0 ? (
                <>
                  <div>to</div>

                  <Input
                    name="maximum"
                    placeholder="00"
                    type="number"
                    maximum={maximum}
                    value={maximum}
                    onChange={handleChange}
                  />
                </>
              ) : null}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ConfigurationIntegerField;
