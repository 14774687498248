import { IAccountState } from './account.interface';
import { createSlice } from '@reduxjs/toolkit';
import {
  getOrgProfileAction,
  editOrgProfileAction,
  updateOrgLogoAction,
  getOrgDocumentsAction,
  updateOrgDocumentAction,
  updateUserDetailsAction,
  changePasswordAction,
  updateUserProfileImgAction,
  switchOrganizationAction,
} from './account.actions';
import { loginUserAction } from '../auth/auth.actions';

const initialState = {
  id: null,
  role_id: 0,
  org_id: null,
  email: '',
  last_name: '',
  full_name: '',
  phone_number: '',
  last_org_id: null,
  image_url: '',
  activated: 0,
  deleted_flag: 0,
  organizations: [],
  web_app_url: '',
  last_organization: {},
  current_organization_profile: {
    id: null,
    name: '',
    rc_number: '',
    tier: 0,
    logo_url: '',
    short_logo_url: '',
    primary_email: '',
    website_url: '',
    address: '',
    type: '',
    type_id: null,
    facebook_url: null,
    twitter_url: null,
    instagram_url: null,
    privacy_url: null,
    terms_url: null,
    short_name: '',
    support_email: '',
    support_phone: '',
    webhook_url: '',
    documents: [],
    domain: '',
  },
  document_types: [],
  isLoading: false,
  isDocumentTypesLoading: 'idle',
  isUpdatingAccount: 'idle',
} as IAccountState;

const AccountSlice = createSlice({
  name: 'AccountState',
  initialState,

  reducers: {
    resetAccount: (state) => {
      Object.assign(state, initialState);
    },
    resetUpdatingAccount: (state) => {
      state.isUpdatingAccount = 'idle';
    },
  },

  extraReducers: (builder) => {
    //Login Action
    builder.addCase(loginUserAction.pending, (state: IAccountState) => {
      state.isLoading = true;
    });
    builder.addCase(
      loginUserAction.fulfilled,
      (state: IAccountState, action: any) => {
        if (action.payload.twofa === true) {
          return;
        }
        state.full_name = action.payload.data.full_name;
        state.last_name =
          action.payload.data.full_name?.split?.(' ')?.[1] ??
          action.payload.data.full_name;
        state.email = action.payload.data.email;
        state.image_url = action.payload.data.image_url;
        state.phone_number = action.payload.data.phone_number;
        state.isLoading = false;
        state.role_id = action.payload.data.role_id;
        state.organizations = action.payload.data.organizations;
      }
    );
    builder.addCase(loginUserAction.rejected, (state: IAccountState) => {
      state.isLoading = false;
    });

    //get Profile
    builder.addCase(getOrgProfileAction.pending, (state: IAccountState) => {
      state.isLoading = true;
    });
    builder.addCase(
      getOrgProfileAction.fulfilled,
      (state: IAccountState, action: any) => {
        state.current_organization_profile = action.payload;
        state.isLoading = false;
      }
    );
    builder.addCase(getOrgProfileAction.rejected, (state: IAccountState) => {
      state.isLoading = false;
    });

    //Expexted Document Types
    builder.addCase(getOrgDocumentsAction.pending, (state: IAccountState) => {
      state.isDocumentTypesLoading = 'loading';
    });
    builder.addCase(
      getOrgDocumentsAction.fulfilled,
      (state: IAccountState, action: any) => {
        state.document_types = action.payload;
        state.isDocumentTypesLoading = 'success';
      }
    );
    builder.addCase(getOrgDocumentsAction.rejected, (state: IAccountState) => {
      state.isDocumentTypesLoading = 'error';
    });

    //Edit Org Profile
    builder.addCase(editOrgProfileAction.pending, (state: IAccountState) => {
      state.isUpdatingAccount = 'loading';
    });
    builder.addCase(
      editOrgProfileAction.fulfilled,
      (state: IAccountState, action: any) => {
        state.current_organization_profile.address = action.payload.address;
        state.current_organization_profile.website_url =
          action.payload.website_url;
        state.current_organization_profile.support_phone =
          action.payload?.support_phone;
        state.current_organization_profile.primary_email =
          action.payload?.primary_email;
        state.current_organization_profile.support_email =
          action.payload?.support_email;
        state.isUpdatingAccount = 'org profile updated';
      }
    );
    builder.addCase(editOrgProfileAction.rejected, (state: IAccountState) => {
      state.isUpdatingAccount = 'error';
    });

    //Update Org Logos
    builder.addCase(
      updateOrgLogoAction.pending,
      (state: IAccountState, action: any) => {
        state.isUpdatingAccount = `loading.${action.meta.arg.logo_type}-org-logo`;
      }
    );
    builder.addCase(
      updateOrgLogoAction.fulfilled,
      (state: IAccountState, action: any) => {
        state.current_organization_profile.logo_url = action.payload.logo_url;
        state.current_organization_profile.short_logo_url =
          action.payload.short_logo_url;
        state.isUpdatingAccount = `success.${action.meta.arg.logo_type}-org-logo`;
      }
    );
    builder.addCase(
      updateOrgLogoAction.rejected,
      (state: IAccountState, action: any) => {
        state.isUpdatingAccount = `error.${action.meta.arg.logo_type}-org-logo`;
      }
    );

    //Update Org Docs
    builder.addCase(updateOrgDocumentAction.pending, (state: IAccountState) => {
      state.isDocumentTypesLoading = 'updating.document-upload';
    });
    builder.addCase(
      updateOrgDocumentAction.fulfilled,
      (state: IAccountState) => {
        state.isDocumentTypesLoading = 'success.document-upload';
      }
    );
    builder.addCase(
      updateOrgDocumentAction.rejected,
      (state: IAccountState) => {
        state.isDocumentTypesLoading = 'error.document-upload';
      }
    );

    //Update User Profile
    builder.addCase(updateUserDetailsAction.pending, (state: IAccountState) => {
      state.isUpdatingAccount = 'loading';
    });
    builder.addCase(
      updateUserDetailsAction.fulfilled,
      (state: IAccountState, action: any) => {
        state.isUpdatingAccount = 'user profile updated';
        state.full_name = action.payload.full_name;
        state.last_name =
          action.payload.full_name?.split?.(' ')?.[1] ??
          action.payload.data.full_name;
        state.phone_number = action.payload.phone_number;
      }
    );
    builder.addCase(
      updateUserDetailsAction.rejected,
      (state: IAccountState) => {
        state.isUpdatingAccount = 'error';
      }
    );

    //Change User Password
    builder.addCase(changePasswordAction.pending, (state: IAccountState) => {
      state.isUpdatingAccount = 'loading';
    });
    builder.addCase(changePasswordAction.fulfilled, (state: IAccountState) => {
      state.isUpdatingAccount = 'password change success';
    });
    builder.addCase(changePasswordAction.rejected, (state: IAccountState) => {
      state.isUpdatingAccount = 'error';
    });

    //Change User Profile Image
    builder.addCase(
      updateUserProfileImgAction.pending,
      (state: IAccountState) => {
        state.isUpdatingAccount = 'loading.profile-image';
      }
    );
    builder.addCase(
      updateUserProfileImgAction.fulfilled,
      (state: IAccountState, action: any) => {
        state.image_url = action.payload.image_url;
        state.isUpdatingAccount = 'success.profile-image';
      }
    );
    builder.addCase(
      updateUserProfileImgAction.rejected,
      (state: IAccountState) => {
        state.isUpdatingAccount = 'error.profile-image';
      }
    );

    //Switch Org Action
    builder.addCase(
      switchOrganizationAction.pending,
      (state: IAccountState) => {
        state.isLoading = true;
      }
    );
    builder.addCase(
      switchOrganizationAction.fulfilled,
      (state: IAccountState, action: any) => {
        state.full_name = action.payload.data.full_name;
        state.last_name =
          action.payload.data.full_name?.split?.(' ')?.[1] ??
          action.payload.data.full_name;
        state.email = action.payload.data.email;
        state.image_url = action.payload.data.image_url;
        state.phone_number = action.payload.data.phone_number;
        state.isLoading = false;
        state.role_id = action.payload.data.role_id;
        state.organizations = action.payload.data.organizations;
      }
    );
    builder.addCase(
      switchOrganizationAction.rejected,
      (state: IAccountState) => {
        state.isLoading = false;
      }
    );
  },
});

export const { resetAccount, resetUpdatingAccount } = AccountSlice.actions;

export default AccountSlice.reducer;
