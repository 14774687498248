import * as Yup from "yup";
import { PasswordSchema, phoneNumberValidationErrorMessage, phoneNumberValidationRegex } from "../../utils/helper";

export const updateUserDetails = Yup.object().shape({
  full_name: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  phone_number: Yup.string()
    .matches(
      phoneNumberValidationRegex,
      phoneNumberValidationErrorMessage
    )
    .required("Required")
    .required("Required"),
});

export const updateUserImage = Yup.object().shape({
  logo_url: Yup.mixed(),
});

export const changePasswordSchema = Yup.object().shape({
  old_password: Yup.string().required("Required"),
  new_password: PasswordSchema,
});
