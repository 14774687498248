import React from 'react';
import { BarChart } from '../../components/graphs/bar-chart';
import { IAuditsLogType } from '../../store/audits/audits.interface';
import Card from '../../components/common/cards/cards';
import CardHeader from '../../components/common/cards/card-header';
import NoActivityCard from './no-activity-card';
import CardBody from '../../components/common/cards/card-body';
import { truncateString } from '../../utils/formatter';

interface Props {
  activeFilter: string;
  data: IAuditsLogType[];
}

export default function ApiCallsChart({ activeFilter, data }: Props) {
  const urlCounts: any = {};

  // Iterate over the data array
  data?.forEach((entry: any) => {
    const url = truncateString(entry.url, 30);
    urlCounts[url] = (urlCounts?.[url] || 0) + 1;
  });

  const sortedUrlCounts =
    Object.entries(urlCounts)
      ?.map(([url, count]) => ({ url, count }))
      ?.sort((a: any, b: any) => b.count - a.count)
      ?.slice(0, 9) ?? [];

  const dataLength = sortedUrlCounts?.length ?? 0;
  const fillerLength = 9 - dataLength;

  return (
    <Card
      cardHeading={
        <CardHeader
          title="Top APIs by Calls"
          additiionalButton={<>{activeFilter}</>}
        />
      }
      className="api_call_chart_container"
    >
      <CardBody className="api_call_chart">
        {data?.length > 0 && sortedUrlCounts?.length > 0 ? (
          <BarChart
            data={{
              labels: [
                ...(sortedUrlCounts ?? [])?.map((count) => {
                  return count.url;
                }),
                ...Array(fillerLength)?.fill(''),
              ],
              datasets:
                (sortedUrlCounts?.map((count) => {
                  return count.count;
                }) as number[]) || [],
            }}
          />
        ) : (
          <NoActivityCard />
        )}
      </CardBody>
    </Card>
  );
}
