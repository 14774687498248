import React from 'react';
import './_sidebar.scss';
import { useAppSelector, useAppDispatch } from '../../../store/customHooks';
import { OpenSideBar, CloseSideBar } from '../../../store/layout/layout.reducer';

//component
import NavComponent from './nav-component';

//icons
import AdjutorLogo from '../../../assets/brand/full-lendsqr-logo.svg';
import SmallAdjutorLogo from '../../../assets/brand/small-lendsqr-logo.svg';
import { ReactComponent as HomeIcon } from '../../../assets/icons/home.svg';
import { ReactComponent as DashboardIcon } from '../../../assets/icons/dashboard.svg';
import { ReactComponent as AppIcon } from '../../../assets/icons/app.svg';
import { ReactComponent as AuditIcon } from '../../../assets/icons/audit.svg';
import { ReactComponent as WalletIcon } from '../../../assets/icons/wallet.svg';
import { ReactComponent as SidebarCollapseIcon } from '../../../assets/icons/sidebar-collapse.svg';
import { ReactComponent as LogoutIcon } from '../../../assets/icons/log-off.svg';
import FillButton from '../../../components/common/buttons/button';
import urls from '../../../routes/all-routes';
import { logoutAction } from '../../../store/auth/auth.actions';

export default function Sidebar() {
  const dispatch = useAppDispatch();
  const sidebarState = useAppSelector((state) => state.layoutReducer);

  return (
    <div
      className={`sidebar ${sidebarState.isSidebarOpen ? 'open' : 'collapsed'}`}
      id="sidebar"
    >
      <div className="logo">
        <img src={AdjutorLogo} alt="ADJUTOR" className="large-logo" />

        <img src={SmallAdjutorLogo} alt="ADJUTOR" className="small-logo" />

        <FillButton
          className="sidebar-toggle-btn"
          onClick={() => {
            if (sidebarState.isSidebarOpen === true) {
              dispatch(CloseSideBar());
            } else {
              dispatch(OpenSideBar());
            }
          }}
        >
          <span className="icon">
            <SidebarCollapseIcon />
          </span>
        </FillButton>
      </div>

      <nav>
        {/* title is case sensitive as it is used to render active link */}
        <NavComponent
          title="home"
          link={urls.home.index}
          icon={<HomeIcon />}
          id={1}
        />

        <NavComponent
          title="dashboard"
          link={urls.dashboard.index}
          icon={<DashboardIcon />}
          id={2}
        />

        <NavComponent
          title="Apps"
          link={urls.business.apps.index}
          icon={<AppIcon />}
          id={4}
        />

        <NavComponent
          title="Wallet"
          link={urls.business.wallet.index}
          icon={<WalletIcon />}
          id={3}
        />

        {/* <NavComponent
          title="Decision Model"
          link={urls.business.decision_model.index}
          icon={<DecisionModelIcon />}
          id={5}
        /> */}

        <NavComponent
          title="Audit"
          link={urls.business.audits_trails.audit.index}
          icon={<AuditIcon />}
          id={8}
        />
      </nav>

      <aside className="api-doc-button">
        <NavComponent
          title="Logout"
          link={'#'}
          onClick={() => {
            logoutAction();
          }}
          icon={<LogoutIcon />}
          id={6}
        />
      </aside>
    </div>
  );
}
