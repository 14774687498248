import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/es/storage/session';
import storage from 'redux-persist/lib/storage';
import accountReducer from './account/account.reducer';
import appsReducer from './apps/apps.reducer';
import auditsReducer from './audits/audits.reducer';
import AuthSlice from './auth/auth.reducer';
import keyManagementReducer from './key-management/key-management.reducer';
import layoutReducer from './layout/layout.reducer';
import onboardingReducer from './onboarding/onboarding.reducer';
import settingsReducer from './settings/settings.reducer';
import utilityReducer from './utility/utility.reducer';
import walletReducer from './wallets/wallet.reducer';
import decisionModelReducer from './decision-model/decision-model.reducer';
import decisionModelaggregateReducer from './decision-model/decision-modelaggregate.reducer';
import auditslogaggregateReducer from './audits/auditslogaggregate.reducer';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import { reducerKeys } from '../constants/store.constants';

const handleEncryptError = (key: string, err: any) => {
  console.log(`Error encrypting ${key} state.`, err);
};

const secretKey: string = process.env.REACT_APP_ENCRYPT_SECRET_KEY ?? '';

const persistConfig = {
  key: 'AdjutorRoot',
  storage,
  whitelist: [],
};

const authPersistConfig = {
  key: reducerKeys.auth,
  storage: sessionStorage,
  transforms: [
    encryptTransform({
      secretKey,
      onError: function (error) {
        return handleEncryptError(reducerKeys.auth, error);
      },
    }),
  ],
};

const auditPersistConfig = {
  key: 'audit',
  storage: sessionStorage,
  transforms: [
    encryptTransform({
      secretKey,
      onError: function (error) {
        return handleEncryptError(reducerKeys.auth, error);
      },
    }),
  ],
};

const walletPersistConfig = {
  key: 'wallet',
  storage: sessionStorage,
  transforms: [
    encryptTransform({
      secretKey,
      onError: function (error) {
        return handleEncryptError(reducerKeys.auth, error);
      },
    }),
  ],
};

const appsPersistConfig = {
  key: 'apps',
  storage: sessionStorage,
};

const accountPersistConfig = {
  key: 'account',
  storage: sessionStorage,
  transforms: [
    encryptTransform({
      secretKey,
      onError: function (error) {
        return handleEncryptError(reducerKeys.auth, error);
      },
    }),
  ],
};

const utilityPersistConfig = {
  key: 'utility',
  storage: sessionStorage,
  transforms: [
    encryptTransform({
      secretKey,
      onError: function (error) {
        return handleEncryptError(reducerKeys.auth, error);
      },
    }),
  ],
};

const settingsPersistConfig = {
  key: 'setting',
  storage: sessionStorage,
  whitelist: ['allApiPlans'],
  transforms: [
    encryptTransform({
      secretKey,
      onError: function (error) {
        return handleEncryptError(reducerKeys.auth, error);
      },
    }),
  ],
};

const rootReducers = combineReducers({
  auth: persistReducer(authPersistConfig, AuthSlice),
  account: persistReducer(accountPersistConfig, accountReducer),
  onboarding: onboardingReducer,
  audit: persistReducer(auditPersistConfig, auditsReducer),
  wallet: persistReducer(walletPersistConfig, walletReducer),
  apps: persistReducer(appsPersistConfig, appsReducer),
  utility: persistReducer(utilityPersistConfig, utilityReducer),
  layoutReducer: layoutReducer,
  decisionModel: decisionModelReducer,
  decisionModelAggregate: decisionModelaggregateReducer,
  auditsLogAggregate: auditslogaggregateReducer,
  settings: persistReducer(settingsPersistConfig, settingsReducer),
  keys: keyManagementReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducers);

export { rootReducers, persistedReducer };
