import React from 'react';
import './_view-role.scss';

import { ReactComponent as CheckIcon } from '../../../../assets/icons/check-with-border.svg';
import { ReactComponent as CancelIcon } from '../../../../assets/icons/cancel-with-border.svg';
import { ADJUTOR_ROLES } from '../../../../utils/hooks/permission-maps';
import SlidingPane from '../../../../components/slidingpane/slidingpane';
import ModalFooter from '../../../../components/common/modal/modal-footer';
import FillButton, {
  ButtonColor,
} from '../../../../components/common/buttons/button';

type Props = {
  role_id: number;
  isOpen: boolean;
  onClose: () => void;
};

function ViewRole({ isOpen, onClose, role_id }: Props) {
  const permitted = ADJUTOR_ROLES.find((role) => {
    return role.id === role_id;
  })?.permissions;

  const super_admin_permissions = ADJUTOR_ROLES.find((role) => {
    return role.id === 1;
  })?.permissions;

  const unpermitted = super_admin_permissions?.filter((permission) => {
    return !permitted?.includes(permission);
  });

  return (
    <SlidingPane
      title="Team Permission"
      isOpen={isOpen}
      onClose={onClose}
      footer={
        <ModalFooter>
          <FillButton
            variant="outlined"
            colorVariant={ButtonColor.neutral}
            label="Close"
            onClick={onClose}
          />
        </ModalFooter>
      }
    >
      <div className="role-ability-cards-container">
        <div className="role-ability-card can-do">
          <div className="role-heading font-600">What you can do</div>

          <div className="role-body">
            {permitted?.map((role: string) => {
              return (
                <div className="role" key={`${role}`}>
                  <span className="role-icon">
                    <CheckIcon />
                  </span>

                  <span>
                    Can{' '}
                    {role
                      .split('-')
                      .filter((role, idx) => {
                        return idx > 0;
                      })
                      .join(' ')}
                  </span>
                </div>
              );
            })}
          </div>
        </div>

        {unpermitted && unpermitted?.length > 0 && (
          <div className="role-ability-card cannot-do">
            <div className="role-heading font-600">What you can't do</div>

            <div className="role-body">
              {unpermitted?.map((role: string) => {
                return (
                  <div className="role" key={`role`}>
                    <span className="role-icon">
                      <CancelIcon />
                    </span>
                    <span>
                      Can't{' '}
                      {role
                        .split('-')
                        .filter((role, idx) => {
                          return idx > 0;
                        })
                        .join(' ')}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </SlidingPane>
  );
}

export default ViewRole;
