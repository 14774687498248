import { createAsyncThunk } from '@reduxjs/toolkit';
import { callApi } from '../../config/apiSetup';
import {
  adjutorLogUrl,
  auditLogAnalyticsUrl,
  eventLogUrl,
} from '../../config/allUrl';
import { toast } from 'react-hot-toast';
import { defaultErrorMessage } from '../../utils/helper';
import { GetAuditActionProps, GetEventLogActionProps } from './types';

export const getAuditsAnalyticsAction = createAsyncThunk(
  'business/getAuditsAnalyticsAction',
  async (dispatch, ThunkApi) => {
    try {
      const res = await callApi.get(`${auditLogAnalyticsUrl}`);
      return res.data;
    } catch (error: any) {
      const message: string = error.response.data?.message || error?.message;
      toast.error(message ?? defaultErrorMessage);
      return ThunkApi.rejectWithValue(message);
    }
  }
);

export const getAuditsAction = createAsyncThunk(
  'business/getAuditAction',
  async (pageDetails: GetAuditActionProps, ThunkApi) => {
    //PAGINATION STARTS ON 0 fFROM BE
    if (pageDetails.page > 0) {
      pageDetails.page = pageDetails.page - 1;
    }
    let params = JSON.stringify(pageDetails);

    // CONVERT PARAMS TO QUERY PARAMS FOR CALL
    params = params
      .split(',')
      .join('&')
      .substring(1, params.length - 1)
      .replace(/"/g, '')
      .replace(/:/g, '=');

    try {
      const res = await callApi.get(`${adjutorLogUrl}?${params}`);
      const data = {
        ...res.data,
        calls: res.data?.calls.map((data: any) => {
          return { ...data, response_time: data.tat };
        }),
      };

      return data;
    } catch (error: any) {
      const message: string = error.response.data?.message || error?.message;
      toast.error(message ?? defaultErrorMessage);
      return ThunkApi.rejectWithValue(message);
    }
  }
);

export const getAuditsActionAggregate = createAsyncThunk(
  'business/getAuditsActionAggregate',
  async (pageDetails: { limit: number }, ThunkApi) => {
    try {
      const res = await callApi.get(
        `${adjutorLogUrl}?page=0&limit=${pageDetails.limit}`
      );

      const data = {
        ...res.data,
        calls: res.data?.calls.map((data: any) => {
          return { ...data, response_time: data.tat };
        }),
      };

      return data;
    } catch (error: any) {
      const message: string = error.response.data?.message || error?.message;
      toast.error(message ?? defaultErrorMessage);
      return ThunkApi.rejectWithValue(message);
    }
  }
);

export const getSingleAuditsAction = createAsyncThunk(
  'business/getSingleAuditAction',
  async (id: number, ThunkApi) => {
    try {
      const res = await callApi.get(`${adjutorLogUrl}/${id}`);
      return res.data;
    } catch (error: any) {
      const message: string = error.response.data?.message || error?.message;
      toast.error(message ?? defaultErrorMessage);
      return ThunkApi.rejectWithValue(message);
    }
  }
);

export const getEventLogAction = createAsyncThunk(
  'business/getEventAction',
  async (pageDetails: GetEventLogActionProps, ThunkApi) => {
    //PAGINATION STARTS ON 0 fFROM BE
    if (pageDetails.page > 0) {
      pageDetails.page = pageDetails.page - 1;
    }

    let params = JSON.stringify(pageDetails);

    // CONVERT PARAMS TO QUERY PARAMS FOR CALL
    params = params
      .split(',')
      .join('&')
      .substring(1, params.length - 1)
      .replace(/"/g, '')
      .replace(/:/g, '=');

    try {
      const res = await callApi.get(`${eventLogUrl}?type=admin&${params}`);
      return res.data;
    } catch (error: any) {
      const message: string = error.response.data?.message || error?.message;
      toast.error(message ?? defaultErrorMessage);
      return ThunkApi.rejectWithValue(message);
    }
  }
);

export const getSingleEventAction = createAsyncThunk(
  'business/getSingleEventAction',
  async (id: number, ThunkApi) => {
    try {
      const res = await callApi.get(`${eventLogUrl}/${id}`);
      return res.data;
    } catch (error: any) {
      const message: string = error.response.data?.message || error?.message;
      toast.error(message ?? defaultErrorMessage);
      return ThunkApi.rejectWithValue(message);
    }
  }
);
