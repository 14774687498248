import React from 'react';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { formatMoney } from '../../utils/formatter';

ChartJS.register(CategoryScale, LinearScale, BarElement, ChartDataLabels);

export interface IBarChartData {
  labels: string[];
  datasets: number[];
}

interface Props {
  data: IBarChartData;
  [rest: string]: any;
}

export function BarChart({ data }: Props) {
  return (
    <Bar
      options={{
        indexAxis: 'y' as const,
        elements: {
          bar: {},
        },
        responsive: true,
        scales: {
          x: {
            stacked: false,
            display: false,
            beginAtZero: true,
          },
          y: {
            grid: {
              display: false,
            },
            position: 'right',
            stacked: false,
            beginAtZero: true,
            ticks: {
              callback: function (value, index) {
                return formatMoney(data.datasets[index] ?? ''); // Displaying the value on the y-axis
              },
            },
          },
        },

        plugins: {
          datalabels: {
            anchor: 'center',
            align: 'center',
            display: true,
            clamp: true,
            padding: 20,
            color: '#000000',
            font: {
              size: 14,
            },

            // and if you need to format how the value is displayed...
            formatter: (value, context) =>
              context?.chart?.data?.labels?.[context.dataIndex],
          },
        },
      }}
      data={{
        labels: data.labels,
        datasets: [
          {
            label: '',
            data: data.datasets,
            backgroundColor: '#7BD2D3',
            barThickness: 23,
          },
        ],
      }}
    />
  );
}
