import React, { useState, useEffect } from 'react';
import './_sign-up.scss';
import AuthLayout from '../../../layout/authLayout/authLayout';
import StepWizard from 'react-step-wizard';
//components
import UserDetails from './user-details';
import CompletedSignUp from './completed-signup';

//images
import { scrollToTop } from '../../../utils/helper';
import { useAppSelector } from '../../../store/customHooks';
import { ISignUp } from '../../../store/onboarding/onboarding.interface';

export default function SignUpPage() {
  const currentStep = useAppSelector((state) => state.onboarding.currentStep);
  const [_completedSteps, setCompletedSteps] = useState<number[]>([0]);
  const [saveData, setSaveData] = useState<Partial<ISignUp>>({});
  const [instance, updateInstance] = useState({
    goToStep: (_step: number) => {},
  });

  const setInstance = (SW: any) => updateInstance(SW);

  const prevStepStatus = (props: any) => {
    props.previousStep();
    setCompletedSteps((prev) => prev.filter((step) => step <= currentStep));
    scrollToTop();
  };

  const saveStepStatus = (data: Partial<ISignUp>) => {
    setSaveData(data);
  };

  useEffect(() => {
    instance?.goToStep(currentStep);
    scrollToTop();
  }, [currentStep]);

  return (
    <AuthLayout banner_type="listed" long_child={true}>
      <div className="sign-up-page">
        <StepWizard
          isLazyMount={true}
          initialStep={currentStep}
          instance={setInstance}
        >
          <UserDetails
            stepNo={0}
            saveStepStatus={saveStepStatus}
            prevStep={prevStepStatus}
          />

          <CompletedSignUp savedData={saveData} stepNo={1} />
        </StepWizard>
      </div>
    </AuthLayout>
  );
}
