import { createSlice } from "@reduxjs/toolkit";
import { deleteModelAction, getDecisionModelAggregate } from "./decision-model.actions";
import { IDecisionModelAggregate } from './decision-model.interface'

const initialState = {
    decisionModelAggregate: []
}  as IDecisionModelAggregate;


const DecisionModelAggregateSlice = createSlice({
    name: 'DecisionModelAggregate',
    initialState,
    reducers: {},

    extraReducers: (builder) => {
      builder.addCase(getDecisionModelAggregate.fulfilled, (state, action) => {
        state.decisionModelAggregate = action.payload.models;
      });
       //Delete Model

    builder.addCase(
      deleteModelAction.fulfilled,
      (state: IDecisionModelAggregate, action: any) => {
        state.decisionModelAggregate = state.decisionModelAggregate.filter((model) => {
          return model.id !== action.payload;
        });
      }
    );
    },
});

export default DecisionModelAggregateSlice.reducer;

