import { ICompleteRegisteration } from './onboarding.interface';
import { createSlice } from '@reduxjs/toolkit';
import {
  createUserAccountAction,
  getOnboardingDetailsActions,
  resendOnboardingMailAction,
  updateBusinessAddressAction,
  updateBusinessBankActions,
  updateBusinessDetailsAction,
  updateBusinessKYCAction,
} from './onboarding.actions';
import { toast } from 'react-hot-toast';

const initialState = {
  currentStep: 0,
  id: 1,
  email: '',
  password: '',
  name: '',
  // language: '',
  // phone_number: "",
  business_name: '',
  short_name: '',
  support_email: '',
  website_url: '',
  address: '',
  address2: '',
  city: '',
  state: '',
  country: '',
  // role: "",
  // source: "",
  rc_number: '',
  logo_url: '',
  short_logo_url: '',
  error: '',
  isOnboadingDetailsStatus: 'idle',
  isSavingStatus: 'idle',
} as ICompleteRegisteration;

const OnboardingSlice = createSlice({
  name: 'OnboardingState',
  initialState,

  reducers: {
    resetOnboarding: (state) => {
      Object.assign(state, initialState);
    },
    resetErrorMessage: (state) => {
      state.error = initialState.error;
    },
    nextStep: (state) => {
      state.currentStep += 1;
    },
    prevStep: (state) => {
      state.currentStep -= 1;
    },
    gotoStep: (state, action: { payload: number }) => {
      state.currentStep = action.payload;
    },
    saveUserDetails: (state, action) => {
      state.email = action.payload.email;
      state.password = action.payload.password;
      state.name = action.payload.name;
      // state.phone_number = action.payload.phone_number;
      // state.language = action.payload.language;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(
      createUserAccountAction.pending,
      (state: ICompleteRegisteration) => {
        state.isSavingStatus = 'loading';
      }
    );
    builder.addCase(
      createUserAccountAction.fulfilled,
      (state: ICompleteRegisteration, { payload }) => {
        state.isSavingStatus = 'success';
        state.email = payload.email;
        state.error = initialState.error;
        state.currentStep = 2;
      }
    );
    builder.addCase(
      createUserAccountAction.rejected,
      (state: ICompleteRegisteration, action: any) => {
        state.error = action.payload;
        state.isSavingStatus = 'error';
        toast.error(action.payload);
      }
    );

    builder.addCase(
      updateBusinessAddressAction.pending,
      (state: ICompleteRegisteration) => {
        state.isSavingStatus = 'loading';
      }
    );
    builder.addCase(
      updateBusinessAddressAction.fulfilled,
      (state: ICompleteRegisteration) => {
        state.isSavingStatus = 'success';
        state.currentStep = 3;
      }
    );
    builder.addCase(
      updateBusinessAddressAction.rejected,
      (state: ICompleteRegisteration, action: any) => {
        state.error = action.payload;
        state.isSavingStatus = 'error';
        toast.error(action.payload);
      }
    );

    builder.addCase(
      updateBusinessDetailsAction.pending,
      (state: ICompleteRegisteration) => {
        state.isSavingStatus = 'loading';
        state.error = initialState.error;
      }
    );
    builder.addCase(
      updateBusinessDetailsAction.fulfilled,
      (state: ICompleteRegisteration, action: any) => {
        state.short_name = action.payload.short_name;
        state.isSavingStatus = 'success';
        state.currentStep = 2;
      }
    );
    builder.addCase(
      updateBusinessDetailsAction.rejected,
      (state: ICompleteRegisteration, action: any) => {
        state.error = action.payload;
        state.isSavingStatus = 'error';
        toast.error(action.payload);
      }
    );

    builder.addCase(
      updateBusinessBankActions.pending,
      (state: ICompleteRegisteration) => {
        state.isSavingStatus = 'loading';
        state.error = initialState.error;
      }
    );
    builder.addCase(
      updateBusinessBankActions.fulfilled,
      (state: ICompleteRegisteration) => {
        state.isSavingStatus = 'success';
        state.currentStep = 5;
      }
    );
    builder.addCase(
      updateBusinessBankActions.rejected,
      (state: ICompleteRegisteration, action: any) => {
        state.error = action.payload;
        state.isSavingStatus = 'error';
        toast.error(action.payload);
      }
    );

    builder.addCase(
      updateBusinessKYCAction.pending,
      (state: ICompleteRegisteration) => {
        state.isSavingStatus = 'loading';
        state.error = initialState.error;
      }
    );
    builder.addCase(
      updateBusinessKYCAction.fulfilled,
      (state: ICompleteRegisteration) => {
        state.isSavingStatus = 'success';
        state.currentStep = 4;
      }
    );
    builder.addCase(
      updateBusinessKYCAction.rejected,
      (state: ICompleteRegisteration, action: any) => {
        state.error = action.payload;
        state.isSavingStatus = 'error';
        toast.error(action.payload);
      }
    );

    builder.addCase(
      getOnboardingDetailsActions.pending,
      (state: ICompleteRegisteration) => {
        state.isOnboadingDetailsStatus = 'loading';
        state.error = initialState.error;
      }
    );
    builder.addCase(
      getOnboardingDetailsActions.fulfilled,
      (state: ICompleteRegisteration) => {
        state.isOnboadingDetailsStatus = 'success';
        state.isSavingStatus = 'success';
        state.currentStep = 5;
      }
    );
    builder.addCase(
      getOnboardingDetailsActions.rejected,
      (state: ICompleteRegisteration, action: any) => {
        state.error = action.payload;
        state.isOnboadingDetailsStatus = 'error';
        toast.error(action.payload);
      }
    );

    builder.addCase(
      resendOnboardingMailAction.pending,
      (state: ICompleteRegisteration) => {
        state.isSavingStatus = 'loading';
        state.error = initialState.error;
      }
    );
    builder.addCase(
      resendOnboardingMailAction.fulfilled,
      (state: ICompleteRegisteration) => {
        state.isSavingStatus = 'success';
      }
    );
    builder.addCase(
      resendOnboardingMailAction.rejected,
      (state: ICompleteRegisteration, action: any) => {
        state.error = action.payload;
        state.isSavingStatus = 'error';
        toast.error(action.payload);
      }
    );
  },
});

export const {
  resetOnboarding,
  nextStep,
  prevStep,
  gotoStep,
  saveUserDetails,
} = OnboardingSlice.actions;

export default OnboardingSlice.reducer;
