import React from 'react';
import './_checkbox.scss';
import { ReactComponent as Checked } from '../../../assets/icons/checked.svg';
import { ReactComponent as Unchecked } from '../../../assets/icons/unchecked.svg';

export interface CheckboxProps {
  value: boolean;
  variant?: 'fill' | 'no-fill';
  label?: string | React.ReactElement;
  labelClassName?: string;
  containerClassName?: string;
  name: string;
  id?: string;
  [rest: string]: any;
}

const Checkbox = (props: CheckboxProps) => {
  const {
    variant,
    value,
    name,
    id,
    label,
    labelClassName,
    containerClassName,
    ...rest
  } = props;
  return (
    <div className={`styled-checkbox-container ${containerClassName ?? ''}`}>
      <input
        data-variant={variant}
        className="styled-checkbox"
        name={name}
        id={id ?? name}
        type="checkbox"
        checked={value}
        {...rest}
      />
      <div className="styled-checkbox-icon">
        {value ? <Checked /> : <Unchecked />}
      </div>

      <label htmlFor={id ?? name} className={`${labelClassName ?? ''}`}>
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
