import React from 'react';
import './_session-timeout.scss';
import Modal, { ModalPosition } from '../../../components/common/modal/modal';
import FillButton, {
  ButtonColor,
} from '../../../components/common/buttons/button';

interface TimeoutProps {
  showTimeoutModal: boolean;
  setShowTimeoutModal: () => void;
  remainingTime: string;
}

function SessionTimeoutModal(props: TimeoutProps) {
  return (
    <Modal
      isOpen={props.showTimeoutModal}
      onClose={props.setShowTimeoutModal}
      showCloseIcon={false}
      modalPosition={ModalPosition.center}
      className="session-timeout-modal"
      title='Timeout'
      modalFooter={
        <FillButton
          colorVariant={ButtonColor.danger}
          onClick={props.setShowTimeoutModal}
        >
          Close
        </FillButton>
      }
    >
      <p>
        You are about to be logged out due to inactivity. <br />
        Close the modal to stay logged in
      </p>
      <br />
      <p>
        You would be logged out in <b>{props.remainingTime}</b>
      </p>
    </Modal>
  );
}

export default SessionTimeoutModal;
