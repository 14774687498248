import React from 'react';
import Input from '../../../components/input-fields/input/input';
import Select from '../../../components/input-fields/Select/select';
import { convertToOptions } from '../../../utils/form';

interface FilterModalProps {
  filter: Record<string, any>;
  setFilter: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  initialFilter: {
    page: number;
    limit: number;
  };
  handleFilter: (e: any) => void;
}

const TransactionFilterModal = ({ filter, handleFilter }: FilterModalProps) => {
  return (
    <>
      <Input
        name="startDate"
        type="date"
        label="Start Date"
        value={filter.startDate ?? ''}
        max={new Date().toISOString().split('T')[0]}
        onChange={handleFilter}
      />

      <Input
        name="endDate"
        type="date"
        label="End Date"
        value={filter.endDate ?? ''}
        max={new Date().toISOString().split('T')[0]}
        onChange={handleFilter}
      />

      <Select
        name="status"
        label="Transaction Status"
        onChange={(val) => {
          handleFilter({ name: 'status', value: val.value });
        }}
        selectOptions={convertToOptions(['Successful', 'Failed'])}
        value={convertToOptions([filter?.status])[0]}
        selectPlaceholder={filter?.status}
        isSearchable={true}
      />
    </>
  );
};

export default TransactionFilterModal;
