import { IWalletState } from "./wallet.interface";
import { createSlice } from "@reduxjs/toolkit";
import {
  getAllTransactionsAction,
  getWalletInfoAction,
} from "./wallet.actions";

const initialState = {
  allTransactions: [],
  totalTransationSize: 0,
  account_number: "",
  account_name: "",
  bank_name: "",
  balance: 0,
  low_wallet_threhold: 0,
  isLoading: false,
  isTransactionsLoading: false,
  error: "",
} as IWalletState;

const WalletSlice = createSlice({
  name: "WalletState",
  initialState,

  reducers: {},

  extraReducers: (builder) => {
    //Transaction Logs
    builder.addCase(getAllTransactionsAction.pending, (state: IWalletState) => {
      state.isTransactionsLoading = true;
    });
    builder.addCase(
      getAllTransactionsAction.fulfilled,
      (state: IWalletState, action: any) => {
        state.allTransactions = action.payload.result;
        state.totalTransationSize = action.payload.page_info.total;
        state.isTransactionsLoading = false;
      }
    );
    builder.addCase(
      getAllTransactionsAction.rejected,
      (state: IWalletState, action: any) => {
        state.error = action.payload;
        state.isTransactionsLoading = false;
      }
    );

    //Wallet Logs
    builder.addCase(getWalletInfoAction.pending, (state: IWalletState) => {
      if (state.account_number === "" && state.account_name === "") {
        state.isLoading = true;
      }
    });
    builder.addCase(
      getWalletInfoAction.fulfilled,
      (state: IWalletState, action: any) => {
        state.account_name = action.payload.virtual_accounts[0].account_name;
        state.bank_name = action.payload.virtual_accounts[0].bank_name;
        state.account_number =
          action.payload.virtual_accounts[0].account_number;
        state.balance = action.payload.wallet.balance;
        const settings = JSON.parse(action.payload.wallet?.settings);
        state.low_wallet_threhold = settings.second_threshold;
        state.isLoading = false;
      }
    );
    builder.addCase(
      getWalletInfoAction.rejected,
      (state: IWalletState, action: any) => {
        state.error = action.payload;
        state.isLoading = false;
      }
    );
  },
});


export default WalletSlice.reducer;
