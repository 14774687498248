import React from 'react';
import './_dropdown.scss';
import { DropdownOptionProps } from './dropdown.interface';

export default function DropdownOption(props: DropdownOptionProps) {
  const { className, children, onClick, label, icon, ...rest } = props;

  return (
    <div
      {...rest}
      className={`${className ?? ''} dropdown-option`}
      data-testid="dropdown-option"
      onClick={onClick}
    >
      {icon && <span className="dropdown-option-icon">{icon}</span>} {label}
      {children}
    </div>
  );
}
