// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import './sort-reorder.scss';

export const SortableItem = sortableElement(
  ({ children, className, rest }: any) => {
    return (
      <li className={`sort-item ${className}`} {...rest}>
        {children}
      </li>
    );
  }
);

export const SortableContainer = sortableContainer(
  ({ children, className, containerRef, rest }: any) => {
    return (
      <ul
        ref={containerRef}
        className={`sort-container ${className}`}
        {...rest}
      >
        {children}
      </ul>
    );
  }
);
