import React, { Dispatch, SetStateAction } from 'react';
import Modal, { ModalPosition, ModalSize } from '../common/modal/modal';
import { ReactComponent as SuccessIcon } from '../../assets/icons/checked.svg';
import Style from './_preset-modal.module.scss';
import ModalFooter from '../common/modal/modal-footer';
import FillButton from '../common/buttons/button';

type Props = {
  isOpen: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
  message: string | React.ReactElement;
  title: string;
  description?: string;
  continueBtnLabel?: string;
  continueAction: () => void;
};

function SuccessModal(props: Props) {
  const {
    isOpen,
    onClose,
    title,
    message,
    description,
    continueBtnLabel = 'Continue',
    continueAction,
  } = props;

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      onClose={onClose}
      modalPosition={ModalPosition.center}
      modalSize={ModalSize.md}
      contentClassName={Style.success_modal}
      modalFooter={
        <ModalFooter>
          <FillButton label={continueBtnLabel} onClick={continueAction} />
        </ModalFooter>
      }
    >
      <div className={Style.icon}>
        <SuccessIcon />
      </div>

      <p className="font-600">{message ?? 'Success'}</p>
      {description && <p className="sm">{description}</p>}
    </Modal>
  );
}

export default SuccessModal;
