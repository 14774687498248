import React, { useEffect, useState } from 'react';
import Modal, { ModalPosition } from '../common/modal/modal';
import PinField from '../input-fields/pin/pin';
import './_mfa-input.scss';
import FillButton, { ButtonColor } from '../common/buttons/button';
import Input from '../input-fields/input/input';

type MfaTypes = {
  showMFA: boolean;
  closeMFA: () => void;
  isLoading?: boolean;
  onComplete: (value: string | number, index?: number) => void;
  onChange: (value: string | number, index?: number) => void;
  value: string;
  canUseRecovery?: boolean;
  error?: boolean;
  otpUssd?: {
    code: string;
    countdown: string;
  };
  description?: string;
};

function MfaInput({
  showMFA,
  closeMFA,
  isLoading,
  onComplete,
  onChange,
  value,
  otpUssd,
  canUseRecovery = true,
  error = false,
  description = 'Enter the OTP sent to your email or the token from your authenticator app to validate your login.',
}: Readonly<MfaTypes>) {
  const [isRecovery, setIsRecovery] = useState(false);

  const toggleRecovery = () => {
    setIsRecovery(!isRecovery);
    onChange('');
  };

  const handleClose = () => {
    closeMFA();
    onChange('');
    setIsRecovery(false);
  };

  const [showOtpUssdCode, setShowOtpUssdCode] = useState(false);

  useEffect(() => {
    if (
      otpUssd?.countdown &&
      otpUssd.code &&
      !Number.isNaN(otpUssd?.countdown)
    ) {
      const timer = setTimeout(
        () => setShowOtpUssdCode(true),
        Number(otpUssd?.countdown) * 1000
      );
      return () => clearTimeout(timer);
    }
  }, [otpUssd?.countdown]);

  return (
    <Modal
      isOpen={showMFA}
      onClose={handleClose}
      modalPosition={ModalPosition.center}
      className="mfa-modal"
      title="Two-Factor Authentication is enabled"
      contentClassName="mfa-modal-body"
      animated={false}
      modalFooter={
        <div className="mfa-button">
          <FillButton
            type="button"
            variant="outlined"
            onClick={handleClose}
            className="mfa-close-button"
            label="Cancel"
            colorVariant={ButtonColor.neutral}
            disabled={isLoading}
          />

          <FillButton
            isLoading={isLoading}
            onClick={() => {
              onComplete(value);
            }}
            disabled={!isRecovery}
            label="Confirm"
            loadingText="Confirming"
          />
        </div>
      }
    >
      <p className="sub-heading">
        {isRecovery ? 'Enter one of your recovery codes.' : description}
      </p>

      {!isRecovery ? (
        <PinField
          length={6}
          value={value}
          onChange={(value) => {
            onChange(value);
          }}
          onComplete={(value) => {
            onComplete(value);
          }}
          error={error}
          errorMessage="Invalid authentication code. Please try again."
        />
      ) : (
        <Input
          autoFocus={true}
          value={value}
          onChange={(e: any) => {
            onChange(e.target.value);
          }}
          error={error}
          errorMessage="Invalid authentication code. Please try again."
        />
      )}

      {canUseRecovery && !otpUssd?.code && (
        <p className="sub-heading sm">
          {isRecovery
            ? 'Found one of your 2FA devices? '
            : 'Lost all 2FA devices? '}

          <FillButton
            style={{ fontWeight: 400, marginLeft: '6px' }}
            variant="ghost"
            onClick={toggleRecovery}
            label={
              isRecovery ? ' Login with token' : ' Login with a recovery code'
            }
          />
        </p>
      )}

      {otpUssd?.code && showOtpUssdCode && (
        <p className="sub-heading sm">
          SMS yet to arrive? Dial{' '}
          <a href={`tel:${otpUssd?.code}`}>
            <FillButton
              style={{ fontWeight: 400, marginLeft: '6px', marginRight: '6px' }}
              variant="ghost"
              onClick={toggleRecovery}
              label={` ${otpUssd?.code} `}
            />
          </a>
        </p>
      )}
    </Modal>
  );
}

export default MfaInput;
