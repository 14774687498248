import React from 'react';
import './_wallet.scss';
import Card from '../../../components/common/cards/cards';
import CardBody from '../../../components/common/cards/card-body';
import { useAppSelector } from '../../../store/customHooks';
import { currencyFormat } from '../../../utils/formatter';
import Tooltip from '../../../components/common/tooltip/tooltip';

function WalletBalanceCard() {
  const walletInfo = useAppSelector((state) => state.wallet);

  return (
    <Card className="wallet-balance-card-container">
      <CardBody className="wallet-balance-card">
        <div>
          <div className="md">Wallet balance</div>
          <div className="wallet-amount font-600">
            {currencyFormat(walletInfo.balance)}
          </div>
        </div>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span className="sm low-wallet">
            Low wallet threshold:{' '}
            {currencyFormat(walletInfo.low_wallet_threhold)}
          </span>{' '}
          <Tooltip
            className="billing-account-tooltip"
            content="This is the minimum amount required in your wallet to run your services. For smooth running, always ensure your wallet is always topped up significantly above this value."
          />
        </div>
      </CardBody>
    </Card>
  );
}

export default WalletBalanceCard;
