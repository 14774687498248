import React from 'react';
import './_status.scss';

import { ReactComponent as SuccessIcon } from '../../assets/icons/success-checkmark.svg';

export interface SlidepaneStatusProps {
  onContinue: () => void;
  message?: string | JSX.Element;
  className?: string;
}

function SlidepaneStatus(props: SlidepaneStatusProps) {
  return (
    <div
      className={`fund-wallet-status ${props.className ? props.className : ''}`}
    >
      <div className="status">
        <div className="icon">
          <SuccessIcon />
        </div>

        <p className="message">{props.message}</p>
      </div>
    </div>
  );
}

export default SlidepaneStatus;
