import React, { useState, useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AuthLayout from '../../../layout/authLayout/authLayout';
import './_login.scss';

//StateManager
import { useAppDispatch, useAppSelector } from '../../../store/customHooks';
import { loginUserAction } from '../../../store/auth/auth.actions';

//components
import Input from '../../../components/input-fields/input/input';
import FillButton from '../../../components/common/buttons/button';
import { useFormik } from 'formik';
import { LoginSchema } from './login-validation-schema';

//images
import { resetLogin } from '../../../store/auth/auth.reducer';
import urls from '../../../routes/all-routes';
import MfaInput from '../../../components/mfa-input/mfa-input';
import { resetOnboarding } from '../../../store/onboarding/onboarding.reducer';

const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loginState = useAppSelector((state) => state.auth);
  const [showMFA, setShowMFA] = useState<boolean>(false);
  const [otpUssd, setOtpUssd] = useState({
    code: '',
    countdown: '',
  });
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      token: '',
    },

    validationSchema: LoginSchema,

    onSubmit: async (values) => {
      setLoading(true);
      if (loginState.error !== '') {
        dispatch(resetLogin());
      }
      dispatch(loginUserAction(values))
        .unwrap()
        .then((res) => {
          if (res.twofa) {
            setShowMFA(true);
          }
          if (res.otp_ussd_code) {
            setOtpUssd({
              code: res.otp_ussd_code,
              countdown: res.otp_ussd_code_countdown,
            });
          }
        })
        .catch((error) => {
          if (!loginState.error?.toLowerCase()?.includes('invalid')) {
            setShowMFA(false);
          }
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    dispatch(resetLogin());
  }, []);

  useMemo(() => {
    if (loginState.access_token && loginState.isAuthenticated) {
      const lastLocation = sessionStorage.getItem('adjutor:lastLocation');
      if (lastLocation) {
        navigate(`${lastLocation}`);
        sessionStorage.removeItem('adjutor:lastLocation');
      } else {
        return navigate(urls.home.index);
      }
    }
  }, [loginState]);

  return (
    <AuthLayout>
      <div className="login-page">
        <div>
          <h6>Welcome back</h6>

          <p className="sm">
            Don’t have an account?{' '}
            <Link
              to={urls.signup.index}
              onClick={() => {
                dispatch(resetOnboarding());
              }}
              className=""
            >
              <FillButton label="Sign Up" variant="ghost" />
            </Link>
          </p>
        </div>

        <form onSubmit={formik.handleSubmit}>
          <Input
            label="Email Address"
            type="email"
            name="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            errorMessage={formik.errors.email}
            error={formik.touched.email && formik.errors.email}
          />

          <div>
            <Input
              label="Password"
              type="password"
              name="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              errorMessage={formik.errors.password}
              error={formik.touched.password && formik.errors.password}
            />
            {loginState.error && (
              <p>
                <label className="error-message">{loginState.error}</label>
              </p>
            )}

            <Link type="button" to="/forgot-password">
              <FillButton
                className="forgot-pwd sm"
                label="Forgot password?"
                variant="ghost"
                type="button"
              />
            </Link>
          </div>

          <FillButton
            type="submit"
            disabled={!(formik.dirty && formik.isValid)}
            isLoading={loading}
            label="Login"
            className="auth-button"
          />
        </form>
      </div>

      <MfaInput
        showMFA={showMFA}
        closeMFA={() => {
          setShowMFA(false);
          formik.setFieldValue('token', '');
        }}
        value={formik.values.token}
        onChange={(value) => {
          formik.setFieldValue('token', value);
        }}
        onComplete={(value) => {
          setLoading(true);
          const payload = {
            email: formik.values.email,
            password: formik.values.password,
            token: value,
          };
          dispatch(loginUserAction(payload))
            .unwrap()
            .finally(() => {
              setLoading(false);
            });
        }}
        otpUssd={otpUssd}
        isLoading={loading}
        error={loginState.error?.toLowerCase()?.includes('invalid')}
      />
    </AuthLayout>
  );
};

export default LoginPage;
