import * as Yup from 'yup';
import { PasswordSchema } from '../../../utils/helper';

export const ForgetPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
});

export const ResetPasswordSchema = Yup.object().shape({
  new_password: PasswordSchema,
});
