import React, { useState } from 'react';
import { IDecisionCheckFieldProps } from '../render-model-config-options';
import Checkbox from '../../../input-fields/checkbox/checkbox';
import { useFormikContext } from 'formik';

function ConfigurationCheckField(props: Readonly<IDecisionCheckFieldProps>) {
  const { id, check } = props;
  const {setFieldValue} = useFormikContext()

  const [data, setData] = useState({
    value: check,
  });

  const handleChange = () => {
    setData({ value: !data.value });
    setFieldValue(`${id}.value`, !data.value)
  };

  return (
    <div className="configuration-field">
      <Checkbox
        variant="no-fill"
        name={id}
        label={id?.replace(/_/g, ' ')}
        value={data.value}
        onChange={handleChange}
      />
    </div>
  );
}

export default ConfigurationCheckField;
