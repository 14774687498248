import * as Yup from 'yup';
import {
  urlValidation,
  urlValidationErrorMessage,
} from '../../../utils/helper';

export const CreateAppSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  webhook_url: Yup.string()
    .nullable()
    .notRequired()
    .test('is_uri', urlValidationErrorMessage, urlValidation),
  callback_url: Yup.string()
    .nullable()
    .notRequired()
    .test('is_uri', urlValidationErrorMessage, urlValidation),
  services: Yup.array().required('Required'),
  logo_url: Yup.mixed(),
});
