import React, { useCallback, useMemo, useState, useEffect } from 'react';
import './_models.scss';

//components
import Card from '../../../components/common/cards/cards';
import Table from '../../../components/table/table';
import TableRow from '../../../components/table/tablebody';
import DropdownOption from '../../../components/common/dropdown/dropdown-option';
import DropdownMenu from '../../../components/common/dropdown/dropdown-menu';
import AnayticsCard from '../../../components/analytics-card/analytics-card';

//Icons
import { ReactComponent as AddIcon } from '../../../assets/icons/plus.svg';
import { ReactComponent as MoreIcon } from '../../../assets/icons/more.svg';
import MobileTable from '../../../components/table/mobile-table/mobile-table';
import MobileTableRow from '../../../components/table/mobile-table/mobile-tablebody';
import { useAppDispatch, useAppSelector } from '../../../store/customHooks';
import {
  // allDecisionModelsNoPagination,
  deleteModelAction,
  duplicateModelAction,
  getAllModelsAction,
  getDecisionModelAggregate,
} from '../../../store/decision-model/decision-model.actions';
import Pagination from '../../../components/common/pagination/Pagination';
import {
  IDecisionModelAggregate,
  IDecisionModels,
  IModel,
} from '../../../store/decision-model/decision-model.interface';
import EmptyTable from '../../../components/empty-table/EmptyTable';
import { useNavigate } from 'react-router-dom';
import urls from '../../../routes/all-routes';
import FillButton, {
  ButtonColor,
} from '../../../components/common/buttons/button';
import Modal, { ModalPosition } from '../../../components/common/modal/modal';
import CreateModelPane from '../../../components/decision-model/create-model/create-model';
import ModalFooter from '../../../components/common/modal/modal-footer';
import PageTitle from '../../../components/common/page-title';
import CardFooter from '../../../components/common/cards/card-footer';
import useScreenSizeHook from '../../../hooks/useScreenSizeHook';

function DecisionModelListPage() {
  const { isDesktop } = useScreenSizeHook();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const decisionModel: IDecisionModels = useAppSelector(
    (state) => state.decisionModel
  );

  const decisionModelTotal: IDecisionModelAggregate = useAppSelector(
    (state) => state.decisionModelAggregate
  );

  const activeDecisionModels: IModel[] | undefined =
    decisionModelTotal?.decisionModelAggregate?.filter(
      (obj: any) => obj.status === 'active'
    );
  const activeModelCount = activeDecisionModels
    ? activeDecisionModels.length
    : 0;

  const inactiveDecisionModels: IModel[] | undefined =
    decisionModelTotal?.decisionModelAggregate?.filter(
      (obj: any) => obj.status === 'draft'
    );
  const inactiveModelCount = inactiveDecisionModels
    ? inactiveDecisionModels.length
    : 0;

  const allModelsCount: number = activeModelCount + inactiveModelCount;

  const initialDeleteModelState = {
    show: false,
    modelId: null,
    modelName: null,
  };
  const [showDeleteModel, setShowDeleteModel] = useState<{
    show: boolean;
    modelId: number | null;
    modelName: string | null;
  }>(initialDeleteModelState);

  const [showCreateModelPane, setShowCreateModelPane] =
    useState<boolean>(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const fetchAllModels = useCallback(async () => {
    await dispatch(
      getAllModelsAction({ page: currentPage - 1, limit: perPage })
    );

    await dispatch(getDecisionModelAggregate({ limit: 400 }));
  }, [perPage, currentPage]);

  const loadPage = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  const editModel = (id: number) => {
    navigate(urls.business.decision_model.for(`${id}`));
  };
  const deleteModel = (id: number, name: string) => {
    setShowDeleteModel({ show: true, modelId: id, modelName: name });
  };

  const duplicateModel = (id: number) => {
    dispatch(duplicateModelAction(id));
  };

  useEffect(() => {
    if (decisionModel.focusModelStatus === 'duplicated') {
      navigate(
        urls.business.decision_model.for(`${decisionModel.focusModel.id}`)
      );
    }

    dispatch(getDecisionModelAggregate({ limit: 400 }));
  }, [decisionModel.focusModelStatus, deleteModelAction, duplicateModelAction]);

  useMemo(() => {
    if (decisionModel.deleteModelStatus === 'success') {
      setShowDeleteModel(initialDeleteModelState);
    }
  }, [decisionModel.deleteModelStatus]);

  return (
    <div className="models-page">
      <PageTitle
        title="Decision Models"
        rightElement={
          <div className="create-modal-button">
            <FillButton
              onClick={() => {
                setShowCreateModelPane(!showCreateModelPane);
              }}
            >
              <span className="icon">
                <AddIcon />
              </span>{' '}
              Create a Model
            </FillButton>
          </div>
        }
      />

      <div className="analytics-grid">
        <AnayticsCard
          className="analytics-card"
          title="All Decision Models"
          value={allModelsCount || '0'}
          isEmpty={false}
          activeFilter={''}
        />

        <AnayticsCard
          className="analytics-card"
          title="Active Decision Models"
          value={activeModelCount}
          isEmpty={false}
          activeFilter={''}
        />

        <AnayticsCard
          className="analytics-card"
          title="Inactive Decision Models"
          value={inactiveModelCount}
          isEmpty={false}
          activeFilter={''}
        />
      </div>

      <Card className="models-table">
        {isDesktop ? (
          <Table
            title="Decision Models"
            tableHeader={['Model', 'Description', 'Versions', 'Modules']}
            more={true}
            isLoading={decisionModel.allModuleStatus === 'loading'}
            isEmpty={decisionModel?.allModels?.length === 0}
            emptyStateComponent={
              <EmptyTable
                message="No Models Created"
                additional_message="Your Models will appear here"
              />
            }
          >
            {decisionModel.allModels.map((model) => {
              return (
                <TableRow
                  key={model.id}
                  onRowClickAction={() => {
                    editModel(model?.id);
                  }}
                  moreOption={
                    <DropdownMenu
                      renderButton={<MoreIcon />}
                      className="action-dropdown"
                      id={`dropdown-menu-${model.id}`}
                    >
                      <DropdownOption
                        onClick={() => {
                          editModel(model?.id);
                        }}
                      >
                        Edit
                      </DropdownOption>
                      <DropdownOption
                        onClick={() => {
                          duplicateModel(model.id);
                        }}
                      >
                        Duplicate
                      </DropdownOption>
                      <DropdownOption
                        onClick={() => {
                          deleteModel(model.id, model.name);
                        }}
                      >
                        Delete
                      </DropdownOption>
                    </DropdownMenu>
                  }
                >
                  <td>{model?.name || '-'}</td>

                  <td>{model?.description || '-'}</td>

                  <td>{model?.version_id || '-'}</td>

                  <td>{Object.keys(model?.decision_setting).length || 0}</td>
                </TableRow>
              );
            })}
          </Table>
        ) : (
          <MobileTable
            title="Decision Models"
            isEmpty={decisionModel.allModels?.length === 0}
            isLoading={decisionModel.allModuleStatus === 'loading'}
            emptyStateComponent={
              <EmptyTable
                message="No Models Created"
                additional_message="Your Models will appear here"
              />
            }
          >
            {decisionModel.allModels.map((model) => {
              return (
                <MobileTableRow
                  key={model.id}
                  onRowClickAction={() => editModel(model?.id)}
                  moreOption={
                    <DropdownMenu
                      renderButton={<MoreIcon />}
                      className="action-dropdown"
                      id={`dropdown-menu-${model.id}`}
                    >
                      <DropdownOption
                        onClick={() => {
                          editModel(model?.id);
                        }}
                      >
                        Edit
                      </DropdownOption>
                      <DropdownOption
                        onClick={() => {
                          duplicateModel(model.id);
                        }}
                      >
                        Duplicate
                      </DropdownOption>
                      <DropdownOption
                        onClick={() => {
                          deleteModel(model.id, model.name);
                        }}
                      >
                        Delete
                      </DropdownOption>
                    </DropdownMenu>
                  }
                >
                  <div className="field-container">
                    <span className="field-title">Model: </span>
                    {model?.name}
                  </div>

                  <div className="field-container">
                    <span className="field-title">Description: </span>
                    {model?.description || '-'}
                  </div>

                  <div className="field-container">
                    <span className="field-title">Version: </span>
                    {model?.version_id || '-'}
                  </div>

                  <div className="field-container">
                    <span className="field-title">Modules: </span>
                    {Object.keys(model?.decision_setting).length || 0}
                  </div>
                </MobileTableRow>
              );
            })}
          </MobileTable>
        )}

        <CardFooter>
          <Pagination
            total={decisionModel?.totalModelSize}
            perPage={perPage}
            setPerPage={setPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            loadPage={loadPage}
            perPageTitle="Models"
            updateDataCallback={fetchAllModels}
          />
        </CardFooter>
      </Card>

      <Modal
        isOpen={showDeleteModel.show}
        title="Delete Model"
        onClose={() => {
          setShowDeleteModel(initialDeleteModelState);
        }}
        className="delete-model-modal"
        modalPosition={ModalPosition.center}
        modalFooter={
          <ModalFooter>
            <FillButton
              onClick={() => {
                setShowDeleteModel(initialDeleteModelState);
              }}
              colorVariant={ButtonColor.secondary}
              className="close-button"
              disabled={decisionModel.deleteModelStatus === 'loading'}
            >
              No, Cancel
            </FillButton>

            <FillButton
              onClick={() => {
                if (showDeleteModel.modelId) {
                  dispatch(deleteModelAction(showDeleteModel.modelId));
                }
              }}
              colorVariant={ButtonColor.danger}
              variant="outlined"
              isLoading={decisionModel.deleteModelStatus === 'loading'}
            >
              Yes, Delete
            </FillButton>
          </ModalFooter>
        }
      >
        <p>
          Are you sure you want to delete <b>{showDeleteModel.modelName}</b>
        </p>
      </Modal>

      <CreateModelPane
        isOpen={showCreateModelPane}
        onClose={setShowCreateModelPane}
      />
    </div>
  );
}

export default DecisionModelListPage;
