import React from 'react';
import Styles from './_analytics-card.module.scss';
import Card from '../common/cards/cards';
import CardBody from '../common/cards/card-body';
import CardHeader from '../common/cards/card-header';
import NoActivityCard from '../../pages/dashboard/no-activity-card';

export interface AnalyticsCardProps {
  title: string;
  activeFilter: string;
  value: string | number | React.ReactElement;
  footer?: string;
  className?: string;
  isEmpty: boolean;
}

function AnayticsCard({
  title,
  value,
  className,
  footer = ' ',
  activeFilter="",
  isEmpty,
}: AnalyticsCardProps) {
  return (
    <Card
      className={`${Styles.metrics_card} ${className ?? ''}`}
      cardHeading={<CardHeader title={title} titleClassName="sm" additiionalButton={<>{activeFilter}</>} />}
    >
      <CardBody className={`${Styles.metrics_card_body}`}>
        {isEmpty ? (
          <NoActivityCard />
        ) : (
          <>
            <p
              className={`font-Montserrat text-text-primary font-600 ${Styles.value}`}
            >
              {value}
            </p>
            <div className="footer">{footer}</div>
          </>
        )}
      </CardBody>
    </Card>
  );
}

export default AnayticsCard;
