import React from 'react';
import Style from './_card.module.scss';
import { ReactComponent as ArrowDown } from '../../../assets/icons/arrowhead-down.svg';

type Props = {
  title: string;
  titleClassName?: string;
  description?: string;
  collapsible?: boolean;
  toggleCollapse?: () => void;
  additiionalElement?: React.ReactElement;
  additiionalButton?: React.ReactElement;
};

function CardHeader({
  title,
  description,
  titleClassName,
  collapsible,
  additiionalElement,
  additiionalButton,
  toggleCollapse,
}: Props) {
  return (
    <div>
      <div className={Style.card_header}>
        <div>
          <p
            className={`font-600 text-text-primary ${Style.card_header_title} ${
              titleClassName ?? ''
            }`}
          >
            {title}
          </p>
          {description && <p className="sm">{description}</p>}
        </div>

        <div className={Style.right_elements}>
          {additiionalButton}
          {collapsible && (
            <button onClick={toggleCollapse} className="collapse_icon">
              <ArrowDown />
            </button>
          )}
        </div>
      </div>

      {additiionalElement}
    </div>
  );
}

export default CardHeader;
