import { IUtilityState } from './utility.interface';
import { createSlice } from '@reduxjs/toolkit';
import {
  getAllAppServicesAction,
  getAllBankAction,
  getAllLanguages,
  getAllStates,
  getLGAoptions,
} from './utility.actions';
import {
  convertToUtilityOptions,
  convertToUtilityOptions_Additional_Code,
} from '../../utils/form';

const initialState = {
  allOptions: [],
  _options: [],
  adjutorServicesOptions: [],
  isLoading: false,
  loadingAdjutorOptions: false,
  error: '',
} as IUtilityState;

const UtilitySlice = createSlice({
  name: 'UtilityState',
  initialState,

  reducers: {
    resetUtility: (state) => {
      Object.assign(state, initialState);
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getAllBankAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllBankAction.fulfilled, (state, action: any) => {
      state.allOptions.push({
        key: action.payload.key,
        options: convertToUtilityOptions_Additional_Code(
          action.payload.options
        ),
      });
      state.isLoading = false;
    });
    builder.addCase(getAllBankAction.rejected, (state, action) => {
      state.error = action.payload as string;
      state.isLoading = false;
    });

    builder.addCase(getAllStates.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllStates.fulfilled, (state, action: any) => {
      state.allOptions.push({
        key: action.payload.key,
        options: convertToUtilityOptions(action.payload.options),
      });
      state.isLoading = false;
    });
    builder.addCase(getAllStates.rejected, (state, action) => {
      state.error = action.payload as string;
      state.isLoading = false;
    });

    builder.addCase(getAllLanguages.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllLanguages.fulfilled, (state, action: any) => {
      state.allOptions.push({
        key: action.payload.key,
        options: convertToUtilityOptions(action.payload.options),
      });
      state.isLoading = false;
    });
    builder.addCase(getAllLanguages.rejected, (state, action) => {
      state.error = action.payload as string;
      state.isLoading = false;
    });

    builder.addCase(getLGAoptions.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getLGAoptions.fulfilled, (state, action) => {
      state.allOptions.push({
        key: action.payload.key,
        options: convertToUtilityOptions(action.payload.options),
      });
      state.isLoading = false;
    });
    builder.addCase(getLGAoptions.rejected, (state, action) => {
      state.error = action.payload as string;
      state.isLoading = false;
    });

    builder.addCase(getAllAppServicesAction.pending, (state) => {
      state.loadingAdjutorOptions = true;
    });
    builder.addCase(getAllAppServicesAction.fulfilled, (state, action) => {
      state.loadingAdjutorOptions = false;
      if (action.payload) {
        state.adjutorServicesOptions = action.payload;
      }
    });
    builder.addCase(getAllAppServicesAction.rejected, (state) => {
      state.loadingAdjutorOptions = false;
    });
  },
});

export const { resetUtility } = UtilitySlice.actions;

export default UtilitySlice.reducer;
