import { createAsyncThunk } from '@reduxjs/toolkit';
import { callApi } from '../../config/apiSetup';
import {
  getMetaDataUrl,
  getMetaDataSubUrl,
  postEventTaggingUrl,
  getLanguages,
  getSettingValue,
} from '../../config/allUrl';
import { IEventTagging } from './utility.interface';
import { toast } from 'react-hot-toast';
import { fullDateTimeFormat } from '../../utils/formatter';
import { defaultErrorMessage } from '../../utils/helper';
import { CONSTANTS } from '../../config/constants';
import Sentry from '../../config/sentrySetup';

export const getAllAppServicesAction = createAsyncThunk(
  'settings/getAllAppServicesUrl',
  async (dispatch, ThunkApi) => {
    try {
      const res = await callApi.get(
        getSettingValue(CONSTANTS.SETTINGS_SLUG.ALLOWED_ADJUTOR_SERVICES)
      );
      const data =
        typeof res?.data?.settings_value === 'string'
          ? JSON.parse(res?.data?.settings_value)
          : res?.data?.settings_value;

      if (!Array.isArray(data)) {
        Sentry.captureException(`Adjutor services must be an array: ${data}`);
        ThunkApi.abort();
        return;
      }

      return data || [];
    } catch (error: any) {
      return ThunkApi.abort();
    }
  }
);

export const getAllBankAction = createAsyncThunk(
  'utility/all_banks',
  async () => {
    try {
      const optionField = 'bank';
      const response = await callApi.get(getMetaDataUrl(optionField));
      return { key: optionField, options: response.data };
    } catch (error: any) {
      const message: string = error.response.data?.message || error?.message;
      toast.error(message ?? defaultErrorMessage);
      return message;
    }
  }
);

export const getAllStates = createAsyncThunk('utility/all_states', async () => {
  try {
    const optionField = 'state';
    const response = await callApi.get(getMetaDataUrl(optionField));
    return { key: optionField, options: response.data };
  } catch (error: any) {
    const message: string = error.response.data?.message || error?.message;
    toast.error(message ?? defaultErrorMessage);
    return message;
  }
});

export const getAllLanguages = createAsyncThunk(
  'utility/all_languages',
  async () => {
    try {
      const response = await callApi.get(getLanguages);
      return { key: `language`, options: response.data };
    } catch (error: any) {
      const message: string = error.response.data?.message || error?.message;
      toast.error(message ?? defaultErrorMessage);
      return message;
    }
  }
);

export const getLGAoptions = createAsyncThunk(
  'utility/cities',
  async (state_ref: string, ThunkAPI) => {
    try {
      const optionField = 'local-government';
      const response = await callApi.get(
        getMetaDataSubUrl(optionField, state_ref)
      );

      return { key: `LGA_${state_ref}`, options: response.data };
    } catch (error: any) {
      const message: string = error.response.data?.message || error?.message;
      toast.error(message ?? defaultErrorMessage);

      return ThunkAPI.rejectWithValue(message);
    }
  }
);

export const eventTaggingAction = (event: {
  event_name: string;
  description: string;
}) => {
  const authData = sessionStorage.getItem('adjutor-auth');
  if (authData) {
    const auth = JSON.parse(authData);

    const payload: IEventTagging = {
      ...event,
      user_id: auth?.userId,
      org_id: auth?.org_id,
      session_id: auth?.sessionId,
      app_name: 'Adjutor WebApp',
      time: fullDateTimeFormat(new Date()),
    };

    try {
      callApi.post(postEventTaggingUrl, payload);
    } catch (error: any) {
      const message: string = error.response.data?.message || error?.message;
      console.log(message);
    }
  }
};
