import * as Yup from "yup";
import {
  phoneNumberValidationErrorMessage,
  phoneNumberValidationRegex,
  urlValidationErrorMessage,
  urlValidationRegex,
} from "../../../utils/helper";

export const updateCompanyDetails = Yup.object().shape({
  name: Yup.string().required("Required"),
  primary_email: Yup.string().email("Invalid email").required("Required"),
  support_email: Yup.string().email("Invalid email").required("Required"),
  support_phone: Yup.string()
    .matches(phoneNumberValidationRegex, phoneNumberValidationErrorMessage)
    .required("Required")
    .required("Required"),
  address: Yup.string().required("Required"),
  website_url: Yup.string().matches(urlValidationRegex, urlValidationErrorMessage).notRequired(),
});

export const updateCompanyLogo = Yup.object().shape({
  logo_url: Yup.mixed(),
  short_logo_url: Yup.mixed(),
});
