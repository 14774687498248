import React from 'react';
import './_wallet.scss';

import CardBody from '../../../components/common/cards/card-body';
import Card from '../../../components/common/cards/cards';
import { useAppSelector } from '../../../store/customHooks';
import ClickToCopy from '../../../components/click-to-copy/click-to-copy';
import Tooltip from '../../../components/common/tooltip/tooltip';

function BillingAccountCard() {
  const walletInfo = useAppSelector((state) => state.wallet);

  return (
    <Card>
      <CardBody className="billing-account-info-conatiner">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span className="md text-text-primary">Billing Account</span>{' '}
          <Tooltip
            className="billing-account-tooltip"
            content="This is your service account details. Fund this account to top up your wallet."
          />
        </div>

        <div className="billing-account-info">
          <div className="bill-account-data">
            <div className="xs">Account Name</div>
            <div className="sm text-text-primary">
              {walletInfo.account_name}
            </div>
          </div>

          <div className="bill-account-data">
            <div className="xs">Account Number</div>
            <div className="acc-num sm text-text-primary">
              {walletInfo.account_number}{' '}
              <span className="icon">
                <ClickToCopy text={walletInfo.account_number} />{' '}
              </span>
            </div>
          </div>

          <div className="bill-account-data">
            <div className="xs">Bank</div>
            <div className="sm text-text-primary">{walletInfo.bank_name}</div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

export default BillingAccountCard;
