import React, { useCallback, useState } from 'react';
import Chip from '../../../../components/common/chip/chip';
import { requestColorMatch } from '../../../../utils/helper';
import {
  currencyFormat,
  fullDateFormat,
  truncateString,
} from '../../../../utils/formatter';
import MobileTableRow from '../../../../components/table/mobile-table/mobile-tablebody';
import EmptyTable from '../../../../components/empty-table/EmptyTable';
import MobileTable from '../../../../components/table/mobile-table/mobile-table';
import Card from '../../../../components/common/cards/cards';
import TableRow from '../../../../components/table/tablebody';
import Table from '../../../../components/table/table';
import useScreenSizeHook from '../../../../hooks/useScreenSizeHook';
import { useNavigate } from 'react-router-dom';
import urls from '../../../../routes/all-routes';
import { useAppDispatch } from '../../../../store/customHooks';
import { getSingleAppLogsAction } from '../../../../store/apps/apps.actions';
import CardFooter from '../../../../components/common/cards/card-footer';
import Pagination from '../../../../components/common/pagination/Pagination';
import { IAuditsLogType } from '../../../../store/audits/audits.interface';
import useFilterHook from '../../../../hooks/useFilterHook';
import ApiLogsFilterModal from '../../audit-trail/api-logs-filter';

type Props = {
  app_id: number;
};

function AppRequestsLogs({ app_id }: Props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isDesktop } = useScreenSizeHook();
  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const [data, setData] = useState<{
    calls: IAuditsLogType[];
    page_info: {
      total: number;
    };
  }>({
    calls: [],
    page_info: {
      total: 0,
    },
  });

  const viewDetails = (id: string) => {
    navigate(urls.business.audits_trails.audit.for(`${id}`));
  };

  const initialFilter = {
    page: currentPage,
    limit: perPage,
  };

  const { filter, handleFilter, setFilter, resetFilter } = useFilterHook({
    initialFilter,
  });

  const loadPage = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  const getAppAudit = async (filter: any) => {
    setLoading(true);

    await dispatch(
      getSingleAppLogsAction({
        ...filter,
        app_id,
        page: currentPage,
        limit: perPage,
      })
    )
      .unwrap()
      .then((data) => {
        setData(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchAudits = useCallback(() => {
    getAppAudit(filter);
  }, [perPage, currentPage]);

  return (
    <Card className="">
      {isDesktop ? (
        <div className="">
          <Table
            isLoading={loading}
            tableHeader={['Date', 'Status', 'Method', 'URL', 'AMOUNT (NGN)']}
            title={'API Logs'}
            isEmpty={data.calls.length === 0}
            emptyStateComponent={
              <EmptyTable
                message="No API Logs"
                additional_message="Your API calls will appear here"
              />
            }
            filter={
              <ApiLogsFilterModal
                filter={filter}
                setFilter={setFilter}
                initialFilter={initialFilter}
                handleFilter={handleFilter}
              />
            }
            handleFilterReset={() => {
              setFilter(initialFilter);
              resetFilter();
              getAppAudit(initialFilter);
            }}
            handleFilterSubmit={() => {
              getAppAudit(filter);
            }}
          >
            {data.calls.map?.((apiCall) => {
              return (
                <TableRow
                  onRowClickAction={() => {
                    viewDetails(apiCall?.id);
                  }}
                  key={apiCall.request_id}
                >
                  <td>{fullDateFormat(apiCall?.created_on)}</td>

                  <td>
                    <Chip
                      label={apiCall?.status_code}
                      data-status={requestColorMatch(apiCall?.status_code)}
                    />
                  </td>

                  <td>
                    <Chip
                      label={apiCall?.method}
                      data-status={requestColorMatch(apiCall?.method)}
                      transparentBackground
                    />
                  </td>

                  <td>{truncateString(apiCall?.url, 30)}</td>

                  <td>{currencyFormat(apiCall.charge, 'NGN', 'table')}</td>
                </TableRow>
              );
            })}
          </Table>
        </div>
      ) : (
        <Card className="table_mobile_card">
          <MobileTable
            isLoading={loading}
            title="API Logs"
            isEmpty={data.calls?.length === 0}
            emptyStateComponent={
              <EmptyTable
                message="No API Logs"
                additional_message="Your API calls will appear here"
              />
            }
            filter={
              <ApiLogsFilterModal
                filter={filter}
                setFilter={setFilter}
                initialFilter={initialFilter}
                handleFilter={handleFilter}
              />
            }
            handleFilterReset={() => {
              setFilter(initialFilter);
              resetFilter();
              getAppAudit(initialFilter);
            }}
            handleFilterSubmit={() => {
              getAppAudit(filter);
            }}
          >
            {data.calls?.map?.((apiCall) => {
              return (
                <MobileTableRow
                  key={apiCall.request_id}
                  onRowClickAction={() => {
                    viewDetails(apiCall?.id);
                  }}
                >
                  <div className="field-container">
                    <span className="field-title">Date: </span>
                    {fullDateFormat(apiCall?.created_on)}
                  </div>

                  <div className="field-container">
                    <span className="field-title">Status: </span>
                    <Chip
                      label={apiCall?.status_code}
                      data-status={requestColorMatch(apiCall?.status_code)}
                      className="method-chip"
                    />
                  </div>

                  <div className="field-container">
                    <span className="field-title">Method: </span>
                    <Chip
                      label={apiCall?.method}
                      data-status={requestColorMatch(apiCall?.method)}
                      className="method-chip"
                      transparentBackground
                    />
                  </div>

                  <div className="field-container">
                    <span className="field-title">URL: </span>
                    {truncateString(apiCall?.url, 23)}
                  </div>

                  <div className="field-container">
                    <span className="field-title">Amount (NGN): </span>
                    {currencyFormat(apiCall.charge, 'NGN', 'table')}
                  </div>
                </MobileTableRow>
              );
            })}
          </MobileTable>
        </Card>
      )}

      {data.calls && (
        <CardFooter>
          <Pagination
            total={data.page_info.total}
            perPage={perPage}
            setPerPage={setPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            loadPage={loadPage}
            perPageTitle="App Logs"
            updateDataCallback={fetchAudits}
          />
        </CardFooter>
      )}
    </Card>
  );
}

export default AppRequestsLogs;
