export const openDropdown = (selector: string) => {
  const dropdown = document.getElementById(`${selector}`);

  if (dropdown) {
    setTimeout(() => {
      if (!dropdown.classList.contains("open")) {
        dropdown.classList.add("open");
      } else {
        dropdown.classList.remove("open");
      }
    }, 50);
  }
};

export const closeDropdown = () => {
  // Function to close on click outside of dropdown

  const popupQuerySelector = ".dropdown-menu";
  const popupEl = document.querySelectorAll(popupQuerySelector);

  document.addEventListener("click", (e: any) => {
    // Check if the filter list parent element exist
    const isClosest = e.target.closest(popupQuerySelector);

    // If `isClosest` equals falsy & popup has the class `show`
    // then hide the popup

    popupEl.forEach((el) => {
      if (!isClosest && el.classList.contains("open")) {
        el.classList.remove("open");
      }
    });
  });
};
