import { ADJUTOR_ROLES } from "./permission-maps";
import { useGetRole } from "./useGetRole";

export const hasPermission = ({ permissions, scopes }: any) => {
  const scopesMap: any = {};
  scopes.forEach((scope: any) => {
    scopesMap[scope] = true;
  });

  return permissions?.some((permission: any) => scopesMap[permission]);
};

export default function PermissionsGate({
  children,
  scopes = [],
}: {
  children: React.ReactNode;
  scopes: any[];
}) {
  const { role_id } = useGetRole();
  const { permissions }: any = role_id !== 0 ? ADJUTOR_ROLES.find((role) => role.id === role_id) : [];

  const permissionGranted = hasPermission({ permissions, scopes });

  if (!permissionGranted) return <></>;

  return <>{children}</>;
}
