import axios, {
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
} from 'axios';
import { logoutAction } from '../store/auth/auth.actions';

const headers: AxiosRequestHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'x-api-key': process.env.REACT_APP_X_API_KEY ?? '',
};

export const saveAccessToken = async(access_token: string) => {
  const authData = sessionStorage.getItem('adjutor-auth');

  if (authData) {
    const obj = JSON.parse(authData);
    sessionStorage.setItem(
      'adjutor-auth',
      JSON.stringify({
        ...obj,
        access_token: access_token,
      })
    );
    return access_token
  } else {
    sessionStorage.setItem(
      'adjutor-auth',
      JSON.stringify({
        access_token: access_token,
      })
    );
    return access_token
  }
};

const getToken = () => {
  const authData = sessionStorage.getItem('adjutor-auth');

  if (authData) {
    const obj = JSON.parse(authData);
    return obj.access_token;
  } else {
    return '';
  }
};

let axiosInstance = axios.create({
  headers,
});

axiosInstance.interceptors.request.use(function (config: AxiosRequestConfig) {
  const token = `bearer ${getToken()}`;
  if (config.headers) {
    config.headers.Authorization = token;
    config.headers.source = process.env.REACT_APP_API_SOURCE ?? '';
  }
  return config;
});

axiosInstance.interceptors.response.use(
  async (response) => {
    return Promise.resolve(response);
  },
  async (error) => {
    if (error?.response?.status === 401) {
      logoutAction();
      return;
    }
    return Promise.reject(error);
  }
);

export interface IAxiosResponse extends AxiosResponse {
  message?: string;
}

const ResponseBody = (response: IAxiosResponse) => response.data;

export const callApi = {
  get: (url: string, body?: {}) =>
    axiosInstance.get(url, body).then(ResponseBody),
  post: (url: string, body?: {}, config?: {}) =>
    axiosInstance.post(url, body, config).then(ResponseBody),
  put: (url: string, body?: {}, config?: {}) =>
    axiosInstance.put(url, body, config).then(ResponseBody),
  patch: (url: string, body?: {}, config?: {}) =>
    axiosInstance.patch(url, body, config).then(ResponseBody),
  delete: (url: string, body?: {}) =>
    axiosInstance.delete(url, body).then(ResponseBody),
  postWithReturnHeaders: (url: string, body?: {}, config?: {}) =>
    axiosInstance.post(url, body, config).then((res) => {
      return { data: res.data, headers: res.headers };
    }),
};
