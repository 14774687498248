import React, { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../../store/customHooks';
import { OpenSideBar, CloseSideBar } from '../../../store/layout/layout.reducer';

import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as DropdownIcon } from '../../../assets/icons/dropdown.svg';

export interface NavComponentProps {
  link: string;
  id: number;
  icon: React.ReactElement;
  title: string;
  subnav?: Array<any>;
  onClick?: Function;
}

const NavComponent = (props: NavComponentProps) => {
  const { link, icon, title, subnav, id, onClick } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const sidebarState = useAppSelector((state) => state.layoutReducer);

  const [activeLink, setActiveLink] = React.useState('');

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  const collapseAllSubnav = () => {
    const allNav = document.querySelectorAll(`.nav-link`);
    if (allNav) {
      allNav.forEach((allNav: any) => {
        allNav.classList.contains('expanded') &&
          allNav.classList.remove('expanded');
      });
    }
  };

  const expandSubnav = (id: number) => {
    const nav = document.getElementById(`nav-${id}`);
    if (nav) {
      nav.classList.add('expanded');
    }

    //expand sidebar if it is collapsed to open submenu
    if (sidebarState.isSidebarOpen === false) {
      dispatch(OpenSideBar());
    }
  };

  const changeRoute = (route: string, id?: number) => {
    navigate(route);
    collapseAllSubnav();
    window.screen.width < 1024 && dispatch(CloseSideBar());
    if (id) {
      expandSubnav(id);
    }
  };

  return (
    <div>
      <button
        className={`nav-link ${
          activeLink.split('/')[1] === link.split?.('/')[1]?.toLowerCase() &&
          sidebarState.isSidebarOpen === true
            ? 'expanded'
            : ''
        }
        ${
          activeLink.split('/')[1] === link.split?.('/')[1]?.toLowerCase() &&
          !subnav
            ? 'active'
            : ''
        }
        ${
          activeLink.split('/')[1] === link.split?.('/')[1]?.toLowerCase() &&
          sidebarState.isSidebarOpen === false &&
          subnav
            ? 'active'
            : ''
        }
        ${activeLink === link ? 'active' : ''}
        `}
        onClick={() => {
          if (subnav && subnav.length > 0) {
            expandSubnav(id);
          } else {
            changeRoute(link);
          }

          if (onClick) {
            onClick();
          }
        }}
        id={`nav-${id}`}
        data-subnav={!!subnav}
      >
        <div className="icon">{icon}</div>

        <div
          className={
            sidebarState.isSidebarOpen ? 'nav-title' : 'collapse-nav-link-title'
          }
        >
          {title}
        </div>

        {subnav && (
          <div className="dropdown icon">
            <DropdownIcon />
          </div>
        )}
      </button>

      {subnav && (
        <div className="subnav-container">
          {subnav.map((nav: { title: string; link: string }) => {
            const { title, link } = nav;

            return (
              <button
                onClick={() => {
                  if (onClick) {
                    onClick();
                    return;
                  }
                  changeRoute(link, id);
                }}
                key={link}
                className={`subnav-link ${
                  activeLink.split('/')[2] ===
                  title.toLowerCase().split(' ').join('-')
                    ? 'active'
                    : ''
                }
                
                ${activeLink.includes(link) ? 'active' : ''}
                `}
              >
                <div className="nav-title xs">{title}</div>
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default NavComponent;
