import React from 'react';
import Input from '../../../components/input-fields/input/input';
import Select from '../../../components/input-fields/Select/select';
import { convertToOptions } from '../../../utils/form';
import { GetAuditActionProps } from '../../../store/audits/types';

interface FilterModalProps {
  filter: Record<string, any>;
  setFilter: React.Dispatch<React.SetStateAction<GetAuditActionProps>>;
  initialFilter: {
    page: number;
    limit: number;
  };
  handleFilter: (e: any) => void;
}

const ApiLogsFilterModal = ({ filter, handleFilter }: FilterModalProps) => {
  return (
    <>
      <Select
        name="status_code"
        label="Status Code"
        onChange={(val) => {
          handleFilter({ name: 'status_code', value: val.value });
        }}
        selectOptions={convertToOptions([
          '200',
          '400',
          '403',
          '404',
          '500',
          '503',
        ])}
        defaultValue={convertToOptions([filter.status_code])}
        value={convertToOptions([filter.status_code])[0]}
        selectPlaceholder={filter.status_code}
        menuPlacement="top"
      />

      <Input
        name="start_date"
        type="date"
        label="Start Date"
        value={filter.start_date ?? ''}
        max={new Date().toISOString().split('T')[0]}
        onChange={handleFilter}
      />

      <Input
        name="end_date"
        type="date"
        label="End Date"
        placeholder=""
        value={filter.end_date ?? ''}
        max={new Date().toISOString().split('T')[0]}
        onChange={handleFilter}
      />
    </>
  );
};

export default ApiLogsFilterModal;
