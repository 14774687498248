import * as Yup from 'yup';
import {
  phoneNumberValidationErrorMessage,
  phoneNumberValidationRegex,
} from '../../../utils/helper';

export const createUserSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string()
    .min(8, 'Enter at least 8 characters')
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special case Character'
    )
    .required('Required'),
  rc_number: Yup.string().required('Required'),
  name: Yup.string().required('Required'),
  business_name: Yup.string().required('Required'),
  phone_number: Yup.string()
    .matches(phoneNumberValidationRegex, phoneNumberValidationErrorMessage)
    .required('Required'),
});

export const createBusinessProfileSchema = Yup.object().shape({
  business_name: Yup.string().required('Required'),
  role: Yup.string().required('Required'),
  source: Yup.string().required('Required'),
});
