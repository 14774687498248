import { IAPIPricingType, ISettingsState } from './settings.interface';
import { createSlice } from '@reduxjs/toolkit';
import {
  activateTeamMembersAction,
  deactivateTeamMembersAction,
  deleteTeamMembersAction,
  getAllApiPricingAction,
  getTeamMembersAction,
  initializeToggletwofaAction,
  inviteTeamMembersAction,
  manageTeamMembersAction,
  toggletwofaAction,
} from './settings.actions';

const initialState = {
  allApiPlans: [],
  allTeamMembers: [],
  isApiPlansLoading: false,
  error: '',
  allTeamMemberState: 'idle',
  isManagingMember: 'idle',
  isInvitingMember: 'idle',
  toggleTwoFAState: 'idle',
} as ISettingsState;

const SettingsSlice = createSlice({
  name: 'SettingsState',
  initialState,

  reducers: {
    resetUpdatingTeamMember: (state) => {
      state.isManagingMember = 'idle';
    },
    resetInvitingTeamMember: (state) => {
      state.isInvitingMember = 'idle';
    },
  },

  extraReducers: (builder) => {
    //API Pricing Logs
    builder.addCase(getAllApiPricingAction.pending, (state: ISettingsState) => {
      state.isApiPlansLoading = true;
    });
    builder.addCase(
      getAllApiPricingAction.fulfilled,
      (state: ISettingsState, action: any) => {
        state.allApiPlans = action.payload.filter((i: IAPIPricingType) => {
          return i.name.includes('Adjutor');
        });
        state.isApiPlansLoading = false;
      }
    );
    builder.addCase(
      getAllApiPricingAction.rejected,
      (state: ISettingsState, action: any) => {
        state.error = action.payload;
        state.isApiPlansLoading = false;
      }
    );

    //All Team Members
    builder.addCase(getTeamMembersAction.pending, (state: ISettingsState) => {
      state.allTeamMemberState = 'loading';
    });
    builder.addCase(
      getTeamMembersAction.fulfilled,
      (state: ISettingsState, action: any) => {
        state.allTeamMembers = action.payload;
        state.allTeamMemberState = 'success';
        state.allTeamMemberState = 'idle';
      }
    );
    builder.addCase(
      getTeamMembersAction.rejected,
      (state: ISettingsState, action: any) => {
        state.error = action.payload;
        state.allTeamMemberState = 'error';
      }
    );

    //Invite Team Members
    builder.addCase(
      inviteTeamMembersAction.pending,
      (state: ISettingsState) => {
        state.allTeamMemberState = 'updating';
      }
    );
    builder.addCase(
      inviteTeamMembersAction.fulfilled,
      (state: ISettingsState) => {
        state.isInvitingMember = 'success';
      }
    );
    builder.addCase(
      inviteTeamMembersAction.rejected,
      (state: ISettingsState, action: any) => {
        state.error = action.payload;
        state.allTeamMemberState = 'error';
      }
    );

    //Manage Team Members
    builder.addCase(
      manageTeamMembersAction.pending,
      (state: ISettingsState) => {
        state.allTeamMemberState = 'updating';
      }
    );
    builder.addCase(
      manageTeamMembersAction.fulfilled,
      (state: ISettingsState) => {
        state.allTeamMemberState = 'success';
        state.isManagingMember = 'success';
      }
    );
    builder.addCase(
      manageTeamMembersAction.rejected,
      (state: ISettingsState, action: any) => {
        state.error = action.payload;
        state.allTeamMemberState = 'error';
      }
    );

    //Activate Team Members
    builder.addCase(
      activateTeamMembersAction.pending,
      (state: ISettingsState) => {
        state.allTeamMemberState = 'updating';
      }
    );
    builder.addCase(
      activateTeamMembersAction.fulfilled,
      (state: ISettingsState) => {
        state.allTeamMemberState = 'success';
      }
    );
    builder.addCase(
      activateTeamMembersAction.rejected,
      (state: ISettingsState, action: any) => {
        state.error = action.payload;
        state.allTeamMemberState = 'error';
      }
    );

    //Deactivate Team Members
    builder.addCase(
      deactivateTeamMembersAction.pending,
      (state: ISettingsState) => {
        state.allTeamMemberState = 'updating';
      }
    );
    builder.addCase(
      deactivateTeamMembersAction.fulfilled,
      (state: ISettingsState) => {
        state.allTeamMemberState = 'success';
      }
    );
    builder.addCase(
      deactivateTeamMembersAction.rejected,
      (state: ISettingsState, action: any) => {
        state.error = action.payload;
        state.allTeamMemberState = 'error';
      }
    );

    //Delete Team Members
    builder.addCase(
      deleteTeamMembersAction.pending,
      (state: ISettingsState) => {
        state.allTeamMemberState = 'updating';
      }
    );
    builder.addCase(
      deleteTeamMembersAction.fulfilled,
      (state: ISettingsState) => {
        state.allTeamMemberState = 'success';
      }
    );
    builder.addCase(
      deleteTeamMembersAction.rejected,
      (state: ISettingsState, action: any) => {
        state.error = action.payload;
        state.allTeamMemberState = 'error';
      }
    );

    //Toggle two fa
    builder.addCase(toggletwofaAction.pending, (state: ISettingsState) => {
      state.toggleTwoFAState = 'loading';
    });
    builder.addCase(toggletwofaAction.fulfilled, (state: ISettingsState) => {
      state.toggleTwoFAState = 'success';
    });
    builder.addCase(
      toggletwofaAction.rejected,
      (state: ISettingsState, action: any) => {
        state.error = action.payload;
        state.toggleTwoFAState = 'error';
      }
    );

    //initialize
    builder.addCase(
      initializeToggletwofaAction.pending,
      (state: ISettingsState) => {
        state.toggleTwoFAState = 'initialize.loading';
      }
    );
    builder.addCase(
      initializeToggletwofaAction.fulfilled,
      (state: any, action: any) => {
        state.toggleTwoFAState = 'initialize.success';
        state.twofaqrcode = action.payload;
      }
    );
    builder.addCase(
      initializeToggletwofaAction.rejected,
      (state: ISettingsState, action: any) => {
        state.error = action.payload;
        state.toggleTwoFAState = 'initialize.error';
      }
    );
  },
});

export const { resetUpdatingTeamMember, resetInvitingTeamMember } =
  SettingsSlice.actions;

export default SettingsSlice.reducer;
