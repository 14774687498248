import React, { useEffect } from 'react';
import './_audit-details.scss';
import { useLocation } from 'react-router-dom';

import Card from '../../../../../components/common/cards/cards';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../store/customHooks';
import { getSingleAuditsAction } from '../../../../../store/audits/audits.actions';
import {
  IAuditsLogType,
  IAuditState,
} from '../../../../../store/audits/audits.interface';
import PageLoading from '../../../../../components/page-loading/pageLoading';
import JsonViewer from '../../../../../components/json-viewer';
import Chip from '../../../../../components/common/chip/chip';
import PageTitle from '../../../../../components/common/page-title';
import CardBody from '../../../../../components/common/cards/card-body';
import CardHeader from '../../../../../components/common/cards/card-header';
import { requestColorMatch } from '../../../../../utils/helper';

function AuditDetailsPage() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const apiCall: IAuditsLogType = useAppSelector(
    (state) => state.audit.focusAudit
  );
  const auditState: IAuditState = useAppSelector((state) => state.audit);

  useEffect(() => {
    const currentLocation = location.pathname.split('/').at(-1);
    dispatch(getSingleAuditsAction(Number(currentLocation)));
  }, []);

  return (
    <div className="view-role-page">
      <PageTitle title="API Calls" description={apiCall?.request_id} />

      {auditState.singleAuditState === 'loading' ? (
        <PageLoading loadingMessage="Loading Audit" />
      ) : (
        <>
          <Card
            className="details-card"
            cardHeading={<CardHeader title="API Call Details" />}
          >
            <CardBody>
              <div className="detail-row">
                <div className="detail-field">
                  <h6 className="field-title">Request Id</h6>
                  <p>{apiCall?.request_id || '-'}</p>
                </div>

                <div className="detail-field">
                  <h6 className="field-title">Path</h6>
                  <p>{apiCall?.url || '-'}</p>
                </div>
              </div>

              <div className="detail-row">
                <div className="detail-field">
                  <h6 className="field-title">Request Method</h6>
                  <p>
                    <Chip
                      data-status={requestColorMatch(apiCall?.method)}
                      label={apiCall?.method}
                      transparentBackground
                    />
                  </p>
                </div>

                <div className="detail-field">
                  <h6 className="field-title">Request IP</h6>
                  <p>{apiCall?.ip || '-'}</p>
                </div>

                <div className="detail-field">
                  <h6 className="field-title">Status Code</h6>
                  <p>
                    <Chip
                      data-status={requestColorMatch(apiCall?.status_code)}
                      label={apiCall?.status_code}
                    />
                  </p>
                </div>

                <div className="detail-field">
                  <h6 className="field-title">Response Time</h6>
                  <p>
                    {apiCall?.response_time
                      ? `${apiCall.response_time} ms`
                      : '-'}
                  </p>
                </div>
              </div>
            </CardBody>
          </Card>

          {apiCall?.request_body && (
            <Card
              className="details-card"
              cardHeading={<CardHeader title="Request Body" />}
            >
              <CardBody className="">
                <JsonViewer data={apiCall?.request_body} />
              </CardBody>
            </Card>
          )}

          {apiCall?.response_body && (
            <Card
              className="details-card"
              cardHeading={<CardHeader title="Response Body" />}
            >
              <CardBody className="response-container">
                <JsonViewer data={apiCall?.response_body} />
              </CardBody>
            </Card>
          )}
        </>
      )}
    </div>
  );
}

export default AuditDetailsPage;
