import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import FillButton from '../../../components/common/buttons/button';
import urls from '../../../routes/all-routes';

export interface CompleteSetupProps {
  stepNo: number;
  saveStepStatus: (stepNo: number, props: any) => void;
}

export default function CompletedSetup(props: CompleteSetupProps) {
  const navigate = useNavigate();
  const { stepNo, saveStepStatus } = props;

  useEffect(() => {
    saveStepStatus(stepNo, props);
  }, []);

  return (
    <div>
      <div>
        <h6>Your Account is all Set !</h6>

        <p className="sm">
          Your Adjutor account is all set up. Go to dashboard and start lending
          like a PRO!
        </p>
      </div>

      <form>
        <FillButton
          onClick={() => {
            navigate(urls.login.index);
          }}
          label="Let's go"
          className="auth-button"
        />
      </form>
    </div>
  );
}
