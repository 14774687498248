import React, { useState, useMemo } from 'react';
import './_create-app.scss';

//form
import { useFormik } from 'formik';
import { CreateAppSchema } from './create-app-validation-schema';

//components
import Input from '../../../components/input-fields/input/input';
import SlidingPane from '../../../components/slidingpane/slidingpane';
import FillButton, {
  ButtonColor,
} from '../../../components/common/buttons/button';

//utils
import DragAndDrop from '../../../components/drag-and-drop/drag-and-drop';
import { useAppDispatch, useAppSelector } from '../../../store/customHooks';
import {
  createAppAction,
  editAppAction,
} from '../../../store/apps/apps.actions';
import { resetAppState } from '../../../store/apps/apps.reducer';
import { useParams } from 'react-router-dom';
import ModalFooter from '../../../components/common/modal/modal-footer';
import toast from 'react-hot-toast';
import MultiSelectCheckbox from '../../../components/input-fields/multi-select-checkbox';
import useAdjutorServicesHook from '../../../hooks/useAdjutorServicesHook';

export interface CreateAppProps {
  isOpen: boolean;
  onClose: (res?: any) => void;
  title: string;
  type: 'create' | 'edit';
  initialValues: {
    name: string;
    description: string;
    webhook_url: string;
    callback_url: string;
    services: string[];
    logo_url: string;
  };
}

function CreateAppPane({
  title,
  isOpen,
  onClose,
  type,
  initialValues = {
    name: '',
    description: '',
    webhook_url: '',
    callback_url: '',
    services: [],
    logo_url: '',
  },
}: CreateAppProps) {
  const params: any = useParams();
  const [full_logo, setFullLogo] = useState<any>(null);
  const { adjutorServicesOptions, loadingAdjutorOptions } =
    useAdjutorServicesHook();

  const [imgUpload, setImgUpload] = useState('');

  const dispatch = useAppDispatch();
  const appState = useAppSelector((state) => state.apps);

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: initialValues,

    validationSchema: CreateAppSchema,

    onSubmit: async (values) => {
      if (type === 'create') {
        dispatch(createAppAction(values))
          .unwrap()
          .then((res) => {
            handleClose();
            onClose(res);
          });
      } else if (type === 'edit') {
        dispatch(editAppAction({ app_id: params.id, values: values }))
          .unwrap()
          .then(() => {
            toast.success('Changes saved', {
              position: 'bottom-center',
            });
            handleClose();
          });
      }
    },
  });

  useMemo(() => {
    formik.setFieldValue('logo_url', full_logo);

    if (full_logo) {
      setImgUpload('loading');
      setTimeout(() => {
        setImgUpload('success');
      }, 1500);
    }
  }, [full_logo]);

  const handleClose = () => {
    dispatch(resetAppState());
    formik.resetForm();
    onClose();
  };

  return (
    <SlidingPane
      isOpen={isOpen}
      title={title}
      onClose={() => {
        handleClose();
      }}
      footer={
        <ModalFooter>
          <FillButton
            variant="outlined"
            onClick={() => {
              handleClose();
            }}
            className="close-button"
            colorVariant={ButtonColor.neutral}
            label="Close"
          />

          <FillButton
            type="submit"
            onClick={formik.submitForm}
            disabled={
              !(formik.isValid && formik.dirty) ||
              formik.values.services.length === 0
            }
            isLoading={appState.createAppStatus === 'loading'}
            label={`${type === 'edit' ? 'Save changes' : 'Create app'}`}
            loadingText={`${type === 'edit' ? 'Saving' : 'Creating'}`}
          />
        </ModalFooter>
      }
    >
      <form className="create-app-form" onSubmit={formik.handleSubmit}>
        <div className="form-section">
          <DragAndDrop
            placeholder="Drop image or click to upload"
            className="upload-image"
            setFileToUpload={setFullLogo}
            value={formik.values.logo_url}
            handleChange={(e: any) => {
              formik.setFieldValue('logo_url', e);
            }}
            showPreview={true}
            status={
              imgUpload === 'loading'
                ? 'loading'
                : imgUpload === 'success'
                  ? 'success'
                  : imgUpload === 'error'
                    ? 'error'
                    : 'idle'
            }
            fileTypes="image/jpg, image/png, image/jpeg"
            resetStatusAction={() => {
              setImgUpload('idle');
            }}
          />

          <Input
            type="text"
            label="App Name"
            name="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            error={formik.touched.name && formik.errors.name}
            errorMessage={formik.errors.name}
          />

          <Input
            type="text"
            label="Description"
            name="description"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.description}
            error={formik.touched.description && formik.errors.description}
            errorMessage={formik.errors.description}
          />

          <Input
            type="text"
            label="Webhook URL (Optional)"
            name="webhook_url"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.webhook_url}
            error={formik.touched.webhook_url && formik.errors.webhook_url}
            errorMessage={formik.errors.webhook_url}
          />

          <Input
            type="text"
            label="Callback URL (Optional)"
            name="callback_url"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.callback_url}
            error={formik.touched.callback_url && formik.errors.callback_url}
            errorMessage={formik.errors.callback_url}
          />

          <div>
            <MultiSelectCheckbox
              loading={loadingAdjutorOptions}
              title="API Scopes"
              description="Select API Scopes to define permissions to access Adjutor's API. This helps control what actions your API can do, enhancing security and privacy"
              SelectOptions={adjutorServicesOptions}
              SelectValues={formik.values.services}
              setSelectedValues={(values) => {
                formik.setFieldValue('services', values);
              }}
            />
          </div>
        </div>
      </form>
    </SlidingPane>
  );
}

export default CreateAppPane;
