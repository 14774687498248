const apiUrl: string = process.env.REACT_APP_API_URL as string;
const utilityUrl: string = process.env.REACT_APP_UTILITY_URL as string;

export const baseUrl = `${apiUrl}/v1`;
export const adjutorBaseUrl = `https://1u0bzafu09.execute-api.us-east-2.amazonaws.com`;
export const baseUrlV2 = `${apiUrl}/v2`;
export const utilitybaseUrl = `${utilityUrl}/v1`;
export const utilitybaseUrlV2 = `${utilityUrl}/v2`;

/*********** ALL APIS ************/

//AUTH APIS
export const loginUrl = `${baseUrlV2}/auth/admin/login`;
export const resetPasswordUrl = (token: string) =>
  `${baseUrl}/auth/reset/admin/password?token=${token}`;
export const sendResetPasswordUrl = `${baseUrl}/auth/reset/admin/password/email`;
export const getSwitchStore = (org_id: number)=> `${baseUrlV2}/auth/admin/switch?org_id=${org_id}`;

//Onboarding APIS
export const signupUrl = `${baseUrlV2}/onboard`;
export const updateOrganizationDetailsUrl = 
  `${baseUrlV2}/onboard/organization-details`;
export const updateBusinessDetailsUrl = (userId: string) =>
  `${baseUrlV2}/onboard/${userId}/business-details`;
export const updateBusinessAddressUrl = 
  `${baseUrlV2}/onboard/address-details`;
export const businessKYCUrl = (userId: string) =>
  `${baseUrlV2}/onboard/${userId}/kyc-details`;
export const updateBuinessBankUrl = 
  `${baseUrlV2}/onboard/complete`;
export const resendOnboardingMailUrl = 
  `${baseUrlV2}/onboard/resend`;
export const getOnboardingInfoUrl = 
  `${baseUrlV2}/onboard/`;

//Languages APIS
export const getLanguages = utilitybaseUrl + '/codes/fetch/languages';

//Banks APIS
export const getAllBanks = utilitybaseUrl + '/codes/fetch/bank';
export const postVerifyBankAccount = utilitybaseUrl + '/verify/bank';

//Organizations APIS
export const organizationEndUrl = `${baseUrl}/admin/organization/settings`;
export const organizationUrl = `${baseUrl}/admin/organization`;
export const organizationUrlV2 = `${baseUrlV2}/admin/organization`;

//Audits
export const eventLogUrl = `${baseUrl}/admin/audit`;
export const adjutorLogUrl = `${baseUrl}/admin/adjutor`;
export const auditLogAnalyticsUrl = `${baseUrl}/admin/adjutor/analytics`;

//Wallet
export const allTransactionsUrl = `${baseUrl}/admin/organization/wallet/transactions`;
export const organizationWalletUrl = `${baseUrl}/admin/organization/wallet`;

//Apps
export const organizationAppsUrl = `${baseUrl}/admin/adjutor/apps`;
export const adjutorAppTokenUrl = `${baseUrl}/admin/adjutor/token`;

//Decision Models
export const decisionModelUrl = `${baseUrl}/admin/adjutor/model`;
export const oraculiUrl = `${baseUrl}/admin/oraculi`;

//Settings
export const allApiPricingUrl = `${baseUrlV2}/admin/fees?entity_type=`;
export const searchAllUrl = `${baseUrl}/admin/search`;
export const teamMembersUrl = `${baseUrlV2}/admin/team`;
export const allPermissionsUrl = `${baseUrlV2}/admin/permissions`;
export const allRolesUrl = `${baseUrl}/admin/roles`;
export const accountUrl = `${baseUrl}/admin/account`;
export const getSettingValue = (id_or_slug: number | string)=> `${baseUrl}/admin/setting-management/values/${id_or_slug}`;

//Utility
export const postUploadFileUrl = `${utilitybaseUrlV2}/upload/file`;
export const postUploadPhotoUrl = `${utilitybaseUrl}/upload/photo`; //Temporary from Web APP
export const postEventTaggingUrl = `${utilitybaseUrl}/event`;

// META
export const getMetaDataUrl = (code: string | number) =>
  baseUrl + `/meta/codes?code_description=${code}`;
export const getMetaDataSubUrl = (
  code: string | number,
  ref: string | number
) => baseUrl + `/meta/codes/link?code_description=${code}&ref_code=${ref}`;
