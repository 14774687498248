import React, { useRef, useCallback } from 'react';
import './_configuration.scss';
import Card from '../../../../components/common/cards/cards';
import MainConfiguration from './main-configuration';
import { useAppDispatch, useAppSelector } from '../../../../store/customHooks';
import {
  IDecisionModels,
  IModel,
} from '../../../../store/decision-model/decision-model.interface';
import { useSearchParams } from 'react-router-dom';
import { Form, Formik, FormikProps } from 'formik';
import { RenderModuleDecsionSettingField } from '../../../../components/decision-model/configuration/decision-settings/render-module-decision-setting';
import FillButton from '../../../../components/common/buttons/button';
import {
  updateModelAction,
  updateModelSettingAction,
} from '../../../../store/decision-model/decision-model.actions';
import { toast } from 'react-hot-toast';
import { defaultErrorMessage } from '../../../../utils/helper';

function ConfigurationComp() {
  const dispatch = useAppDispatch();
  const decisionModel: IDecisionModels = useAppSelector(
    (state) => state.decisionModel
  );

  const [searchParams] = useSearchParams();

  const focusedSetting = decisionModel?.focusModel?.settings?.find(
    (setting) => {
      return setting?.decision_type === searchParams?.get('module-id');
    }
  );

  const focusConfigSetting = decisionModel?.configModel?.find((setting) => {
    return setting?.name === searchParams?.get('module-id');
  });

  const obj = decisionModel?.focusModel?.decision_setting || {};

  const focusDecsionSetting =
    obj[searchParams?.get('module-id') as string] || null;

  const getObjKey = useCallback(
    (obj: any, value: any) => {
      if (obj) {
        return obj[value];
      }
    },
    [focusDecsionSetting]
  );

  const handleSubmitForm1 = (data: {
    payload: Partial<IModel>;
    id: number;
    modelsettingid: number;
  }) => {
    dispatch(updateModelSettingAction(data))
      .unwrap()
      .then(() => {
        decisionSettingFormRef.current &&
          decisionSettingFormRef?.current.dispatchEvent(
            new Event('submit', { cancelable: true, bubbles: true })
          );
      })
      .catch((err: any) => {
        toast.error(err?.message ?? defaultErrorMessage);
        console.log(err);
      });
  };

  const decisionSettingFormRef: any = useRef(null);
  const configSettingFormRef: any = useRef(null);
  const activeModule =
    focusedSetting?.decision_type ?? focusConfigSetting?.name;

  const deleteObjectsWithDisallowField = (obj: any) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          obj[key] = deleteObjectsWithDisallowField(obj[key]);
        }
        if (obj[key] && obj[key].allowField === false) {
          delete obj[key];
        }
      }
    }
    return obj;
  };

  const formattedFocusSettingExample = () => {
    let obj = { ...focusConfigSetting?.setting_example };
    if (activeModule?.toLowerCase() === 'scoring') {
      return obj;
    }
    for (const objKey in obj) {
      if (
        obj.hasOwnProperty(objKey) &&
        typeof obj[objKey] === 'object' &&
        obj[objKey] !== null
      ) {
        obj[objKey] = { ...obj[objKey], ['allowField']: false };
      }
    }
    return obj;
  };

  return (
    <Card className="configuration-container">
      <div className="decision-card-header">
        <p className="decision-card-title">Configuration</p>
      </div>
      {searchParams?.get('module-id') && (
        <>
          <Card className="module-version-info">
            <div className="card-header">
              <span className="module-name">
                Module: {activeModule?.replace(/_/g, ' ')}
              </span>
              <span className="id">
                ID: {focusedSetting?.id ?? focusConfigSetting?.id}
              </span>
            </div>

            <p>Version ID: {focusedSetting?.version_id ?? 0}</p>

            {focusedSetting?.decision_model_id && (
              <p>Decision Model ID: {focusedSetting?.decision_model_id}</p>
            )}
          </Card>

          <Card className="main-configuration-card">
            <div className="decision-card-header">
              <div className="decision-card-title">
                {activeModule?.replace(/_/g, ' ')}
              </div>
            </div>

            <Formik
              enableReinitialize
              initialValues={decisionModel?.focusModel}
              onSubmit={async (values) => {
                Object.keys(values.decision_setting).forEach((key: any) => {
                  if (
                    values.decision_setting[key].required === false ||
                    !values.decision_setting[key].hasOwnProperty('required')
                  ) {
                    delete values.decision_setting[key];
                  }
                });
                //Function Above Removes non required models

                let modelUpdate = {
                  name: values.name,
                  description: values.description,
                  offer_setting: values.offer_setting,
                  decision_setting: values.decision_setting,
                  status: values.status,
                };
                dispatch(
                  updateModelAction({
                    payload: modelUpdate,
                    id: values.id,
                  })
                );
              }}
              validateOnBlur
            >
              {(props: FormikProps<any>) => (
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    props.handleSubmit();
                  }}
                  className="module-decision-form"
                  ref={decisionSettingFormRef}
                >
                  {props?.values &&
                    focusConfigSetting?.model
                      ?.filter((model) => {
                        return model.key !== 'sequence';
                      })
                      .map((model) => {
                        return (
                          <RenderModuleDecsionSettingField
                            key={model.key}
                            value={
                              getObjKey(focusDecsionSetting, model?.key) ??
                              model.default
                            }
                            data_type={model.data_type}
                            description={model.description}
                            fieldKey={model.key}
                            options={model?.options}
                          />
                        );
                      })}
                </Form>
              )}
            </Formik>

            {(focusConfigSetting || focusedSetting) && (
              <Formik
                enableReinitialize
                initialValues={{
                  ...formattedFocusSettingExample(),
                  ...focusedSetting?.setting,
                }}
                onSubmit={async (values) => {
                  if (focusedSetting?.setting) {
                    const payload = values;
                    deleteObjectsWithDisallowField(payload);
                    let data: any = {
                      payload,
                      id: focusedSetting?.decision_model_id,
                      modelsettingid: focusedSetting?.id,
                    };

                    handleSubmitForm1(data);
                  }
                }}
                validateOnBlur
              >
                {(props: FormikProps<unknown>) => (
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      props.handleSubmit();
                    }}
                    ref={configSettingFormRef}
                  >
                    <MainConfiguration
                      activeModule={activeModule ?? ''}
                      setting={props.values}
                    />
                  </Form>
                )}
              </Formik>
            )}

            <div className="configuration-footer">
              <FillButton
                type="submit"
                onClick={() => {
                  configSettingFormRef?.current?.dispatchEvent(
                    new Event('submit', { cancelable: true, bubbles: true })
                  );
                }}
                isLoading={
                  decisionModel.focusModelStatus === 'updating' ||
                  decisionModel.focusModelStatus === 'loading'
                }
                disabled={
                  decisionModel.focusModelStatus === 'updating' ||
                  decisionModel.focusModelStatus === 'loading'
                }
              >
                Save Details
              </FillButton>
            </div>
          </Card>
        </>
      )}
    </Card>
  );
}

export default ConfigurationComp;
